import axios from "axios";
 
const instance = axios.create({
   baseURL: "https://idm.althisolutions.com",
  //  baseURL: "http://10.228.1.202:8087", 
  headers: {
    "Content-Type": "application/json",
  },
});
 
export default instance;