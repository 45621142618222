import React, { useState, useRef, useEffect } from "react";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import axios from "axios";
import { Toast } from "primereact/toast";
import { Calendar } from 'primereact/calendar';
// import { Divider } from "primereact/divider";
import './Sansationupdate.css';
// import { MdCloudUpload } from 'react-icons/md';
import { Triangle } from 'react-loader-spinner';

function SansactionUpdates(props) {

    const [eid, setEid] = useState('');
    let eidRef = useRef('');
    const [gender, setGender] = useState('');
    let genderRef = useRef('');
    const [activeStatus, setActiveStatus] = useState('');
    let activeStatusRef = useRef('');
    const [deceased, setDeceased] = useState('');
    let deceasedRef = useRef('');
    const [notes, setNotes] = useState('');
    let notesRef = useRef('');
    const [suffix, setSuffix] = useState('');
    let suffixRef = useRef('');
    const [firstName, setFirstName] = useState('');
    let firstNameRef = useRef('');
    const [middleName, setMiddleName] = useState('');
    let middleNameRef = useRef('');
    const [surname, setSurename] = useState('');
    let surenameRef = useRef('');
    const [originalScriptName, setOriginalScriptName] = useState('');
    let originalScriptNameRef = useRef('');
    const [maidenName, setMaidenName] = useState('');
    let maidenNameRef = useRef('');
    const [titleHonorific, setTitleHonorific] = useState('');
    let titleHonorificRef = useRef('');
    const [singleStringName, setSingleStringName] = useState('');
    let singleStringNameRef = useRef('');
    const [descriptions, setDescriptions] = useState(['']);
    const [roleType, setRoleType] = useState(['']);
    const [title, setTitle] = useState(['']);
    const [dateValue, setdateValue] = useState([new Date()]);
    const [sinceDay, setSinceDay] = useState([new Date()]);
    const [sourcename, setSourcename] = useState('')
    let sourceNameRef = useRef('');
    const toast = useRef(null);
    const [step, setStep] = useState(0);
    // const totalSteps = 5;
    const [idExpiryDate, setidExpiryDate] = useState([new Date()]);
    const [aliasNames, setAliasNames] = useState(['']);
    const [toDay, setToDay] = useState([new Date()]);
    const [occCat, setOccCat] = useState('');
    let occCatRef = useRef('');
    const [dateType, setDateType] = useState(['']);
    const [place, setPlace] = useState('');
    let placeRef = useRef('');
    const [countryType, setCountryType] = useState('');
    let countryTypeRef = useRef('');
    const [code, setCode] = useState('');
    let codeRef = useRef('');
    const [url, setUrl] = useState('');
    let urlRef = useRef('');
    const [reference, setReference] = useState(['']);
    const [sinceDay1, setSinceDay1] = useState([new Date()]);
    const [toDay1, setToDay1] = useState([new Date()]);
    const [addressLine, setAddressLine] = useState('');
    let addressLineRef = useRef('');
    const [addressCity, setAddressCity] = useState('');
    let addressCityRef = useRef('');
    const [addressCountry, setAddressCountry] = useState('');
    let addressCountryRef = useRef('');
    const [url1, setUrl1] = useState('');
    let url1Ref = useRef('');
    const [idType, setIdType] = useState(['']);
    const [value, setValue] = useState(['']);
    const [category, setcategory] = useState('');
    let categoryRef = useRef('');
    const [dnotes, setDnotes] = useState('');
    let dnotesRef = useRef('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const addAlias = (event) => {
        event.preventDefault();
        setAliasNames([...aliasNames, '']); // Add an empty input
    };

    const removeAlias = (index, event) => {
        event.preventDefault();
        const updatedAliases = aliasNames.filter((_, i) => i !== index);
        setAliasNames(updatedAliases); // Remove the input at the given index
    };

    const handleAliasChange = (index, value) => {
        const updatedAliases = [...aliasNames];
        updatedAliases[index] = value;
        setAliasNames(updatedAliases); // Update the input value
    };

    const adddescription = (event) => {
        event.preventDefault();
        setDescriptions([...descriptions, '']); // Add an empty input
    };

    const removedescription = (index, event) => {
        event.preventDefault();
        const updateddescription = descriptions.filter((_, i) => i !== index);
        setDescriptions(updateddescription); // Remove the input at the given index
    };

    const handledescription = (index, value) => {
        const updateddescription = [...descriptions];
        updateddescription[index] = value;
        setDescriptions(updateddescription); // Update the input value
    };

    const addroleType = (event) => {
        event.preventDefault();
        setRoleType([...roleType, '']); // Add an empty input
    };

    const removeroleType = (index, event) => {
        event.preventDefault();
        const updatedroleType = roleType.filter((_, i) => i !== index);
        setRoleType(updatedroleType); // Remove the input at the given index
    };

    const handleroleType = (index, value) => {
        const updatedroleType = [...roleType];
        updatedroleType[index] = value;
        setRoleType(updatedroleType); // Update the input value
    };

    const addtitle = (event) => {
        event.preventDefault();
        setTitle([...title, '']); // Add an empty input
    };

    const removetitle = (index, event) => {
        event.preventDefault();
        const updatedroleType = title.filter((_, i) => i !== index);
        setTitle(updatedroleType); // Remove the input at the given index
    };

    const handletitle = (index, value) => {
        const updatedtitle = [...title];
        updatedtitle[index] = value;
        setTitle(updatedtitle); // Update the input value
    };

    const addreference = (event) => {
        event.preventDefault();
        setReference([...reference, '']); // Add an empty input
    };

    const removereference = (index, event) => {
        event.preventDefault();
        const updatedroleType = reference.filter((_, i) => i !== index);
        setReference(updatedroleType); // Remove the input at the given index
    };

    const handlereference = (index, value) => {
        const updatedtitle = [...reference];
        updatedtitle[index] = value;
        setReference(updatedtitle); // Update the input value
    };

    const addidType = (event) => {
        event.preventDefault();
        setIdType([...idType, '']); // Add an empty input
    };

    const removeidType = (index, event) => {
        event.preventDefault();
        const updatedroleType = idType.filter((_, i) => i !== index);
        setIdType(updatedroleType); // Remove the input at the given index
    };

    const handleidType = (index, value) => {
        const updatedtitle = [...idType];
        updatedtitle[index] = value;
        setIdType(updatedtitle); // Update the input value
    };

    const addvalue = (event) => {
        event.preventDefault();
        setValue([...value, '']); // Add an empty input
    };

    const removevalue = (index, event) => {
        event.preventDefault();
        const updatedroleType = value.filter((_, i) => i !== index);
        setValue(updatedroleType); // Remove the input at the given index
    };

    const handlevalue = (index, valuee) => {
        const updatedtitle = [...value];
        updatedtitle[index] = valuee;
        setValue(updatedtitle); // Update the input value
    };

    const adddateType = (event) => {
        event.preventDefault();
        setDateType([...dateType, '']);
    };

    // const removedateType = (index, event) => {
    //     event.preventDefault();
    //     const updatedroleType = dateType.filter((_, i) => i !== index);
    //     setDateType(updatedroleType); // Remove the input at the giv
    // };

    const handledateType = (index, valuee) => {
        const updatedtitle = [...dateType];
        updatedtitle[index] = valuee;
        setDateType(updatedtitle); //
    };

    const formatDate = (date) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return date.toLocaleDateString('en-US', options);
    };

    const addsinceDay = (event) => {
        event.preventDefault();
        setSinceDay([...sinceDay, new Date()]);
    };

    const removesinceDay = (index, event) => {
        event.preventDefault();
        const updatedsinceDay = sinceDay.filter((_, i) => i !== index);
        setSinceDay(updatedsinceDay);
    };

    const handlesinceDay = (index, value) => {
        const updatedsinceDay = [...sinceDay];
        const newDate = new Date(value); // Convert the value to a Date object
        updatedsinceDay[index] = newDate;
        setSinceDay(updatedsinceDay);
    };

    const addtoDay = (event) => {
        event.preventDefault();
        setToDay([...toDay, new Date()]);
    };

    const removetoDay = (index, event) => {
        event.preventDefault();
        const updatedtoDay = toDay.filter((_, i) => i !== index);
        setToDay(updatedtoDay);
    };

    const handletoDay = (index, value) => {
        const updatedtoDay = [...toDay];
        const newDate = new Date(value); // Convert the value to a Date object
        updatedtoDay[index] = newDate;
        setToDay(updatedtoDay);
    };

    const adddateValue = (event) => {
        event.preventDefault();
        setdateValue([...dateValue, new Date()]);
    };

    const removedateValue = (index, event) => {
        event.preventDefault();
        const updateddateValue = dateValue.filter((_, i) => i !== index);
        setdateValue(updateddateValue);
    };

    const handledateValue = (index, value) => {
        const updateddateValue = [...dateValue];
        const newDate = new Date(value); // Convert the value to a Date object
        updateddateValue[index] = newDate;
        setdateValue(updateddateValue);
    };

    const addsinceDay1 = (event) => {
        event.preventDefault();
        setSinceDay1([...sinceDay1, new Date()]);
    };

    const removesinceDay1 = (index, event) => {
        event.preventDefault();
        const updatedsinceDay1 = sinceDay1.filter((_, i) => i !== index);
        setSinceDay1(updatedsinceDay1);
    };

    const handlesinceDay1 = (index, value) => {
        const updatedsinceDay1 = [...sinceDay1];
        const newDate = new Date(value); // Convert the value to a Date object
        updatedsinceDay1[index] = newDate;
        setSinceDay1(updatedsinceDay1);
    };

    const addhandletoDay1 = (event) => {
        event.preventDefault();
        setToDay1([...toDay1, new Date()]);
    };

    const removehandletoDay1 = (index, event) => {
        event.preventDefault();
        const updatedtoDay1 = toDay1.filter((_, i) => i !== index);
        setToDay1(updatedtoDay1);
    };

    const handletoDay1 = (index, value) => {
        const updatedtoDay1 = [...toDay1];
        const newDate = new Date(value); // Convert the value to a Date object
        updatedtoDay1[index] = newDate;
        setToDay1(updatedtoDay1);
    };

    const addidExpiryDate = (event) => {
        event.preventDefault();
        setidExpiryDate([...idExpiryDate, new Date()]);
    };

    const removeidExpiryDate = (index, event) => {
        event.preventDefault();
        const updatedtoDay1 = idExpiryDate.filter((_, i) => i !== index);
        setidExpiryDate(updatedtoDay1);
    };

    const handleidExpiryDate = (index, value) => {
        const updatedtoDay1 = [...idExpiryDate];
        const newDate = new Date(value); // Convert the value to a Date object
        updatedtoDay1[index] = newDate;
        setidExpiryDate(updatedtoDay1);
    };

    // const [formData, setFormData] = useState({

    //     eid: '',
    //     gender: '',
    //     activeStatus: '',
    //     deceased: '',
    //     notes: '',
    //     suffix: '',
    //     firstName: '',
    //     middleName: '',
    //     surename: '',
    //     originalScriptName: '',
    //     maidenName: '',
    //     titleHonorific: '',
    //     singleStringName: '',
    //     descriptions1: '',
    //     descriptions2: '',
    //     descriptions3: '',
    //     roleType: '',
    //     title: '',
    //     sinceYear: '',
    //     sinceMonth: '',
    //     sinceDay: '',
    //     toYear: '',
    //     toMonth: '',
    //     toDay: '',
    //     occCat: '',
    //     dateType: '',
    //     year: '',
    //     month: '',
    //     day: '',
    //     dnotes: '',
    //     place: '',
    //     countryType: '',
    //     code: '',
    //     url: '',
    //     reference: '',
    //     sinceDay1: '',
    //     sinceMonth1: '',
    //     sinceYear1: '',
    //     toDay1: '',
    //     toMonth1: '',
    //     toYear1: '',
    //     addressLine: '',
    //     addressCity: '',
    //     addressCountry: '',
    //     url1: '',
    //     sourceDescription: '',
    //     idType: '',
    //     value: '',
    //     idNotes: '',
    //     createdUser: '',
    //     createdTime: '',
    // });

    const handleNext = () => {
        if (!eid || !firstName) {
            displayWarnToast("Please fill in both 'EID' and 'First Name' fields.");
        } else {
            setStep((prevStep) => prevStep + 1);
        }
    };
    const handleNext1 = () => {
        // if (descriptions.some((desc) => desc.trim() === '') || roleType.some((role) => role.trim() === '')) {
        if (!descriptions || !roleType) {
            displayWarnToast("Please fill in both 'Descriptions' and 'Role Type' fields.");
        } else {
            setStep((prevStep) => prevStep + 1);
        }
    };
    const handleNext2 = () => {
        if (!place || !countryType) {
            displayWarnToast("Please fill in both 'Place' and 'Country Type' fields.");
        } else {
            setStep((prevStep) => prevStep + 1);
        }
    };
    const handleNext3 = () => {
        if (!addressCountry || !addressCity || !addressLine) {
            displayWarnToast("Please fill in both 'Address Line' and 'Address Country Type' fields.");
        } else {
            setStep((prevStep) => prevStep + 1);
        }
    };

    const handlePrevious = () => {
        setStep((prevStep) => prevStep - 1);
    };

    const displayErrorToast = (message) => {
        if (toast.current) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
        }
    };
    const displayWarnToast = (message) => {
        if (toast.current) {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: message, life: 5000 });
        }
    };

    // const email = props?.data?.emailId

    const sansationsubmit = event => {
        event.preventDefault();
        setLoading(true);
        const data = {
            eid: eid,
            gender: gender,
            activeStatus: activeStatus,
            deceased: deceased,
            notes: notes,
            suffix: suffix,
            firstName: firstName,
            middleName: middleName,
            surname: surname,
            originalScriptName: originalScriptName,
            maidenName: maidenName,
            titleHonorific: titleHonorific,
            singleStringName: singleStringName,
            descriptions: descriptions,
            roleType: roleType,
            title: title,
            sinceDay: sinceDay.map(formatDate),
            toDay: toDay.map(formatDate),
            occCat: occCat,
            dateType: dateType,
            place: place,
            countryType: countryType,
            code: code,
            url: url,
            reference: reference,
            sinceDay1: sinceDay1.map(formatDate),
            toDay1: toDay1.map(formatDate),
            addressLine: addressLine,
            addressCity: addressCity,
            addressCountry: addressCountry,
            url1: url1,
            idType: idType,
            idValue: value,
            category: category,
            dnotes: dnotes,
            aliasName: aliasNames,
            dateValue: dateValue.map(formatDate),
            idExpiryDate: idExpiryDate.map(formatDate),
            sourceName: sourcename
        };
        axios.post(process.env.REACT_APP_BACKEND_HOST + '/sanctions/upload', data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props?.data?.accessToken
            }
        })
            .then(postdata => {
                setLoading(false);
                event.preventDefault();
                if (postdata.status === 200) {
                    toast.current.show({ severity: 'success', summary: 'Registeration Successfully' });
                    setEid('');
                    setGender('');
                    setActiveStatus('');
                    setDeceased('');
                    setNotes('');
                    setSuffix('');
                    setFirstName('');
                    setMiddleName('');
                    setOriginalScriptName('');
                    setMaidenName('');
                    setTitleHonorific('');
                    setSingleStringName('');
                    setDescriptions(['']);
                    setRoleType(['']);
                    setTitle(['']);
                    setdateValue([new Date()]);
                    setSinceDay([new Date()]);
                    setSourcename('');
                    setStep(0);
                    setidExpiryDate([new Date()]);
                    setAliasNames(['']);
                    setToDay([new Date()]);
                    setOccCat('');
                    setDateType(['']);
                    setPlace('');
                    setCountryType('');
                    setCode('');
                    setUrl('');
                    setReference(['']);
                    setSinceDay1([new Date()]);
                    setToDay1([new Date()]);
                    setAddressLine('');
                    setAddressCity('');
                    setAddressCountry('');
                    setUrl1('');
                    setIdType(['']);
                    setValue(['']);
                    setcategory('');
                    setDnotes('');
                }
            })
            .catch((error) => {
                setLoading(false);
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                } else if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    sessionStorage.clear();
                } else if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                }
            });
    }

    const renderStep1 = () => (
        <>
            <div className="feild c">
                <div className="heading">Account Details</div>
                <div className="flex">
                    <div className="input">
                        <div className="label">Eid<span>*</span></div>
                        <InputText className="w-full" defaultValue={eidRef.current} type="number" onChange={(event) => setEid(event.target.value)} />
                    </div>

                    <div className="input">
                        <div className="label">Gender</div>
                        <InputText className="w-full" defaultValue={genderRef.current} onChange={(event) => setGender(event.target.value)} />
                    </div>

                    <div className="input">
                        <div className="label">Active Status</div>
                        <InputText className="w-full" defaultValue={activeStatusRef.current} onChange={(event) => setActiveStatus(event.target.value)} />
                    </div>
                </div>
                <div className="flex">
                    <div className="input">
                        <div className="label"> Deceased</div>
                        <InputText className="w-full" defaultValue={deceasedRef.current} onChange={(event) => setDeceased(event.target.value)} />
                    </div>

                    <div className="input">
                        <div className="label">Notes</div>
                        <InputText className="w-full" defaultValue={notesRef.current} onChange={(event) => setNotes(event.target.value)} />
                    </div>
                </div>

                <div className="heading">Names</div>

                <div className="flex">
                    <div className="input ">
                        <div className="label">Suffix</div>
                        <InputText className="w-full" defaultValue={suffixRef.current} onChange={(event) => setSuffix(event.target.value)} />
                    </div>

                    <div className="input">
                        <div className="label">First Name<span>*</span></div>
                        <InputText className="w-full" defaultValue={firstNameRef.current} onChange={(event) => setFirstName(event.target.value)} />
                    </div>

                    <div className="input">
                        <div className="label">Middle Name</div>
                        <InputText className="w-full" defaultValue={middleNameRef.current} onChange={(event) => setMiddleName(event.target.value)} />
                    </div>
                </div>
                <div className="flex">
                    <div className="input">
                        <div className="label">Sur Name</div>
                        <InputText className="w-full" defaultValue={surenameRef.current} onChange={(event) => setSurename(event.target.value)} />
                    </div>

                    <div className="input">
                        <div className="label">Original Script Name</div>
                        <InputText className="w-full" defaultValue={originalScriptNameRef.current} onChange={(event) => setOriginalScriptName(event.target.value)} />
                    </div>

                    <div className="input">
                        <div className="label">Maiden Name</div>
                        <InputText className="w-full" defaultValue={maidenNameRef.current} onChange={(event) => setMaidenName(event.target.value)} />
                    </div>
                </div>
                <div className="flex">
                    <div className="input">
                        <div className="label">Title Honorific</div>
                        <InputText className="w-full" defaultValue={titleHonorificRef.current} onChange={(event) => setTitleHonorific(event.target.value)} />
                    </div>

                    <div className="input">
                        <div className="label">Single String Name</div>
                        <InputText className="w-full" defaultValue={singleStringNameRef.current} onChange={(event) => setSingleStringName(event.target.value)} />
                    </div>
                </div>
                <div className="flex">
                    <div className="input" >
                        <div className="label">Alias Name</div>
                        <div className="" style={{ width: '100%', display: 'flex', flexDirection: 'column' }} id="alias-name">
                            {aliasNames.map((alias, index) => (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', }}>
                                        <div class="InputContainer-sc-10zlk7a-0 fXKhdW w-full">
                                            <InputText
                                                value={alias}
                                                onChange={(e) => handleAliasChange(index, e.target.value)}
                                                id={`alias-${index}`}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                        <div className="alias-btn">
                                            <Button
                                                onClick={(e) => removeAlias(index, e)}
                                                disabled={aliasNames.length === 1}
                                                style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }}
                                            >
                                                <i className="pi pi-minus" />
                                            </Button>
                                        </div>
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }} disabled={!alias} onClick={(event) => addAlias(event)} ><i className="pi pi-plus" /></Button>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    const renderStep2 = () => (
        <>
            <div className="feild c">

                <div className="heading">Description</div>

                <div className="flex">
                    <div className="input">
                        <div className="label">Description</div>
                        <div className="w-full" style={{ width: '100%', display: 'flex', gap: '5px', flexDirection: 'column' }}>
                            {descriptions.map((alias, index) => (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', }}>
                                        <div class="InputContainer-sc-10zlk7a-0 fXKhdW w-full">
                                            <InputText
                                                value={alias}
                                                onChange={(e) => handledescription(index, e.target.value)}
                                                id={`alias-${index}`}
                                                className="w-full"
                                            />
                                        </div>
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }} onClick={(e) => removedescription(index, e)} disabled={descriptions.length === 1} ><i className="pi pi-minus" /></Button>
                                        </div>
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }} onClick={(event) => adddescription(event)} disabled={!alias}><i className="pi pi-plus" /></Button>
                                        </div>
                                    </div>
                                </>
                            ))}

                        </div>
                    </div>
                </div>

                <div className="heading">Role</div>

                <div className="flex">
                    <div className="input">
                        <div className="label">Role Type</div>
                        <div style={{ width: '100%', display: 'flex', gap: '5px', flexDirection: 'column' }} id="alias-name">
                            {roleType.map((alias, index) => (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', }}>
                                        <div class="InputContainer-sc-10zlk7a-0 fXKhdW w-full">
                                            <InputText
                                                value={alias}
                                                onChange={(e) => handleroleType(index, e.target.value)}
                                                id={`alias-${index}`}
                                                className="w-full"
                                            />
                                        </div>
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }} onClick={(e) => removeroleType(index, e)} disabled={roleType.length === 1} ><i className="pi pi-minus" /></Button>
                                        </div>
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }} onClick={(event) => addroleType(event)} disabled={!alias}><i className="pi pi-plus" /></Button>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                    <div className="input">
                        <div className="label">Title</div>
                        <div className="w-full" style={{ width: '100%', display: 'flex', gap: '5px', flexDirection: 'column' }} id="alias-name">
                            {title.map((alias, index) => (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', }}>
                                        <div class="InputContainer-sc-10zlk7a-0 fXKhdW w-full">
                                            <InputText
                                                value={alias}
                                                onChange={(e) => handletitle(index, e.target.value)}
                                                id={`alias-${index}`}
                                                className="w-full"
                                            />
                                        </div>
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }} onClick={(e) => removetitle(index, e)} disabled={title.length === 1} ><i className="pi pi-minus" /></Button>
                                        </div>
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }} onClick={(event) => addtitle(event)} disabled={!alias}><i className="pi pi-plus" /></Button>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="flex">
                    <div className="input">
                        <div className="label">Since Day</div>
                        <div style={{ width: '100%', display: 'flex', gap: '5px', flexDirection: 'column' }} id="alias-name">
                            {sinceDay.map((date, index) => (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', }}>
                                        <div key={index} class="InputContainer-sc-10zlk7a-0 fXKhdW w-full">
                                            <Calendar
                                                showIcon
                                                id="p-calender"
                                                className="w-full calander"
                                                onChange={(e) => {
                                                    const selectedDate = e.value.toLocaleDateString(); // Convert to standard format
                                                    handlesinceDay(index, selectedDate);
                                                }}

                                            />
                                        </div >
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }} onClick={(e) => removesinceDay(index, e)} disabled={sinceDay.length === 1} ><i className="pi pi-minus" /></Button>
                                        </div>
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }} onClick={(event) => addsinceDay(event)} ><i className="pi pi-plus" /></Button>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                    <div className="input">
                        <div className="label">To Day</div>
                        <div style={{ width: '100%', display: 'flex', gap: '5px', flexDirection: 'column' }} id="alias-name">
                            {toDay.map((date, index) => (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', }}>
                                        <div key={index} class="InputContainer-sc-10zlk7a-0 fXKhdW w-full">
                                            <Calendar
                                                showIcon
                                                id="p-calender"
                                                className="w-full calander"
                                                onChange={(e) => {
                                                    const selectedtoDay = e.value.toLocaleDateString(); // Convert to standard format
                                                    handletoDay(index, selectedtoDay);
                                                }}

                                            />
                                        </div>
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }} onClick={(e) => removetoDay(index, e)} disabled={toDay.length === 1} ><i className="pi pi-minus" /></Button>
                                        </div>
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }} onClick={(event) => addtoDay(event)}  ><i className="pi pi-plus" /></Button>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="flex">
                    <div className="input">
                        <div className="label">Occupational Category</div>
                        <InputText className="w-full" defaultValue={occCatRef.current} style={{ width: '100%' }} onChange={(event) => setOccCat(event.target.value)} />
                    </div>
                </div>
            </div>

        </>
    );

    const renderStep3 = () => (
        <>
            <div className="feild c">

                <div className="heading">Date</div>

                <div className="flex">
                    <div className="input">
                        <div className="label"> Date Type</div>
                        <div style={{ width: '100%', display: 'flex', gap: '5px', flexDirection: 'column' }} id="alias-name">

                            {dateType.map((alias, index) => (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', }}>
                                        <div key={index} className="InputContainer-sc-10zlk7a-0 fXKhdW w-full">
                                            <InputText
                                                className="w-full"
                                                value={alias}
                                                onChange={(e) => handledateType(index, e.target.value)}
                                                id={`alias-${index}`}
                                            />
                                        </div>
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }}
                                                disabled={dateType.length === 1}
                                            ><i className="pi pi-minus" /></Button>
                                        </div>
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }}
                                                onClick={(event) => adddateType(event)}
                                            ><i className="pi pi-plus" /></Button>
                                        </div>
                                    </div>
                                </>
                            ))}

                        </div>
                    </div>
                    <div className="input">
                        <div className="label">Date Value</div>
                        <div style={{ width: '100%', display: 'flex', gap: '5px', flexDirection: 'column' }} id="alias-name">

                            {dateValue.map((date, index) => (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', }}>
                                        <div key={index} className="InputContainer-sc-10zlk7a-0 fXKhdW w-full">
                                            <Calendar
                                                showIcon
                                                id="p-calender"
                                                className="w-full calander"
                                                onChange={(e) => {
                                                    const selectedtoDay = e.value.toLocaleDateString(); // Convert to standard format
                                                    handledateValue(index, selectedtoDay);
                                                }}
                                            />

                                        </div>
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }}
                                                onClick={(e) => removedateValue(index, e)}
                                                disabled={dateValue.length === 1}
                                            ><i className="pi pi-minus" /></Button>
                                        </div>
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }}
                                                onClick={(event) => adddateValue(event)}
                                            ><i className="pi pi-plus" /></Button>
                                        </div>
                                    </div>
                                </>
                            ))}

                        </div>
                    </div>
                </div>
                <div className="flex">
                    <div className="input">
                        <div className="label">Date Notes</div>
                        <InputText className="w-full" defaultValue={dnotesRef.current} style={{ width: '100%' }} onChange={(event) => setDnotes(event.target.value)} />
                    </div>
                </div>

                <div className="heading">Birth Place</div>

                <div className="flex">
                    <div className="input">
                        <div className="label">Birth Place<span>*</span></div>
                        <InputText defaultValue={placeRef.current} className="w-full" onChange={(event) => setPlace(event.target.value)} />
                    </div>
                </div>

                <div className="heading">Country Details</div>

                <div className="flex">

                    <div className="input">
                        <div className="label">Country Type<span>*</span></div>
                        <InputText defaultValue={countryTypeRef.current} className="w-full" onChange={(event) => setCountryType(event.target.value)} />
                    </div>
                </div>
                <div className="flex">
                    <div className="input">
                        <div className="label">Code</div>
                        <InputText defaultValue={codeRef.current} className="w-full" onChange={(event) => setCode(event.target.value)} />
                    </div>
                </div>
            </div>
        </>
    );

    const renderStep4 = () => (
        <>
            <div className="feild c">

                <div className="heading">Images</div>

                <div className="flex">
                    <div className="input">
                        <div className="label">Url</div>
                        <InputText defaultValue={urlRef.current} className="w-full" onChange={(event) => setUrl(event.target.value)} />
                    </div>
                </div>

                <div className="heading">Sanctions References</div>

                <div className="flex">
                    <div className="input">
                        <div className="label">Reference</div>
                        <div style={{ width: '100%', display: 'flex', gap: '5px', flexDirection: 'column' }} id="alias-name">

                            {reference.map((alias, index) => (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', }}>
                                        <div key={index} className="InputContainer-sc-10zlk7a-0 fXKhdW w-full">
                                            <InputText
                                                className="w-full"
                                                value={alias}
                                                onChange={(e) => handlereference(index, e.target.value)}
                                                id={`alias-${index}`}
                                            />
                                        </div>
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }}
                                                onClick={(e) => removereference(index, e)}
                                                disabled={reference.length === 1}
                                            ><i className="pi pi-minus" /></Button>
                                        </div>
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }}
                                                onClick={(event) => addreference(event)}
                                            ><i className="pi pi-plus" /></Button>
                                        </div>
                                    </div>
                                </>
                            ))}

                        </div>
                    </div>

                    <div className="input">
                        <div className="label"> Since Day 1</div>
                        <div style={{ width: '100%', display: 'flex', gap: '5px', flexDirection: 'column' }} id="alias-name">

                            {sinceDay1.map((date, index) => (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', }}>
                                        <div key={index} className="InputContainer-sc-10zlk7a-0 fXKhdW w-full">
                                            <Calendar
                                                showIcon
                                                id="p-calender"
                                                className="w-full calander"
                                                onChange={(e) => {
                                                    const selectedtoDay = e.value.toLocaleDateString();
                                                    handlesinceDay1(index, selectedtoDay);
                                                }}
                                            />
                                        </div>
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }}
                                                onClick={(e) => removesinceDay1(index, e)}
                                                disabled={sinceDay1.length === 1}
                                            ><i className="pi pi-minus" /></Button>
                                        </div>
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }}
                                                onClick={(event) => addsinceDay1(event)}
                                            ><i className="pi pi-plus" /></Button>
                                        </div>
                                    </div>
                                </>
                            ))}

                        </div>
                    </div>
                </div>
                <div className="flex">
                    <div className="input">
                        <div className="label">To Day 1</div>
                        <div style={{ width: '100%', display: 'flex', gap: '5px', flexDirection: 'column' }} id="alias-name">

                            {toDay1.map((date, index) => (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', }}>
                                        <div key={index} className="p-float-label w-full">
                                            <Calendar
                                                showIcon
                                                id="p-calender"
                                                className="w-full calander"
                                                onChange={(e) => {
                                                    const selectedtoDay = e.value.toLocaleDateString(); // Convert to standard format
                                                    handletoDay1(index, selectedtoDay);
                                                }}
                                            />
                                        </div>
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }}
                                                onClick={(e) => removehandletoDay1(index, e)}
                                                disabled={toDay1.length === 1}
                                            ><i className="pi pi-minus" /></Button>
                                        </div>
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }}
                                                onClick={(event) => addhandletoDay1(event)}
                                            ><i className="pi pi-plus" /></Button>
                                        </div>
                                    </div>
                                </>
                            ))}

                        </div>
                    </div>
                </div>

                <div className="heading">Address</div>

                <div className="flex">
                    <div className="input">
                        <div className="label">Address Line <span>*</span></div>
                        <InputText defaultValue={addressLineRef.current} className="w-full" onChange={(event) => setAddressLine(event.target.value)} />
                    </div>
                    <div className="input">
                        <div className="label">Address City<span>*</span></div>
                        <InputText defaultValue={addressCityRef.current}className="w-full" onChange={(event) => setAddressCity(event.target.value)} />
                    </div>
                </div>
                <div className="flex">
                    <div className="input">
                        <div className="label">Address Country<span>*</span></div>
                        <InputText defaultValue={addressCountryRef.current} className="w-full" onChange={(event) => setAddressCountry(event.target.value)} />
                    </div>
                    <div className="input">
                        <div className="label">Url 1</div>
                        <InputText defaultValue={url1Ref.current} className="w-full" onChange={(event) => setUrl1(event.target.value)} />
                    </div>
                </div >
            </div>

        </>
    );

    const renderStep5 = () => (
        <>
            <div className="feild c">

                <div className="heading">Source Description</div>

                <div className="flex">
                    <div className="input">
                        <div className="label">Source Name</div>
                        <InputText defaultValue={sourceNameRef.current} className="w-full" style={{ width: '100%' }} onChange={event => setSourcename(event.target.value)} />
                    </div>
                </div>

                <div className="heading"> Id Number Type</div>

                <div className="flex">
                    <div className="input">
                        <div className="label">Id Type</div>
                        <div style={{ width: '100%', display: 'flex', gap: '5px', flexDirection: 'column' }} id="alias-name">

                            {idType.map((alias, index) => (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', }}>
                                        <div key={index} className="InputContainer-sc-10zlk7a-0 fXKhdW w-full">
                                            <InputText
                                                value={alias}
                                                onChange={(e) => handleidType(index, e.target.value)}
                                                id={`alias-${index}`}
                                                className="w-full"
                                            />
                                        </div>
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }}
                                                onClick={(e) => removeidType(index, e)}
                                                disabled={idType.length === 1}
                                            ><i className="pi pi-minus" /></Button>
                                        </div>
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }}
                                                onClick={(event) => addidType(event)}
                                            ><i className="pi pi-plus" /></Button>
                                        </div>
                                    </div>
                                </>
                            ))}

                        </div>
                    </div>
                </div>
                <div className="flex">
                    <div className="input">
                        <div className="label">Id Value</div>
                        <div style={{ width: '100%', display: 'flex', gap: '5px', flexDirection: 'column' }} id="alias-name">

                            {value.map((alias, index) => (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', }}>
                                        <div key={index} className="InputContainer-sc-10zlk7a-0 fXKhdW w-full">
                                            <InputText
                                                value={alias}
                                                onChange={(e) => handlevalue(index, e.target.value)}
                                                id={`alias-${index}`}
                                                className="w-full"
                                            />
                                        </div>
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }}
                                                onClick={(e) => removevalue(index, e)}
                                                disabled={value.length === 1}
                                            ><i className="pi pi-minus" /></Button>
                                        </div>
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }}
                                                onClick={(event) => addvalue(event)}
                                            ><i className="pi pi-plus" /></Button>
                                        </div>
                                    </div>
                                </>
                            ))}

                        </div>
                    </div>
                    <div className="input">
                        <div className="label">Id Expire Date</div>
                        <div style={{ width: '100%', display: 'flex', gap: '5px', flexDirection: 'column' }} id="alias-name">

                            {idExpiryDate.map((date, index) => (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', }}>
                                        <div key={index} className="InputContainer-sc-10zlk7a-0 fXKhdW w-full">
                                            <Calendar
                                                showIcon
                                                id="p-calender"
                                                className="w-full calander"
                                                onChange={(e) => {
                                                    const selectedtoDay = e.value.toLocaleDateString();
                                                    handleidExpiryDate(index, selectedtoDay);
                                                }}
                                                minDate={new Date()}
                                            />
                                        </div>
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }}
                                                onClick={(e) => removeidExpiryDate(index, e)}
                                                disabled={idExpiryDate.length === 1}
                                            ><i className="pi pi-minus" /></Button>
                                        </div>
                                        <div className="alias-btn">
                                            <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }}
                                                onClick={(event) => addidExpiryDate(event)}
                                            ><i className="pi pi-plus" /></Button>
                                        </div>
                                    </div>
                                </>
                            ))}

                        </div>
                    </div>
                </div>
                <div className="flex">
                    <div className="input">
                        <div className="label">Category</div>
                        <InputText defaultValue={categoryRef.current} style={{ width: '100%' }} className="w-full" onChange={(event) => setcategory(event.target.value)} />
                    </div>
                </div>
            </div>

        </>
    );

    // const handleStepClick = () => {
    //     displayWarnToast("Please fill the details....");
    // };

    // const renderStepIndicator = () => {
    //     return (
    //         <div className="step-indicator al">
    //             <div className="step-item-container">
    //                 {Array.from({ length: totalSteps }, (_, index) => (
    //                     <div
    //                         key={index}
    //                         className={`step-item ${index === step ? 'active-on' : ''}`}
    //                         onClick={handleStepClick}
    //                     >
    //                         {index + 1}
    //                         {index !== totalSteps - 1 && (
    //                             <div className={`step-line ${index < step ? 'active-line' : 'inactive-line'}`} />
    //                         )}
    //                     </div>
    //                 ))}
    //             </div>
    //         </div>
    //     );
    // };
    // const [selectedFile, setSelectedFile] = useState(null);

    // const handleFileUpload = (event) => {
    //     const file = event.target.files[0];
    //     setSelectedFile(file);
    // };

    // const handleUpdate = async (event) => {
    //     event.preventDefault();
    //     if (selectedFile) {
    //         try {
    //             const formData = new FormData();
    //             formData.append('file', selectedFile);

    //             const response = await axios.post(
    //                 process.env.REACT_APP_BACKEND_HOST + '/sanction/fileupload',
    //                 formData,
    //                 {
    //                     headers: {
    //                         'Content-Type': 'multipart/form-data',
    //                         Authorization: 'Bearer ' + props?.data?.accessToken
    //                     },
    //                 }

    //             );

    //         } catch (error) {
    //             // console.error(error);
    //         }
    //     }
    // };
    // const [isSticky, setIsSticky] = useState(false);
    // useEffect(() => {
    //     const handleScroll = () => {
    //         setIsSticky(window.scrollY > 0);
    //     };
    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    return (
        <>
            <Toast ref={toast} position="top-right" style={{ top: '10%' }} />
            <div className="card overflow-auto" style={{ top: windowWidth <= 500 ? 'unset' : '' }}>
                <div className="" id="AR-client-card">
                    <div className=" col-12 md-12 lg-8  surface-border border-round card-regist ">
                        <div className=" relative overflow-hidden" id="sanction-updates-step-indicator">

                            {/* <form > */}
                            {step === 0 && renderStep1()}
                            {step === 1 && renderStep2()}
                            {step === 2 && renderStep3()}
                            {step === 3 && renderStep4()}
                            {step === 4 && renderStep5()}

                            <div className="footer_sec_btn f_p" style={{ justifyContent: 'end', alignItems: windowWidth <= 500 ? 'flex-start' : '' }}>
                                {step > 0 && (
                                    <div className="flex align-items-center justify-content-center">
                                        <Button
                                            onClick={handlePrevious}
                                            style={{ backgroundColor: '#124A99' }}
                                        >
                                            Previous
                                        </Button>
                                    </div>
                                )}
                                {step === 0 && (
                                    <div className="flex align-items-center justify-content-center">
                                        <Button
                                            onClick={handleNext}
                                            disabled={!eid || !firstName}
                                            style={{ backgroundColor: '#124A99' }}
                                        >Next
                                        </Button>
                                    </div>
                                )}
                                {step === 1 && (
                                    <div className="flex align-items-center justify-content-center">
                                        <Button
                                            onClick={handleNext1}
                                            style={{ backgroundColor: '#124A99' }}
                                        >Next
                                        </Button>
                                    </div>
                                )}
                                {step === 2 && (
                                    <div className="flex align-items-center justify-content-center">
                                        <Button
                                            onClick={handleNext2}
                                            style={{ backgroundColor: '#124A99' }}
                                        >Next
                                        </Button>
                                    </div>
                                )}
                                {step === 3 && (
                                    <div className="flex align-items-center justify-content-center">
                                        <Button
                                            onClick={handleNext3}
                                            style={{ backgroundColor: '#124A99' }}
                                        >Next
                                        </Button>
                                    </div>
                                )}
                                {step === 4 && (
                                    <div className="flex align-items-center justify-content-center">
                                        <Button
                                            style={{ backgroundColor: '#124A99' }} onClick={sansationsubmit} >
                                            Submit
                                        </Button>
                                    </div>
                                )}
                            </div>
                            {/* </form> */}

                        </div>

                    </div>

                </div>
            </div>

            {loading ?
                <div class="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                    <Triangle
                        height="80"
                        width="80"
                        color="#124A99"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
                : null}

        </>
    )
}

export default SansactionUpdates
