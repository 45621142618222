import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Triangle } from 'react-loader-spinner';

import './Adhocsearch.css'
// import { Button } from 'primereact/button';
import { useLocation, useHistory } from 'react-router-dom';
import '../../../../views/Loading.css';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Divider } from 'primereact/divider';


function AdhocSearchMoreinfo(props) {


    let databyid = sessionStorage.getItem('individualmoreinfovalue');
    const [accountdetials, setAccountDetials] = useState('');
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const location = useLocation();


    useEffect(() => {
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);


    useEffect(() => {
        setLoading(true);
        axios.get(process.env.REACT_APP_BACKEND_HOST + '/sanction?id=' + databyid, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
            }
        })
            .then(response => {
                setAccountDetials(response.data); // Extract the data array from the response
                setLoading(false);
            })
            .catch(error => {
                // Handle the error
            });
    }, [databyid]);

    const exactNames = accountdetials?.exactName?.map((nameObj, index) => {
        const name = nameObj.name;
        const matchType = nameObj.matchType;

        const addFontColorAndSpaces = (text, matchType) => {
            const englishLetterRegex = /[a-zA-Z]/;
            const parts = [];
            let currentPart = ' ';

            const className = matchType === "bg-orange-500" ? "font-orange-500" : "";

            for (let i = 0; i < text.length; i++) {
                const char = text.charAt(i);
                if (englishLetterRegex.test(char)) {
                    currentPart += `<span class="${className}">${char}</span>`;
                } else if (char === ' ') {
                    currentPart += ', ';
                } else {
                    currentPart += char;
                }
            }

            parts.push(currentPart);
            return parts.join('');
        };

        const comma = index < (accountdetials?.exactName?.length - 1) ? ' ,  ' : ' ';
        return (
            <span key={name}>
                <span
                    style={{ fontSize: '20px', fontWeight: 'bold' }}
                    dangerouslySetInnerHTML={{ __html: addFontColorAndSpaces(name, matchType) }}
                />
                {comma}
            </span>
        );
    });
    let activestatus = accountdetials.activeStatus;
    // let aliasData = accountdetials.alias;
    // let countriesAssociatedWith = accountdetials.countriesAssociatedWith;
    // let ids = accountdetials.ids;

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const items = [
        {
            label: 'Adhoc Search', command: () =>
                history.push({
                    pathname: '/admin/adhoc/search',
                    state: {
                        accessToken: location?.state?.accessToken,
                        emailId: location?.state?.emailId,
                        getUserData: location?.state?.getUserData,
                    },
                })
        },
        {
            label: 'Individual Search Data', command: () =>
                history.push({
                    pathname: '/admin/adhoc/corporateadvancetable',
                    state: {
                        accessToken: location?.state?.accessToken,
                        emailId: location?.state?.emailId,
                        getUserData: location?.state?.getUserData,
                        fullName: location?.state?.individualname,
                        exact: location?.state?.exactvalue,
                        partial: location?.state?.partialvalue,
                        wrinklerDistance: location?.state?.eightyvalue,
                    },
                })
        },
        { label: 'Adhoc Search MoreInfo' }
    ];
  
    const home = location?.state?.getUserData?.premiumPlan === "FreeTrial" ? null : {
        icon: 'pi pi-home', 
        command: () => history.push({
            pathname: '/admin/dashboard',
            state: {
                accessToken: location?.state?.accessToken,
                emailId: location?.state?.emailId,
                getUserData: location?.state?.getUserData,
            },
        })
    };

    // const handleNavigate = (url) => {
    //     history.push(url);
    // };
    const home1 = { icon: 'pi pi-angle-double-left', command: () => window.history.back() }

    return (
        <>
            <div className="container h-max">
                {loading ? (
                    <div class="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                        <Triangle
                            height="80"
                            width="80"
                            color="#124A99"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <div className="card card-Adhoc" style={{ background: '#fff' }}>
                        <BreadCrumb model={items} className='breadCrumb' home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />
                        <div className="col-12 md-6">
                            <div className="card col-12 md-12 lg-8 border-1 border-solid surface-border border-round Adhocsearch">
                                <div className='cor-first-box'>
                                    <div>
                                        <h2 className='exact-name-h2' style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'center', marginTop: '37px', flexWrap: 'wrap', justifyContent: 'center' }}> {exactNames}</h2>
                                    </div>
                                    <div class="formgrid grid p-4">
                                        <Divider align="left" className='ml-2'>
                                            <div className="inline-flex align-items-center">
                                                <i className="pi pi-user mr-2 font-bold"></i>
                                                <b className="text-sm">PERSONAL DETAILS</b>
                                            </div>
                                        </Divider>
                                        {accountdetials && (
                                            <>
                                                {accountdetials?.activeStatus && (
                                                    <div className="field col-12 md:col-4 pt-3 Adthoc-input">
                                                        <div className='flex justify-content-between'>

                                                            <div className="label-form w-5" style={{ fontWeight: '650', fontSize: '14px', color: '#837d7d' }}  >ACTIVE STATUS</div>
                                                            <div className='w-1'>:</div>
                                                            <div className='w-5' style={{ color: '#7f7878' }}>{activestatus}</div>
                                                        </div>
                                                    </div>
                                                )}
                                                {accountdetials?.gender && (
                                                    <div className="field col-12 md:col-4 pt-3 Adthoc-input">
                                                        <div className='flex justify-content-between'>

                                                            <div className="label-form w-5" style={{ color: '#837d7d' }}  >GENDER </div>
                                                            <div className='w-1'>:</div>
                                                            <div className='w-5' style={{ color: '#7f7878' }}>{accountdetials.gender}</div>
                                                        </div>
                                                    </div>
                                                )}
                                                {accountdetials?.id && (
                                                    <div className="field col-12 md:col-4 pt-3 Adthoc-input">
                                                        <div className='flex justify-content-between'>

                                                            <div className="label-form w-5" style={{ fontWeight: '650', fontSize: '14px', color: '#837d7d' }}  > ID</div>
                                                            <div className='w-1'>:</div>
                                                            <div className='w-5' style={{ color: '#7f7878' }}>{accountdetials.id}</div>
                                                        </div>
                                                    </div>
                                                )}
                                                {accountdetials?.addresses?.length > 0 && (
                                                    <>
                                                        {accountdetials?.addresses[0]?.address && (
                                                            <div className="field col-12 md:col-4 pt-3 Adthoc-input">
                                                                <div className='flex justify-content-between'>
                                                                    <div className="label-form w-5" style={{ fontWeight: '600', fontSize: '18px', color: '#837d7d'||'rgb(143 143 143)' }}> Address</div>
                                                                    <div className='w-1'>:</div>
                                                                    <div className='w-5' style={{ color: '#7f7878' }}>{accountdetials.addresses[0]?.address}</div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {accountdetials?.addresses[0]?.city && (

                                                            <div className="field col-12 md:col-4 pt-3 Adthoc-input">
                                                                <div className='flex justify-content-between'>
                                                                    <div className="label-form w-5" style={{ fontWeight: '600', fontSize: '18px', color: '#837d7d'|| 'rgb(143 143 143)' }}> City</div>
                                                                    <div className='w-1'>:</div>
                                                                    <div className='w-5' style={{ color: '#7f7878' }}>{accountdetials.addresses[0]?.city}</div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {accountdetials?.addresses[0]?.country && (
                                                            <div className="field col-12 md:col-4 pt-3 Adthoc-input">
                                                                <div className='flex justify-content-between'>
                                                                    <div className="label-form w-5" style={{ fontWeight: '600', fontSize: '18px', color: '#837d7d'|| 'rgb(143 143 143)' }}> Country</div>
                                                                    <div className='w-1'>:</div>
                                                                    <div className='w-5' style={{ color: '#7f7878' }}>{accountdetials.addresses[0]?.country}</div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                                {accountdetials?.modifiedDate && (
                                                    <div className="field col-12 md:col-4 pt-3 Adthoc-input">
                                                        <div className='flex justify-content-between'>
                                                            <div className="label-form w-5" style={{ fontWeight: '650', fontSize: '14px', color: '#837d7d' }}  >MODIFIED DATE </div>
                                                            <div className='w-1'>:</div>
                                                            <div className='w-5' style={{ color: '#7f7878' }}>{accountdetials.modifiedDate}</div>
                                                        </div>
                                                    </div>
                                                )}
                                                {accountdetials?.notes && (
                                                    <div className="field col-12 md:col-4 pt-3 Adthoc-input">
                                                        <div className='flex justify-content-between'>
                                                            <div className="label-form w-5" style={{ fontWeight: '650', fontSize: '14px', color: '#837d7d' }}  > NOTES</div>
                                                            <div className='w-1'>:</div>
                                                            <div className='w-5' style={{ color: '#7f7878' }}>{accountdetials.notes}</div>
                                                        </div>
                                                    </div>
                                                )}
                                                {accountdetials?.description && (
                                                    <div className="field col-12 md:col-4 pt-3 Adthoc-input">
                                                        <div className='flex justify-content-between'>
                                                            <div className="label-form w-5" style={{ fontWeight: '650', fontSize: '14px', color: '#837d7d' }}>DESCRIPTION</div>
                                                            <div className='w-1'>:</div>
                                                            <div className='w-5' style={{ color: '#7f7878' }}>{accountdetials.description}</div>
                                                        </div>
                                                    </div>
                                                )}
                                                {accountdetials?.decreased && (
                                                    <div className="field col-12 md:col-4 pt-3 Adthoc-input">
                                                        <div className='flex justify-content-between'>
                                                            <div className="label-form w-5" style={{ fontWeight: '650', fontSize: '14px', color: '#837d7d' }}>DECREASED</div>
                                                            <div className='w-1'>:</div>
                                                            <div className='w-5' style={{ color: '#7f7878' }}>{accountdetials.decreased}</div>
                                                        </div>
                                                    </div>
                                                )}
                                                {accountdetials?.matchPoints && (
                                                    <div className="field col-12 md:col-4 pt-3 Adthoc-input">
                                                        <div className='flex justify-content-between'>
                                                            <div className="label-form w-5" style={{ fontWeight: '650', fontSize: '14px', color: '#837d7d' }}>MATCH POINTS</div>
                                                            <div className='w-1'>:</div>
                                                            <div className='w-5' style={{ color: '#7f7878' }}>{accountdetials.matchPoints}</div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )}

                                        <Divider align="left" className='ml-2'>
                                            <div className="inline-flex align-items-center">
                                                <i className="pi pi-calendar mr-2 font-bold"></i>
                                                <b className="text-sm">DATES</b>
                                            </div>
                                        </Divider>
                                        {accountdetials?.dates && accountdetials.dates.length > 0 ? (
                                            <div className="formgrid grid w-full p-4">
                                                {accountdetials.dates.map((dateObj, index) => (
                                                    <div key={index} className="formgrid grid w-full">
                                                        {dateObj.dateType !== null && dateObj.dateType !== '' && (
                                                            <div className="field col-12 md:col-4 pt-3 Adthoc-input">
                                                                <div className='flex justify-content-between'>
                                                                    <div className="label-form w-5" style={{ fontWeight: '650', fontSize: '14px', color: '#837d7d' }}>DATE TYPE</div>
                                                                    <div className='w-1'>:</div>
                                                                    <div className='w-5' style={{ color: '#7f7878' }}>{dateObj.dateType}</div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {dateObj.date !== null && dateObj.date !== '' && (
                                                            <div className="field col-12 md:col-4 pt-3 Adthoc-input">
                                                                <div className='flex justify-content-between'>
                                                                    <div className="label-form w-5" style={{ fontWeight: '650', fontSize: '14px', color: '#837d7d' }}>DATE</div>
                                                                    <div className='w-1'>:</div>
                                                                    <div className='w-5' style={{ color: '#7f7878' }}>{dateObj.date}</div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {dateObj.dateFormat !== null && dateObj.dateFormat !== '' && (
                                                            <div className="field col-12 md:col-4 pt-3 Adthoc-input">
                                                                <div className='flex justify-content-between'>
                                                                    <div className="label-form w-5" style={{ fontWeight: '650', fontSize: '14px', color: '#837d7d' }}>DATE FORMAT</div>
                                                                    <div className='w-1'>:</div>
                                                                    <div className='w-5' style={{ color: '#7f7878' }}>{dateObj.dateFormat}</div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {dateObj.matchType !== null && dateObj.matchType !== '' && (
                                                            <div className="field col-12 md:col-4 pt-3 Adthoc-input">
                                                                <div className='flex justify-content-between'>
                                                                    <div className="label-form w-5" style={{ fontWeight: '650', fontSize: '14px', color: '#837d7d' }}>MATCH TYPE</div>
                                                                    <div className='w-1'>:</div>
                                                                    <div className='w-5' style={{ color: '#7f7878' }}>{dateObj.matchType}</div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {dateObj.start !== null && dateObj.start !== '' && (
                                                            <div className="field col-12 md:col-4 pt-3 Adthoc-input">
                                                                <div className='flex justify-content-between'>
                                                                    <div className="label-form w-5" style={{ fontWeight: '650', fontSize: '14px', color: '#837d7d' }}>START</div>
                                                                    <div className='w-1'>:</div>
                                                                    <div className='w-5' style={{ color: '#7f7878' }}>{dateObj.start}</div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {dateObj.end !== null && dateObj.end !== '' && (
                                                            <div className="field col-12 md:col-4 pt-3 Adthoc-input">
                                                                <div className='flex justify-content-between'>
                                                                    <div className="label-form w-5" style={{ fontWeight: '650', fontSize: '14px', color: '#837d7d' }}>END</div>
                                                                    <div className='w-1'>:</div>
                                                                    <div className='w-5' style={{ color: '#7f7878' }}>{dateObj.end}</div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <p className='text-center'>No Dates available</p>
                                        )}
                                        <Divider align="left" className='ml-2'>
                                            <div className="inline-flex align-items-center">
                                                <i className="pi pi-id-card mr-2 font-bold"></i>
                                                <b className="text-sm">IDS</b>
                                            </div>
                                        </Divider>

                                        <Divider align="left" className='ml-2'>
                                            <div className="inline-flex align-items-center">
                                                <i className="pi pi-users mr-2 font-bold"></i>
                                                <b className="text-sm">ROLES</b>
                                            </div>
                                        </Divider>
                                        {accountdetials.roles && accountdetials.roles.length > 0 && (
                                            <div className="formgrid grid w-full p-4">
                                                {accountdetials.roles.map((role, index) => (
                                                    <div key={index} className="formgrid grid w-full">
                                                        {role.type && (
                                                            <div className="field col-12 md:col-4 pt-3 Adthoc-input">
                                                                <div className='flex justify-content-between'>
                                                                    <div className="label-form w-5" style={{ fontWeight: '650', fontSize: '14px', color: '#837d7d' }}>ROLE TYPE</div>
                                                                    <div className='w-1'>:</div>
                                                                    <div className='w-5' style={{ color: '#7f7878' }}>{role.type}</div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {role.title && (
                                                            <div className="field col-12 md:col-4 pt-3 Adthoc-input">
                                                                <div className='flex justify-content-between'>
                                                                    <div className="label-form w-5" style={{ fontWeight: '650', fontSize: '14px', color: '#837d7d' }}>TITLE</div>
                                                                    <div className='w-1'>:</div>
                                                                    <div className='w-5' style={{ color: '#7f7878' }}>{role.title}</div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {role.from && (
                                                            <div className="field col-12 md:col-4 pt-3 Adthoc-input">
                                                                <div className='flex justify-content-between'>
                                                                    <div className="label-form w-5" style={{ fontWeight: '650', fontSize: '14px', color: '#837d7d' }}>FROM</div>
                                                                    <div className='w-1'>:</div>
                                                                    <div className='w-5' style={{ color: '#7f7878' }}>{role.from}</div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {role.to && (
                                                            <div className="field col-12 md:col-4 pt-3 Adthoc-input">
                                                                <div className='flex justify-content-between'>
                                                                    <div className="label-form w-5" style={{ fontWeight: '650', fontSize: '14px', color: '#837d7d' }}>TO</div>
                                                                    <div className='w-1'>:</div>
                                                                    <div className='w-5' style={{ color: '#7f7878' }}>{role.to}</div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        <Divider align="left" className='ml-2'>
                                            <div className="inline-flex align-items-center">
                                                <i className="pi pi-at mr-2 font-bold"></i>
                                                <b className="text-sm">ALIAS DETIALS</b>
                                            </div>
                                        </Divider>

                                        <Divider align="left" className='ml-2'>
                                            <div className="inline-flex align-items-center">
                                                <i className="pi pi-map-marker mr-2 font-bold"></i>
                                                <b className="text-sm">COUNTRIES ASSOCIATED WITH</b>
                                            </div>
                                        </Divider>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div >
        </>
    )
}

export default AdhocSearchMoreinfo
