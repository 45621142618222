import React, { useState, useRef, useEffect } from "react";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
// import { Divider } from 'primereact/divider';
import { Calendar } from 'primereact/calendar';
import { Dialog } from "primereact/dialog";
import { InputTextarea } from 'primereact/inputtextarea';
import axios from "axios";
import { Toast } from "primereact/toast";
// import moment from "moment";
import { Card } from 'primereact/card';
import { Triangle } from 'react-loader-spinner';
import { useLocation } from 'react-router-dom';
import '../IndividualRegisteration/IndividualRegisteration.css';
import PhoneInput from 'react-phone-input-2';

function CorporateRegisteration(props) {

    const [date, setDate] = useState(null);
    const [corporateName, setCorporateName] = useState('');
    let cnameRef = useRef('');
    const [arabicName, setArabicName] = useState('');
    let anameRef = useRef('');
    const [clientId, setclientId] = useState('');
    let clientIdRef = useRef('');
    const [mobile, setmobile] = useState('');
    const [email, setemail] = useState('');
    let emailRef = useRef('');
    const [idcardtype, setIdCardType] = useState('');
    const [idNumber, setidNumber] = useState('');
    let idNumberRef = useRef('');
    const [address, setAddress] = useState('');
    let addressRef = useRef('');
    const [city, setCity] = useState('');
    let cityRef = useRef('');
    const location = useLocation();
    const [countrydata, setCountrydata] = useState([]);
    const [country, setCountry] = useState('');
    const [postalCode, setPostalCode] = useState('');
    let postalCodeRef = useRef('');
    const [emailId, setemailId] = useState('');
    let emailIdRef = useRef('');
    const [phone, setphone] = useState('');
    const [avgAnnualIncome, setavgAnnualIncome] = useState('');
    let avgAnnualIncomeRef = useRef('');
    const toast = useRef(null);
    const [response, setResponse] = useState('');
    const [dialogVisible, setDialogVisible] = useState(false);
    const [notes, setnotes] = useState('');
    let notesRef = useRef('');
    const [dateofcop, setDateofcop] = useState(null);
    const [branch, setBranchname] = useState('');
    const [branchdata, setBranchdata] = useState([]);
    const [ownershipFields, setOwnershipFields] = useState([{ clientId: '', name: '', idCardType: '', idNumber: '', mobile: '', email: '' }]);
    const [managementFields, setManagementFields] = useState([{ clientId: '', name: '', idCardType: '', idNumber: '', mobile: '', email: '' }]);
    const [Authorization, setAuthorization] = useState([{ clientId: '', name: '', idCardType: '', idNumber: '', mobile: '', email: '' }]);
    const [loading, setLoading] = useState(false);

    const [corporateNameError, setCorporateNameError] = useState('');
    // const [cmpyarabicNameError, setCmpyArabicNameError] = useState('');
    const [, setMobileNoError] = useState('');
    const [, setemailError] = useState('');
    // const [emailIdError, setemailIdError] = useState('');
    const [idTypeError, setidTypeError] = useState('');
    const [idNoError, setidNoError] = useState('');
    const [addressError, setAddressError] = useState('');
    const [countryError, setcountryError] = useState('');
    const [, setphNoError] = useState('');
    // const [postError, setpostError] = useState('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [mailEr, setMailEr] = useState(false);
    const [mailEr_T, setMailEr_T] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const formatDate = (date) => {
        return date?.toLocaleDateString('en-US');
    };

    const handleIdcardtype = (event) => {
        setIdCardType(event.value);
    }

    const displayErrorToast = (message) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
    };
    const today1 = new Date();
    const minDate1 = new Date(today1.getFullYear(), today1.getMonth(), today1.getDate() + 1);

    const dialogStyle = {
        width: '30rem',
        overflow: 'hidden',
        textalign: 'center'
    };

    const showDialog = () => {
        setDialogVisible(true);
    };

    const hideDialog = () => {
        setDialogVisible(false);
        setDate(new Date());
        setCorporateName('');
        setArabicName('');
        setclientId('');
        setmobile('');
        setemail('');
        setIdCardType('');
        setidNumber('');
        setAddress('');
        setCity('');
        setOwnershipFields([{ clientId: '', name: '', idCardType: '', idNumber: '', mobile: '', email: '' }]);
        setManagementFields([{ clientId: '', name: '', idCardType: '', idNumber: '', mobile: '', email: '' }]);
        setAuthorization([{ clientId: '', name: '', idCardType: '', idNumber: '', mobile: '', email: '' }]);
        setCountry('');
        setPostalCode('');
        setemailId('');
        setphone('');
        setavgAnnualIncome('');
        setnotes('');
        setDateofcop(new Date());
        setBranchname('');
        refreshHandler();
    };

    useEffect(() => {
        getAllbranches();
        getAllcountry();
    }, []);

    const getAllcountry = () => {

        axios.get(process.env.REACT_APP_BACKEND_HOST + '/country', {
            headers: {
                'Authorization': 'Bearer ' + location?.state?.accessToken
            }
        })
            .then(response => {
                setCountrydata(response.data);
            })
            .catch(error => {
                // console.log(error);
            });
    }

    const getAllbranches = () => {

        axios.get(process.env.REACT_APP_BACKEND_HOST + '/branches', {
            headers: {
                'Authorization': 'Bearer ' + location?.state?.accessToken
            }
        })
            .then(response => {
                const branches = response.data;
                setBranchdata(branches);
            })
            .catch(error => {
                // console.log(error);
            });

    }

    const handleCorporateSubmit = event => {
        setLoading(true);
        event.preventDefault();
        let isValid = true;
        if (!corporateName) {
            setCorporateNameError('Corporate Name is required.');
            isValid = false;
        } else {
            setCorporateNameError('');
        }
        if (!mobile) {
            setMobileNoError('Mobile No is required.');
            isValid = false;
        } else {
            setMobileNoError('');

        } if (!email) {
            setemailError('Email is required.');
            isValid = false;
        } else {
            setemailError('');
        }
        if (!idcardtype) {
            setidTypeError('ID Type is required.');
            isValid = false;
        } else {
            setidTypeError('');
        } if (!idNumber) {
            setidNoError('ID Number is required.');
            isValid = false;
        } else {
            setidNoError('');
        }
        if (!address) {
            setAddressError('Address is required.');
            isValid = false;
        } else {
            setAddressError('');
        }
        if (!phone) {
            setphNoError('Phone Number is required.');
            isValid = false;
        } else {
            setphNoError('');
        }
        if (!country) {
            setcountryError('Country is required.');
            isValid = false;
        } else {
            setcountryError('');
        }

        if (isValid) {
            const data = {
                corporateName: corporateName,
                arabicName: arabicName,
                clientId: clientId,
                mobile: mobile,
                email: email,
                idType: idcardtype,
                idNumber: idNumber,
                address: address,
                city: city,
                country: {
                    id: country.id,
                    country: {
                        laCountryName: country.country.laCountryName,
                    }
                },
                postalCode: postalCode,
                emailId: emailId,
                phone: phone,
                avgAnnualIncome: avgAnnualIncome,
                dateOfIncorporation: formatDate(dateofcop || null),
                idExpDate: formatDate(date || null),
                partner: ownershipFields,
                management: managementFields,
                authorized: Authorization,
                branch: {
                    id: branch.id,
                    name: branch.name,
                },
                notes: notes,

            };
            axios.post(process.env.REACT_APP_BACKEND_HOST + '/create', data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + location?.state?.accessToken
                }
            })
                .then(postdata => {
                    event.preventDefault();
                    setResponse(postdata.data)
                    setLoading(false);
                    setmobile('');
                    setPostalCode('');
                    if (postdata.status === 200) {
                        setLoading(false);
                        toast.current.show({ severity: 'success', summary: 'Registeration Successfully' });
                        setTimeout(() => {
                            showDialog();
                        }, 800);
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Enter the valid detials' });
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    refreshHandler();
                    if (error?.response && error?.response?.status === 403) {
                        (window?.location?.assign(process.env.REACT_APP_AUTH_URL));
                        sessionStorage.clear();
                        setLoading(false);
                    }
                    else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                        const errorMessage = error?.response?.data?.message;
                        displayErrorToast(errorMessage);
                        setLoading(false);
                    }
                    else {
                        displayErrorToast('An error occurred. Please try again later.');
                        setLoading(false);
                    }
                })
        } else {
            refreshHandler();
            setLoading(false);
        }
    }

    const idcardtypes = [
        { name: "Pan Card", code: "Pan Card" },
        { name: "Registration Certificate", code: "Registration Certificate" },
        { name: "Certificate Of Incorporation", code: "Certificate Of Incorporation" }
    ];

    const clientIdstatus = response.clientId;
    const fullNamestatus = response.corporateName;
    // const id = response.id;
    const status = response.status;
    const profileType = response.profileType;

    const refreshHandler = () => {
        setDate(null);
        setCorporateName('');
        setArabicName('');
        setclientId('');
        setmobile('');
        setemail('');
        setIdCardType('');
        setidNumber('');
        setOwnershipFields([{ clientId: '', name: '', idCardType: '', idNumber: '', mobile: '', email: '' }]);
        setManagementFields([{ clientId: '', name: '', idCardType: '', idNumber: '', mobile: '', email: '' }]);
        setAuthorization([{ clientId: '', name: '', idCardType: '', idNumber: '', mobile: '', email: '' }]);
        setAddress('');
        setCity('');
        setCountry('');
        setPostalCode('');
        setemailId('');
        setphone('');
        setavgAnnualIncome('');
        setnotes('');
        setDateofcop(null);
        setBranchname('');
    };


    const addFieldSet = (fieldSetType, event) => {
        event.preventDefault();
        if (fieldSetType === 'ownership') {
            setOwnershipFields([...ownershipFields, { clientId: '', name: '', idCardType: '', idNumber: '', mobile: '', email: '' }]);
        } else if (fieldSetType === 'management') {
            setManagementFields([...managementFields, { clientId: '', name: '', idCardType: '', idNumber: '', mobile: '', email: '' }]);
        } else if (fieldSetType === 'authorization') {
            setAuthorization([...Authorization, { clientId: '', name: '', idCardType: '', idNumber: '', mobile: '', email: '' }]);
        }
    };

    // const removeFieldSet = (fieldSetType, index, event) => {
    //     event.preventDefault();
    //     if (fieldSetType === 'ownership') {
    //         const updatedFields = [...ownershipFields];
    //         updatedFields.splice(index, 1);
    //         setOwnershipFields(updatedFields);
    //     } else if (fieldSetType === 'management') {
    //         const updatedFields = [...managementFields];
    //         updatedFields.splice(index, 1);
    //         setManagementFields(updatedFields);
    //     } else if (fieldSetType === 'authorization') {
    //         const updatedFields = [...Authorization];
    //         updatedFields.splice(index, 1);
    //         setAuthorization(updatedFields);
    //     }
    // };

    const handleFieldChange = (fieldSetType, index, field, value) => {
        if (fieldSetType === 'ownership') {
            const updatedFields = [...ownershipFields];
            updatedFields[index][field] = value;
            setOwnershipFields(updatedFields);

        } else if (fieldSetType === 'management') {
            const updatedFields = [...managementFields];
            updatedFields[index][field] = value;
            setManagementFields(updatedFields);
        } else if (fieldSetType === 'authorization') {
            const updatedFields = [...Authorization];
            updatedFields[index][field] = value;
            setAuthorization(updatedFields);
        }
    };

    const today = new Date();
    const minDate = new Date(today);
    minDate.setFullYear(today.getFullYear() - 90);

    const handleSearch = async (fieldSetType, setIndex, event) => {
        event.preventDefault();
        try {
            const ownershipId = ownershipFields[setIndex].clientId;
            const baseUrl = process.env.REACT_APP_BACKEND_HOST;
            const url = `${baseUrl}/accounts?clientId=${ownershipId}`;

            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + location?.state?.accessToken
                }
            });
            const updatedFields = [...ownershipFields];
            updatedFields[setIndex] = {
                ...updatedFields[setIndex],
                name: response.data.fullName,
                idCardType: response.data.idType,
                idNumber: response.data.idNumber,
                mobile: response.data.phoneNumber,
                email: response.data.emailId,
            };
            setOwnershipFields(updatedFields);

        } catch (error) {
            if (error?.response?.status === 401) {
                toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
            } else if (error?.response && error?.response?.status === 403) {
                window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                sessionStorage.clear();
            }
        }
    };

    const manageSearch = async (setIndex, event) => {
        event.preventDefault();
        try {
            const managementId = managementFields[setIndex].clientId;
            const baseUrl = process.env.REACT_APP_BACKEND_HOST;
            const url = `${baseUrl}/accounts?clientId=${managementId}`;

            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + location?.state?.accessToken
                }
            });

            const updatedFields = [...managementFields]; // Create a copy of ownershipFields
            updatedFields[setIndex] = {
                ...updatedFields[setIndex],
                name: response.data.fullName,
                idCardType: response.data.idType,
                idNumber: response.data.idNumber,
                mobile: response.data.phoneNumber,
                email: response.data.emailId,
            };

            setManagementFields(updatedFields);
        } catch (error) {
            if (error?.response?.status === 401) {
                toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
            } else if (error?.response && error?.response?.status === 403) {
                window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                sessionStorage.clear();
            }
        }
    };





    const authorization = async (setIndex, event) => {
        event.preventDefault();
        try {
            const managementId = Authorization[setIndex].clientId;
            const baseUrl = process.env.REACT_APP_BACKEND_HOST;
            const url = `${baseUrl}/accounts?clientId=${managementId}`;

            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + location?.state?.accessToken
                }
            });

            const updatedFields = [...Authorization]; // Create a copy of ownershipFields
            updatedFields[setIndex] = {
                ...updatedFields[setIndex],
                name: response.data.fullName,
                idCardType: response.data.idType,
                idNumber: response.data.idNumber,
                mobile: response.data.phoneNumber,
                email: response.data.emailId,
            };

            setAuthorization(updatedFields);
        } catch (error) {
            if (error?.response?.status === 401) {
                toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
            } else if (error?.response && error?.response?.status === 403) {
                window?.location.assign(process.env.REACT_APP_AUTH_URL);
                sessionStorage.clear();
            }
        }
    };


    const branchchange = (event) => {
        const selectedBranch = branchdata?.find((item) =>
            item?.name === event?.target?.value
        );
        setBranchname(selectedBranch);
    };

    const countrychange = (event) => {
        const selectedCountry = countrydata?.find((item) => item.country.laCountryName === event.target.value);
        setCountry(selectedCountry);
    };

    const handleEmailChange = (event, i) => {
        const emailValue = event.target.value;
        setemail(emailValue);
        if (!emailValue.trim()) {
            setMailEr('Email is required.');
        } else if (!isValidEmail(emailValue)) {
            setMailEr('Invalid email format or domain, length.');
        } else {
            setMailEr('');
        }
    };

    const handleEmailChangeAdd = (event, i) => {
        const emailValue = event.target.value;
        setemailId(emailValue);
        if (!emailValue.trim()) {
            setMailEr_T('Email is required.');
        } else if (!isValidEmail(emailValue)) {
            setMailEr_T('Invalid email format or domain, length.');
        } else {
            setMailEr_T('');
        }
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // const emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?!gmail\.com)(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
        // const emailFormatRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const words = email.endsWith('gmail.com');
        if (!words) {
            if (!emailRegex.test(email)) {
                return false;
            }
        }
        const domain = email.split('@')[1].toLowerCase();
        const hasDotCom = domain.endsWith('.com' || '@gmail.com');
        const isDomainLengthValid = domain.length >= 8 && domain.length <= 20;
        return hasDotCom && isDomainLengthValid && domain;
    };


    const handlePhoneNumberChange = (value) => {
        const rawInput = value;
        setmobile(rawInput);
    };

    const handlePhoneNumberChangeT = (value) => {
        const rawInput = value;
        setphone(rawInput);
    };


    // const formatPhoneNumber = (phonenumber) => {
    //     const matches = phonenumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    //     if (matches) {
    //         return `${matches[1]}-${matches[2]}-${matches[3]}`;
    //     }
    //     return phonenumber;
    // };

    return (
        <>
            <div className="mb-4">
                <Toast ref={toast} position="top-right" style={{ top: '10%' }} />
                {loading ? (
                    <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                        <Triangle
                            height="80"
                            width="80"
                            color="#124A99"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <>
                        <div className="feild c">

                            <div className="heading">Personal Information</div>

                            <div className="flex">
                                <div className="input">
                                    <div className="label">Corporate Name<span>*</span></div>
                                    <InputText type="text" defaultValue={cnameRef.current} onChange={(e) => setCorporateName(e.target.value)} />
                                    {corporateNameError && <div className="error-message">{corporateNameError}</div>}
                                    {corporateName && corporateName.length < 3 && <div className="error-message">minimum 3 characters</div>}
                                </div>
                                <div className="input">
                                    <div className="label">Company Name In Arabic</div>
                                    <InputText dir="rtl" defaultValue={anameRef.current} onChange={(e) => setArabicName(e.target.value)} />
                                </div>
                            </div>

                            <div className="flex">
                                <div className="input">
                                    <div className="label">Company Id(5-digits)</div>
                                    <InputText keyfilter="int" defaultValue={clientIdRef.current} onChange={(e) => setclientId(e.target.value)} />
                                </div>
                                <div className="input">
                                    <div className="label">Phone Number<span>*</span></div>
                                    <PhoneInput
                                        country={'us'}
                                        value={mobile}
                                        onChange={handlePhoneNumberChange}
                                        countryCodeEditable={false}
                                        className='w-full unique-phone'
                                    />
                                </div>
                                <div className="input">
                                    <div className="label">Email Id<span>*</span></div>
                                    <InputText defaultValue={emailRef.current} onChange={handleEmailChangeAdd} />
                                    {mailEr_T && <div className="error-message">{mailEr_T}</div>}
                                </div>
                            </div>

                            <div className="heading">ID Information</div>

                            <div className="flex">
                                <div className="input">
                                    <div className="label">Id card type<span>*</span></div>
                                    <Dropdown optionLabel="name" optionValue="code" options={idcardtypes} value={idcardtype} onChange={handleIdcardtype} required className="w-full p-0 unique-drops dropdown" />
                                    {idTypeError && <div className="error-message">{idTypeError}</div>}
                                </div>
                                <div className="input">
                                    <div className="label">Id Number<span>*</span></div>
                                    <InputText defaultValue={idNumberRef.current} onChange={(e) => setidNumber(e.target.value)} />
                                    {idNoError && <div className="error-message">{idNoError}</div>}
                                </div>
                            </div>

                            <div className="flex">
                                <div className="input">
                                    <div className="label">Id Expiration Date</div>
                                    <Calendar style={{ padding: 'unset' }} showIcon value={date} onChange={(e) => setDate(e.value)} required minDate={minDate1} id="p-calender" className="w-full calander" />
                                </div>
                                <div className="input">
                                    <div className="label">Date Of Incorporation</div>
                                    <Calendar style={{ padding: 'unset' }} onChange={(e) => setDateofcop(e.value)} value={dateofcop} showIcon minDate={minDate} maxDate={today} id="p-calender" className="w-full calander" required />
                                </div>
                            </div>

                            <div className="heading">Address Information</div>

                            <div className="flex">
                                <div className="input">
                                    <div className="label">Address<span>*</span></div>
                                    <InputText defaultValue={addressRef.current} onChange={(e) => setAddress(e.target.value)} />
                                    {addressError && <div className="error-message">{addressError}</div>}
                                </div>
                                <div className="input">
                                    <div className="label">City<span>*</span></div>
                                    <InputText defaultValue={cityRef.current} onChange={(e) => setCity(e.target.value)} />
                                </div>
                                <div className="input">
                                    <div className="label">Country<span>*</span></div>
                                    <Dropdown value={country?.country?.laCountryName || null} options={countrydata?.map((item) => ({ label: item?.country?.laCountryName, value: item?.country?.laCountryName }))} optionLabel="label" required onChange={countrychange} filter className="w-full p-0 unique-drops dropdown" />
                                    {countryError && <div className="error-message">{countryError}</div>}
                                </div>
                            </div>

                            <div className="flex">
                                <div className="input">
                                    <div className="label">Postal Code<span>*</span></div>
                                    <InputText maxlength={6} defaultValue={postalCodeRef.current} keyfilter="int" onChange={(e) => setPostalCode(e.target.value)} />
                                </div>
                                <div className="input">
                                    <div className="label">Email Id<span>*</span></div>
                                    <InputText defaultValue={emailIdRef.current} onChange={handleEmailChange} />
                                    {mailEr && <div className="error-message">{mailEr}</div>}
                                </div>
                                <div className="input">
                                    <div className="label">Phone Number<span>*</span></div>
                                    <PhoneInput
                                        country={'us'}
                                        value={phone}
                                        onChange={handlePhoneNumberChangeT}
                                        countryCodeEditable={false}
                                        className='w-full unique-phone'
                                    />
                                </div>
                            </div>

                            <div className="flex">
                                <div className="input">
                                    <div className="label">Annual Income<span>*</span></div>
                                    <InputText defaultValue={avgAnnualIncomeRef.current} keyfilter="int" onChange={(e) => setavgAnnualIncome(e.target.value)} />
                                </div>
                            </div>

                            <div className="heading">Ownership Information</div>

                            <div className="gap">

                                <div className="card_Sec" style={{ padding: 'unset' }}>
                                    <div className="sub_heading">Partner Details</div>
                                    <Card className="card_Sec2 full_View" style={{ boxShadow: 'unset' }}>
                                        {ownershipFields.map((fieldSet, setIndex) => (
                                            <div key={setIndex} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                <div className="flex" style={{ alignItems: 'end', flexWrap: 'wasr' }}>
                                                    <div className="input padding full_View">
                                                        <div className="label">Client Id</div>
                                                        <div style={{ display: 'flex' }} >
                                                            <InputText name={`id${setIndex}`} value={ownershipFields[setIndex].clientId} onChange={(e) => handleFieldChange('ownership', setIndex, 'clientId', e.target.value)} className="custom-input w-full mr-1" />
                                                            <div style={{ display: 'flex', gap: '10px' }}>
                                                                <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }} icon="pi pi-search" onClick={(event) => handleSearch('ownership', setIndex, event)} />
                                                                <Button className="" disabled={!ownershipFields[setIndex].clientId} icon="pi pi-plus" style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }} onClick={(event) => addFieldSet('ownership', event)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {ownershipFields[setIndex].clientId ? <>
                                                    <div className="input padding">
                                                        <div className="label">Name</div>
                                                        <InputText readOnly id={`name${setIndex}`} name={`name${setIndex}`} value={fieldSet.name} onChange={(e) => handleFieldChange('ownership', setIndex, 'name', e.target.value)} disabled={(!ownershipFields[setIndex].clientId)} />
                                                    </div>
                                                    <div className="input padding">
                                                        <div className="label">Id Card Type</div>
                                                        <InputText readOnly id={`idCardType${setIndex}`} name={`idCardType${setIndex}`} value={fieldSet.idCardType} onChange={(e) => handleFieldChange('ownership', setIndex, 'idCardType', e.target.value)} disabled={(!ownershipFields[setIndex].clientId)} />
                                                    </div>
                                                    <div className="input padding">
                                                        <div className="label">Id Number</div>
                                                        <InputText readOnly id={`idNumber${setIndex}`} name={`idNumber${setIndex}`} value={fieldSet.idNumber} onChange={(e) => handleFieldChange('ownership', setIndex, 'idNumber', e.target.value)} disabled={(!ownershipFields[setIndex].clientId)} />
                                                    </div>
                                                    <div className="input padding">
                                                        <div className="label">Mobile</div>
                                                        <InputText readOnly id={`mobile${setIndex}`} name={`mobile${setIndex}`} value={fieldSet.mobile} onChange={(e) => handleFieldChange('ownership', setIndex, 'mobile', e.target.value)} disabled={(!ownershipFields[setIndex].clientId)} />
                                                    </div>
                                                    <div className="input padding">
                                                        <div className="label">Email Id</div>
                                                        <InputText readOnly id={`email${setIndex}`} name={`email${setIndex}`} value={fieldSet.email} onChange={(e) => handleFieldChange('ownership', setIndex, 'email', e.target.value)} disabled={(!ownershipFields[setIndex].clientId)} />
                                                    </div>
                                                </> : null}
                                            </div>
                                        ))}
                                    </Card>
                                </div>

                                <div className="card_Sec" style={{ padding: 'unset' }}>
                                    <div className="sub_heading">Management Details</div>
                                    <Card className="card_Sec2 full_View" style={{ boxShadow: 'unset' }}>
                                        {managementFields.map((fieldSet, setIndex) => (
                                            <div key={setIndex} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                <div className="flex" style={{ alignItems: 'end', flexWrap: 'wasr' }}>
                                                    <div className="input padding full_View">
                                                        <div className="label">Client Id</div>
                                                        <div style={{ display: 'flex' }} >
                                                            <InputText name={`idM${setIndex}`} value={fieldSet.clientId} onChange={(e) => handleFieldChange('management', setIndex, 'clientId', e.target.value)} />
                                                            <div style={{ display: 'flex', gap: '10px' }}>
                                                                <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }} icon="pi pi-search" id="search-btn" onClick={(event) => manageSearch('management', setIndex, event)} />
                                                                <Button disabled={!fieldSet.clientId} icon="pi pi-plus" style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }} onClick={(event) => addFieldSet('management', event)}></Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {fieldSet.clientId ? <>
                                                    <div className="input padding">
                                                        <div className="label">Name</div>
                                                        <InputText readOnly id={`nameM${setIndex}`} name={`nameM${setIndex}`} value={fieldSet.name} onChange={(e) => handleFieldChange('management', setIndex, 'name', e.target.value)} disabled={(!fieldSet.clientId)} />
                                                    </div>
                                                    <div className="input padding">
                                                        <div className="label">Id Card Type</div>
                                                        <InputText readOnly id={`idCardTypeM${setIndex}`} name={`idCardTypeM${setIndex}`} value={fieldSet.idCardType} onChange={(e) => handleFieldChange('management', setIndex, 'idCardType', e.target.value)} disabled={(!fieldSet.clientId)} />
                                                    </div>
                                                    <div className="input padding">
                                                        <div className="label">Id Number</div>
                                                        <InputText readOnly id={`idNumberM${setIndex}`} name={`idNumberM${setIndex}`} value={fieldSet.idNumber} onChange={(e) => handleFieldChange('management', setIndex, 'idNumber', e.target.value)} disabled={(!fieldSet.clientId)} />
                                                    </div>
                                                    <div className="input padding">
                                                        <div className="label">Mobile</div>
                                                        <InputText readOnly id={`mobileM${setIndex}`} name={`mobileM${setIndex}`} value={fieldSet.mobile} onChange={(e) => handleFieldChange('management', setIndex, 'mobile', e.target.value)} disabled={(!fieldSet.clientId)} />
                                                    </div>
                                                    <div className="input padding">
                                                        <div className="label">Email Id</div>
                                                        <InputText readOnly id={`emailM${setIndex}`} name={`emailM${setIndex}`} value={fieldSet.email} onChange={(e) => handleFieldChange('management', setIndex, 'email', e.target.value)} disabled={(!fieldSet.clientId)} />
                                                    </div>
                                                </> : null}
                                            </div>
                                        ))}
                                    </Card>
                                </div>

                                <div className="card_Sec" style={{ padding: 'unset' }}>
                                    <div className="sub_heading">Authorized Signatories Information</div>
                                    <Card className="card_Sec2 full_View" style={{ boxShadow: 'unset' }}>
                                        {Authorization.map((fieldSet, setIndex) => (
                                            <div key={setIndex} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                <div className="flex" style={{ alignItems: 'end', flexWrap: 'wasr' }}>
                                                    <div className="input padding full_View">
                                                        <div className="label">Client Id</div>
                                                        <div style={{ display: 'flex' }} >
                                                            <InputText name={`idM${setIndex}`} value={fieldSet.clientId} onChange={(e) => handleFieldChange('authorization', setIndex, 'clientId', e.target.value)} />
                                                            <div style={{ display: 'flex', gap: '10px' }}>
                                                                <Button style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }} icon="pi pi-search" id="search-btn" onClick={(event) => authorization('authorization', setIndex, event)} />
                                                                <Button disabled={!fieldSet.clientId} icon="pi pi-plus" style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }} onClick={(event) => addFieldSet('authorization', event)}></Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {fieldSet.clientId ? <>
                                                    <div className="input padding">
                                                        <div className="label">Name</div>
                                                        <InputText readOnly id={`nameM${setIndex}`} name={`nameM${setIndex}`} value={fieldSet.name} onChange={(e) => handleFieldChange('management', setIndex, 'name', e.target.value)} disabled={(!fieldSet.clientId)} />
                                                    </div>
                                                    <div className="input padding">
                                                        <div className="label">Id Card Type</div>
                                                        <InputText readOnly id={`idCardTypeM${setIndex}`} name={`idCardTypeM${setIndex}`} value={fieldSet.idCardType} onChange={(e) => handleFieldChange('management', setIndex, 'idCardType', e.target.value)} disabled={(!fieldSet.clientId)} />
                                                    </div>
                                                    <div className="input padding">
                                                        <div className="label">Id Number</div>
                                                        <InputText readOnly id={`idNumberM${setIndex}`} name={`idNumberM${setIndex}`} value={fieldSet.idNumber} onChange={(e) => handleFieldChange('management', setIndex, 'idNumber', e.target.value)} disabled={(!fieldSet.clientId)} />
                                                    </div>
                                                    <div className="input padding">
                                                        <div className="label">Mobile</div>
                                                        <InputText readOnly id={`mobileM${setIndex}`} name={`mobileM${setIndex}`} value={fieldSet.mobile} onChange={(e) => handleFieldChange('management', setIndex, 'mobile', e.target.value)} disabled={(!fieldSet.clientId)} />
                                                    </div>
                                                    <div className="input padding">
                                                        <div className="label">Email Id</div>
                                                        <InputText readOnly id={`emailM${setIndex}`} name={`emailM${setIndex}`} value={fieldSet.email} onChange={(e) => handleFieldChange('management', setIndex, 'email', e.target.value)} disabled={(!fieldSet.clientId)} />
                                                    </div>
                                                </> : null}
                                            </div>
                                        ))}
                                    </Card>
                                </div>
                            </div>

                            <div className="heading">Additional Information</div>

                            <div className="flex">
                                <div className="input">
                                    <div className="label">Branches<span>*</span></div>
                                    <Dropdown value={branch ? branch.name : null}
                                        options={branchdata?.map((item) =>
                                            ({ label: item?.name, value: item?.name }))
                                        } onChange={branchchange} required className="w-full unique-drops dropdown" optionLabel="label" />
                                </div>
                            </div>

                            <div className="flex">
                                <div className="input">
                                    <div className="label">Additional Notes</div>
                                    <InputTextarea rows={1} cols={30} defaultValue={notesRef.current} style={{ width: '100%' }} onChange={(event) => setnotes(event.target.value)} />
                                </div>
                            </div>

                            <div className="footer_sec_btn input" style={{ padding: 'unset' }}>
                                <Button style={{ background: '#124A99' }} onClick={refreshHandler} className="resetBtn" icon="pi pi-refresh" />
                                <Button style={{ background: '#124A99' }} onClick={handleCorporateSubmit}
                                    disabled={mailEr || !email || mobile.length < 10 || !branch.name || !corporateName || corporateName.length < 3 || !idcardtype || !idNumber || !address || !city || !country || !postalCode || !phone || !avgAnnualIncome}
                                >Submit</Button>
                            </div>
                        </div>

                        <Dialog header="Registration Status" visible={dialogVisible} onHide={hideDialog} className="sing_up_main_Dialog" position="top" style={dialogStyle}>
                            <div className='risk-clfn-dialog '>
                                <div className="w-full px-4 py-3">
                                    <p className="flex align-items-center justify-content-center"><b className="w-6">Client Id</b>     <span className="w-2">:</span><span className="w-6">{clientIdstatus}</span></p>
                                    <p className="flex align-items-center justify-content-center"><b className="w-6">Corporate Name</b><span className="w-2">:</span><span className="w-6">{fullNamestatus}</span></p>
                                    <p className="flex align-items-center justify-content-center"><b className="w-6" >Profile Type </b><span className="w-2">:</span><span className="w-6" style={{ fontWeight: 'bold', color: profileType === 'High Risk' ? '#f42616' : profileType === 'Medium Risk' ? '#f7af00' : profileType === 'Low Risk' ? 'rgb(69 160 75' : '' }}>{profileType}</span></p>
                                    <p className="flex align-items-center justify-content-center"><b className="w-6">Status</b>        <span className="w-2">:</span><span className="w-6" style={{ fontWeight: 'bold', color: status === 'Blocked' ? '#f42616' : status === 'Pending' ? '#f7af00' : status === 'Cleared' ? 'rgb(69 160 75)' : '' }}>{status}</span></p>
                                </div>
                            </div>
                        </Dialog>
                    </>
                )}
            </div >

        </>
    )
}
export default CorporateRegisteration;








