import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dialog } from "primereact/dialog";
import { MultiSelect } from 'primereact/multiselect';
import classNames from 'classnames';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { Dropdown } from 'primereact/dropdown';
import './Users.css';
import * as XLSX from 'xlsx';
import { FaFilePdf, FaFileCsv, FaFileExcel } from 'react-icons/fa';
import { Menu } from 'primereact/menu';
import UserRegisteration from './UserRegisteration';
import "../../../views/Loading.css";
import { BreadCrumb } from 'primereact/breadcrumb';
import { Triangle } from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { useLocation } from 'react-router-dom';

export default function Users() {

    let emptyProduct = {
        id: '',
        username: "",
        firstName: "",
        middleName: "",
        lastName: "",
        emailId: "",
        phoneNumber: "",
        password: "",
        role: '',
        status: '',
    };

    const [data, setData] = useState([]);
    const [products, setProducts] = useState(null);
    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState(null);
    const [emails, setemail] = useState(null);
    const [selectedRoleObj, setSelectedRoleObj] = useState([]);
    const [userDialog, setUserDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const [activeComponent, setActiveComponent] = useState(null);
    const history = useHistory();
    const [, setWindowWidth] = useState(window.innerWidth);
    const location = useLocation();
    const accessToken = location?.state?.accessToken;
    const plan = location?.state?.getUserData?.premiumPlan;
    // const email = location?.state?.emailId;
    let inputRef = useRef('');

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleAxiosError = (error) => {
        if (error?.response) {
            if (error?.response?.status === 403) {
                window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                sessionStorage.clear();
            } else if (error?.response?.status === 401) {
                toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
            } else if (error?.response?.data && error?.response?.data?.message) {
                const errorMessage = error?.response?.data?.message;
                displayErrorToast(errorMessage);
            }
        } else {
            displayErrorToast('An unexpected error occurred.');
        }
    };

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                setLoading(true);
                axios.get(`${process.env.REACT_APP_BACKEND_HOST}/get_all/Repurchase`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + accessToken
                    }
                })
                    .then(response => {
                        setProducts(response.data);
                        setData(response.data);
                        if (response && response.status === 403) {
                            window.location.assign(process.env.REACT_APP_AUTH_URL);
                            sessionStorage.clear();
                        }
                        setLoading(false);
                    })
                    .catch(error => {
                        if (error?.response && error?.response?.data && error?.response?.data?.message) {
                            const errorMessage = error?.response?.data?.message;
                            displayErrorToast(errorMessage);
                        } else if (error?.response && error?.response?.status === 403) {
                            window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                            sessionStorage.clear();
                        } else if (error?.response?.status === 401) {
                            toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                        }
                    });
            }
            catch (error) {
                handleAxiosError(error);
            } finally {
                setLoading(false);
            }
        };

        const fetchUserId = async () => {
            try {
                setLoading(true);
                axios.get(process.env.REACT_APP_IDM_BACKEND_HOST + '/roles/all', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + accessToken
                    }
                })
                    .then(response => {
                        setRoles(response.data);
                    })
                    .catch(error => {
                        if (error?.response && error?.response?.data && error?.response?.data?.message) {
                            const errorMessage = error?.response?.data?.message;
                            displayErrorToast(errorMessage);
                        } else if (error?.response && error?.response.status === 403) {
                            window.location.assign(process.env.REACT_APP_AUTH_URL);
                            sessionStorage.clear();
                        } else if (error?.response?.status === 401) {
                            toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                        }
                    });
                setLoading(false);
            } catch (error) {
                if (error?.response && error?.response?.data && error?.response?.data.message) {
                    const errorMessage = error?.response?.data.message;
                    displayErrorToast(errorMessage);
                } else if (error?.response && error?.response?.status === 403) {
                    window.location.assign(process.env.REACT_APP_AUTH_URL);
                    sessionStorage.clear();
                } else if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                }
                setLoading(false);
            }
        };
        fetchProfileData();
        fetchUserId();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const fetchProfileData = async () => {
        try {
            setLoading(true);
            axios.get(`${process.env.REACT_APP_BACKEND_HOST}/get_all/Repurchase`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                }
            })
                .then(response => {
                    setProducts(response.data);
                    setData(response.data);
                })
                .catch(error => {
                    if (error?.response && error?.response?.data && error?.response?.data?.message) {
                        const errorMessage = error?.response?.data?.message;
                        displayErrorToast(errorMessage);
                    } else if (error?.response && error?.response?.status === 403) {
                        window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                        sessionStorage.clear();
                    } else if (error?.response?.status === 401) {
                        toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                    }
                });
            setLoading(false);
        }
        catch (error) {
            handleAxiosError(error);
        } finally {
            setLoading(false);
        }
    };

    const openNew = () => {
        setActiveComponent('nextComponent');
        history.push({
            pathname: '/admin/userregistration',
            state: {
                accessToken: location?.state?.accessToken,
                emailId: location?.state?.emailId,
                getUserData: location?.state?.getUserData,
                rolesData: location?.state?.rolesData
            },
        })
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProduct(false);
        setUserDialog(false);
    }

    const update = () => {
        setSubmitted(true);
        setLoading(true)
        if (
            !product.phoneNumber ||
            !product.reason ||
            !selectedRoleObj ||
            !product.status
        ) {
            return;
        }

        const updatedata = {
            phoneNumber: product.phoneNumber,
            reason: product.reason,
            roles: selectedRoleObj,
            status: product.status,
            emailId: emails,
        };
        hideDialog();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        axios
            .put(process.env.REACT_APP_BACKEND_HOST + `/user`, updatedata, {
                headers: headers,
            })
            .then(response => {
                setLoading(false)
                fetchProfileData();
                showSuccessToast(response.data.message);
            })
            .catch(error => {
                setLoading(false)
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data.message;
                    displayErrorToast(errorMessage);
                } else if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    sessionStorage.clear();
                } else if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                }
            });
    };

    const handleStatusChange = (e) => {
        setProduct({ ...product, status: e.value });
    };

    const infoTemplate = (rowData) => {
        const handleEditClick = () => {
            setUserDialog(true);
            // const id = rowData.id;
            // sessionStorage.setItem('userupdateid', id);
            const roless = rowData.role;
            // sessionStorage.setItem('userupdaterole', roless);
            const emails = rowData.emailId;
            setemail(emails);
            // sessionStorage.setItem('emails', emails);
            if (roless) {
                const matchingRoles = roles.filter(role => roless.includes(role.roleName));
                setSelectedRoleObj(matchingRoles);
            }
            setProduct({
                ...product,
                role: rowData.role,
                phoneNumber: rowData.phoneNumber,
                status: rowData.status,
                reason: rowData.reason,
                emailId: emails
            });

        };

        return (
            <React.Fragment>
                <Button className="pi pi-pencil" onClick={handleEditClick} />
            </React.Fragment>
        );
    };

    const productDialogFooter = (
        <React.Fragment>
            <div className="footer_sec_btn">
                <Button onClick={update} style={{ width: '80px' }}>Update</Button>
            </div>
        </React.Fragment>
    );

    const statusOptions = [
        { name: 'Active', value: 'Active' },
        { name: 'Inactive', value: 'Inactive' }
    ];

    const cols = [
        { field: 'id', header: 'User ID' },
        { field: 'firstName', header: 'First Name' },
        { field: 'username', header: 'User Name' },
        { field: 'emailId', header: 'Email' },
        { field: 'phoneNumber', header: 'Phone Number' },
    ];

    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    const handleExportToPDF = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, products);
                doc.save('Users data.pdf');
            });
        });
    };

    const handleExportToCSV = () => {
        const csvContent = convertToCSV(products);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.setAttribute('href', URL.createObjectURL(blob));
        link.setAttribute('download', 'Users data.csv');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const convertToCSV = (data) => {
        const csvRows = [];
        if (Array.isArray(data) && data.length > 0) {
            const headers = Object.keys(data[0]);
            csvRows.push(headers.join(','));

            for (const row of data) {
                const values = Object.values(row).join(',');
                csvRows.push(values);
            }
        }
        return csvRows.join('\n');
    };

    const exportToExcel = () => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(products);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'Users data.xlsx');
    };

    const exportToXLS = () => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(products);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'Users data.xls');
    };

    const [visible, setVisible] = useState(false);
    let menu = null;

    const items = [
        {
            label: 'XLS',
            icon: () => <FaFileExcel />,
            command: () => {
                setVisible(false);
                exportToXLS();
            }
        },
        {
            label: 'XLSX',
            icon: () => <FaFileExcel />,
            command: () => {
                setVisible(false);
                exportToExcel();
            }
        },
        {
            label: 'CSV',
            icon: () => <FaFileCsv />,
            command: () => {
                setVisible(false);
                handleExportToCSV();
            }
        },
        {
            label: 'PDF',
            icon: () => <FaFilePdf />,
            command: () => {
                setVisible(false);
                handleExportToPDF();
            }
        }
    ];


    const itemsFB = [
        {
            label: 'PDF',
            icon: () => <FaFilePdf />,
            command: () => {
                setVisible(false);
                handleExportToPDF();
            }
        }
    ];

    const toggleMenu = (event) => {
        setVisible(!visible);
        menu.toggle(event);
    };

    const handlePrint = () => {
        const printWindow = window.open('', '_blank');
        printWindow.document.write('<html><head><title>User Datas List</title></head><body>');
        printWindow.document.write('<style>table { border-collapse: collapse; width: 100%; } th, td { border: 1px solid black; padding: 8px; text-align: left; }</style>');
        printWindow.document.write('<h1>User Datas List</h1>');

        printWindow.document.write('<table>');
        printWindow.document.write('<thead>');
        printWindow.document.write('<tr>');

        const columns = [
            { field: "id", header: "User ID" },
            { field: "firstName", header: "First Name" },
            { field: "emailId", header: "Email" },
            { field: "phoneNumber", header: "Phone Number" },
            { field: "status", header: "Status" },
        ];

        columns.forEach(col => {
            printWindow.document.write(`<th>${col.header}</th>`);
        });


        printWindow.document.write('</tr>');
        printWindow.document.write('</thead>');
        printWindow.document.write('<tbody>');

        data.forEach(row => {
            printWindow.document.write('<tr>');

            columns.forEach(col => {
                const fieldValue = row[col.field] !== undefined && row[col.field] !== null ? row[col.field] : '-';
                printWindow.document.write(`<td>${fieldValue}</td>`);
            });


            printWindow.document.write('</tr>');
        });

        printWindow.document.write('</tbody></table></body></html>');
        printWindow.document.close();
        printWindow.print();
    };


    const [expandedRows, setExpandedRows] = useState(null);

    const showSuccessToast = (message) => {
        toast.current.show({ severity: 'success', summary: 'Successfully Changed', detail: message, life: 5000 });
    };
    const displayErrorToast = (message) => {
        if (toast.current) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
        }
    };

    const rowExpansionTemplate = (rowData) => {
        return (
            <div className="branch-expand-details" style={{ fontSize: '14px', }}>
                <div className='branch-expand-details-a'>
                    <div className='branch-expand-details-a1'><div className='font-semibold'>User Id</div> <div className='branch-expand-details-a2'>:</div> <div className='branch-expand-details-a3'>{rowData.id ? rowData.id : '-'}</div></div>
                    <div className='branch-expand-details-a1'><div className='font-semibold'>Email Id</div> <div className='branch-expand-details-a2'>:</div> <div className='branch-expand-details-a3'>{rowData.emailId ? rowData.emailId : '-'}</div></div>
                    <div className='branch-expand-details-a1'><div className='font-semibold'>User Name</div> <div className='branch-expand-details-a2'>:</div> <div className='branch-expand-details-a3'>{rowData.username ? rowData.username : '-'}</div></div>
                    <div className='branch-expand-details-a1'><div className='font-semibold'>Phone Number</div> <div className='branch-expand-details-a2'>:</div> <div className='branch-expand-details-a3'>{rowData.phoneNumber ? rowData.phoneNumber : '-'}</div></div>
                    <div className='branch-expand-details-a1'><div className='font-semibold'>Id Card Type</div> <div className='branch-expand-details-a2'>:</div> <div className='branch-expand-details-a3'>{rowData.idCardType ? rowData.idCardType : '-'}</div></div>
                    <div className='branch-expand-details-a1'><div className='font-semibold'>Id Card No</div> <div className='branch-expand-details-a2'>:</div> <div className='branch-expand-details-a3'>{rowData.idCardNo ? rowData.idCardNo : '-'}</div></div>
                    <div className='branch-expand-details-a1'><div className='font-semibold'>DOB</div> <div className='branch-expand-details-a2'>:</div> <div className='branch-expand-details-a3'>{rowData.dob ? rowData.dob : '-'}</div></div>
                    <div className='branch-expand-details-a1'><div className='font-semibold'>Updated On</div> <div className='branch-expand-details-a2'>:</div> <div className='branch-expand-details-a3'>{rowData.updatedOn ? rowData.updatedOn : '-'}</div></div>
                    <div className='branch-expand-details-a1'><div className='font-semibold'>Marital Status</div> <div className='branch-expand-details-a2'>:</div> <div className='branch-expand-details-a3'>{rowData.maritalStatus ? rowData.maritalStatus : '-'}</div></div>
                    <div className='branch-expand-details-a1'><div className='font-semibold'>Address</div> <div className='branch-expand-details-a2'>:</div> <div className='branch-expand-details-a3'>{rowData.address ? rowData.address : '-'}</div></div>
                    <div className='branch-expand-details-a1'><div className='font-semibold'>City</div> <div className='branch-expand-details-a2'>:</div> <div className='branch-expand-details-a3'>{rowData.city ? rowData.city : '-'}</div></div>
                </div>
                <div className='branch-expand-details-b' style={{}}>
                    <div className='branch-expand-details-b1'><div className='font-semibold'>State</div> <div className='branch-expand-details-b2'>:</div> <div className='branch-expand-details-b3'>{rowData.state ? rowData.state : '-'}</div></div>
                    <div className='branch-expand-details-b1'><div className='font-semibold'>Updated By Id</div> <div className='branch-expand-details-b2'>:</div> <div className='branch-expand-details-b3'>{rowData.updatedById ? rowData.updatedById : '-'}</div></div>
                    <div className='branch-expand-details-b1'><div className='font-semibold'>Branch Code</div> <div className='branch-expand-details-b2'>:</div> <div className='branch-expand-details-b3'>{rowData.branchCode ? rowData.branchCode : '-'}</div></div>
                    <div className='branch-expand-details-b1'><div className='font-semibold'>Branch Address</div> <div className='branch-expand-details-b2'>:</div> <div className='branch-expand-details-b3'>{rowData.branchAddress ? rowData.branchAddress : '-'}</div></div>
                    <div className='branch-expand-details-b1'><div className='font-semibold'>Work Type</div> <div className='branch-expand-details-b2'>:</div> <div className='branch-expand-details-b3'>{rowData.workType ? rowData.workType : '-'}</div></div>
                    <div className='branch-expand-details-b1'><div className='font-semibold'>Work Time</div> <div className='branch-expand-details-b2'>:</div> <div className='branch-expand-details-b3'>{rowData.workTime ? rowData.workTime : '-'}</div></div>
                    <div className='branch-expand-details-b1'><div className='font-semibold'>Created By User</div> <div className='branch-expand-details-b2'>:</div> <div className='branch-expand-details-b3'>{rowData.createdByUserName ? rowData.createdByUserName : '-'}</div></div>
                    <div className='branch-expand-details-b1'><div className='font-semibold'>Created On</div> <div className='branch-expand-details-b2'>:</div> <div className='branch-expand-details-b3'>{rowData.createdOn ? rowData.createdOn : '-'}</div></div>
                    <div className='branch-expand-details-b1'><div className='font-semibold'>Status</div> <div className='branch-expand-details-b2'>:</div> <div className='branch-expand-details-b3'>{rowData.status ? rowData.status : '-'}</div></div>
                    <div className='branch-expand-details-b1'><div className='font-semibold'>Reason</div> <div className='branch-expand-details-b2'>:</div> <div className='branch-expand-details-b3'>{rowData.reason ? rowData.reason : '-'}</div></div>
                </div>
            </div>
        );
    };

    const onRowToggle = (event) => {
        setExpandedRows(event.data);
    };

    // const handleNavigate = (url) => {
    //     history.push(url);
    // };

    const item = [
        { label: 'Users' }
    ];
    const home = {
        icon: 'pi pi-home', command: () =>
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                },
            })
    }
    // const home1 = {
    //     icon: 'pi pi-angle-double-left', command: () =>
    //         history.push({
    //             pathname: '/admin/dashboard',
    //             state: {
    //                 accessToken: location?.state?.accessToken,
    //                 emailId: location?.state?.emailId,
    //                 getUserData: location?.state?.getUserData,
    //             },
    //         })
    // }

    const TypeTemplate = (rowData) => {
        const status = rowData.status;

        let color = '';

        if (status) {
            if (status.toLowerCase() === 'inactive') {
                color = '#f7af00';
            } else if (status.toLowerCase() === 'blocked') {
                color = '#f42616';
            } else if (status.toLowerCase() === 'active') {
                color = 'rgb(69 160 75)';
            } else if (status.toLowerCase() === 'disable') {
                color = '#708026';
            } else if (status.toLowerCase() === 'subscriptionexpired') {
                color = '#ff6399';
            }
        }
        return (
            <div
                style={{
                    color: color,
                    fontWeight: '500',
                }}
            >
                {status}
            </div>
        );
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;
        setProduct(_product);
    }
    const handleRoleChange = (e) => {
        setSelectedRoleObj(e.value);
    };

    return (
        <>
            <div className="mainSec" >
                <Toast ref={toast} style={{ marginTop: '150px' }} />
                {activeComponent === 'nextComponent' ? (
                    <UserRegisteration />
                ) : (
                    <div className='container h-full w-full'>
                        <BreadCrumb model={item} home={home} style={{ border: 'none' }} />
                        {loading ? (
                            <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                                <Triangle
                                    height="80"
                                    width="80"
                                    color="#124A99"
                                    ariaLabel="triangle-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            </div>
                        ) : (
                            <>
                                <div className="card-Adhoc w-full bg-white unique-data-response" style={{ marginBottom: '8%', background: '#fff', position: 'relative', border: '1px solid #e8e9eb' }} >
                                    <div className="individual-account-search" style={{ border: 'solid rgba(209, 209, 209, 1) 1px' }}>
                                        <div className="tab-head-btns flex flex-wrap align-items-center justify-content-end" style={{ borderBottom: 'rgba(209, 209, 209, 1) 1px solid' }}>
                                            <div className='country-conatent flex justify-content-end  pt-2 pb-2 px-3 bg-gray-50'>
                                                <div className="tab-head-btn flex justify-content-end flex-wrap w-full" >
                                                    <div className=" ">
                                                        <span className="p-input-icon-left">
                                                            <i className="pi pi-search" />
                                                            <InputText
                                                                type="text"
                                                                placeholder="Search..."
                                                                style={{ border: '2px solid #ccc' }}
                                                                value={globalFilter}
                                                                onChange={(e) => setGlobalFilter(e.target.value)}
                                                                className='py-2 w-full'
                                                            />
                                                        </span>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center" style={{ zIndex: '1' }}>
                                                        <Button className=' mr-2 w-3rem flex align-items-center justify-content-center' id="b-btn" style={{ zIndex: '1', border: 'none', backgroundColor: 'transparent' }} onClick={openNew} tooltip="Create User" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} ><i className='pi pi-plus pl-1'></i></Button>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center">
                                                        <Button className=' mr-2 w-3rem flex align-items-center justify-content-center' id="b-btn" style={{ border: 'none', backgroundColor: 'transparent' }} disabled={data.length === 0} tooltip="Export" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} onClick={toggleMenu} ><i className='pi pi-upload pl-1'></i></Button>
                                                        <Menu
                                                            className='unique-menu-exp'
                                                            model={plan === 'FreeTrial' || plan === 'BasicAdmin' ? itemsFB : items}
                                                            popup
                                                            ref={(el) => (menu = el)}
                                                            onHide={() => setVisible(false)}
                                                            appendTo={document.body}
                                                        />
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center">
                                                        <Button className=' mr-2 w-3rem flex align-items-center justify-content-center' id="b-btn" style={{ zIndex: '1', border: 'none', backgroundColor: 'transparent' }} onClick={handlePrint} disabled={data.length === 0} tooltip="Print" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} ><i className='pi pi-print pl-1'></i></Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='border-btm-datatble1'>
                                            <DataTable
                                                value={data}
                                                id='edit-btn'
                                                paginator rows={8}
                                                rowsPerPageOptions={[8, 16, 24, 40]}
                                                globalFilter={globalFilter}
                                                rowExpansionTemplate={rowExpansionTemplate}
                                                expandedRows={expandedRows}
                                                onRowToggle={onRowToggle}
                                                stripedRows
                                            >
                                                <Column expander style={{ width: '3rem' }} />
                                                <Column field="id" header="User ID" style={{ whiteSpace: 'nowrap' }} sortable></Column>
                                                <Column field="firstName" header="First Name" sortable></Column>
                                                <Column field="emailId" header="Email" sortable></Column>
                                                <Column field="phoneNumber" header="Phone Number" sortable></Column>
                                                <Column field="status" header="Status" body={TypeTemplate} sortable></Column>
                                                <Column header="Edit" body={infoTemplate} ></Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                                <Dialog visible={userDialog} header="Update Details" modal style={{ width: '38vw' }} draggable={false} className="sing_up_main_Dialog" footer={productDialogFooter} onHide={hideDialog}>
                                    <div className=" overflow-hidden" id="advanced-ahoc-rect" style={{ overflow: 'auto' }}>
                                        <div className="feild c pt-3 pb-3">
                                            <div className="flex">
                                                <div className="input">
                                                    <div className="label">Role</div>
                                                    <MultiSelect inputId="multiselect" value={selectedRoleObj} options={roles} onChange={handleRoleChange} optionLabel="roleName" filter maxSelectedLabels={3} className="w-full unique-drops p-0" />
                                                    {submitted && !selectedRoleObj && <small className="p-error">Role is required.</small>}
                                                </div>
                                                <div className="input">
                                                    <div className="label">Phone Number</div>
                                                    <PhoneInput country={'us'} countryCodeEditable={false} value={product.phoneNumber} onChange={(e) => onInputChange(e, 'phoneNumber')} className={`w-full unique-phone ${submitted && !product.phoneNumber ? "error-border" : ""}`} />
                                                    {submitted && !product.phoneNumber && <small className="p-error">Phone Number is required.</small>}
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="input">
                                                    <div className="label">Status</div>
                                                    <Dropdown value={product.status} options={statusOptions} optionLabel="name" onChange={handleStatusChange} className="w-full unique-drops" />
                                                    {submitted && !product.status && <small className="p-error">Status is required.</small>}
                                                </div>
                                                <div className="input">
                                                    <div className="label">Reason</div>
                                                    <InputText value={product.reason}  onChange={(e) => onInputChange(e, 'reason')} className={classNames({ 'p-invalid': submitted && !product.reason })} />
                                                    {submitted && !product.reason && <small className="p-error">Reason is required.</small>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog>
                            </>
                        )}

                    </div>
                )}
            </div>
        </>
    );
}

