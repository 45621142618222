import React, { useEffect, useState, useRef } from "react";
import './AccountProfiling.css';
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { BreadCrumb } from 'primereact/breadcrumb';
import { Toast } from "primereact/toast";
import Account from "./Beneficiary";
import Historyv from "./History";
import Transactionv from "./Transaction";
import { Triangle } from 'react-loader-spinner';
import { Card } from 'primereact/card';
import { Chart } from 'primereact/chart';
import { useLocation } from 'react-router-dom';

function AccountProfilings() {

    const [accountId, setAccountId] = useState('');
    const [customerData, setCustomerData] = useState(null);
    // const [apiData, setApiData] = useState(null);
    const [apiData1, setApiData1] = useState(null);
    const [apiData2, setApiData2] = useState(null);
    const [dataFetched, setDataFetched] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const toast = useRef(null);
    const [mapingData, setMapingData] = useState(null);
    const [chartDataTransactions, setChartDataTransaction] = useState(null);
    const [chartDataBeneficiarys, setChartDataBeneficiary] = useState(null);
    const [riskReasonClientId, setRiskReasonClientId] = useState();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const accessToken = location?.state?.accessToken;

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

   
    const handleRiskreason = (event) => {
        event.preventDefault();

        history.push({
            pathname: '/admin/account/profiling/riskreason',
            state: {
                clientId: riskReasonClientId,
                accountprofilingid: location?.state?.accountprofilingid,
                accountId: location?.state?.accountprofilingid,
                accessToken: location?.state?.accessToken,
                emailId: location?.state?.emailId,
                getUserData: location?.state?.getUserData,
            },
        });
    }

    const displayErrorToast = (message) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
    };

    useEffect(() => {
        const accountId = location?.state?.accountprofilingid

        if (!accountId) {
            console.error('Account ID not found in sessionStorage.');
            return;
        }

        const fetchData = async () => {
            setLoading(true);

            try {
                const token = accessToken
                if (!token) {
                    console.error('Access token not found. Please ensure the user is authenticated.');
                    return;
                }
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    // params
                };
                const uri = `/accounts/profile?accountId=${accountId}`;
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_HOST}${uri}`,
                    requestOptions
                );
                setCustomerData(response.data);
                response.data.forEach((data) => {
                    setMapingData(data);
                });
                const responseData = response.data[0];
                setAccountId(responseData.accountId);
                setRiskReasonClientId(responseData.clientId);
                setLoading(false);
                const requestOptions1 = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                };

                setTimeout(async () => {
                    const response2 = await axios.get(
                        `${process.env.REACT_APP_BACKEND_HOST}/accounts/bene/${accountId}`,
                        requestOptions1
                    )

                    setApiData1(response2.data);
                    setDataFetched(true);
                    setLoading(false);

                    const response3 = await axios.get(
                        `${process.env.REACT_APP_BACKEND_HOST}/data/api?accountId=${accountId}`,
                        requestOptions1
                    )
                    setApiData2(response3.data);
                    setDataFetched(true);
                    setLoading(false);

                }, 100);

            } catch (error) {
                setLoading(false);

                if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    sessionStorage.clear();
                    setLoading(false);
                }
                else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                    setLoading(false);
                }
                return;

            }
        };

        fetchData();

    }, [accessToken, accountId, location?.state?.accountprofilingid]);

    useEffect(() => {
        if (dataFetched) {
        }
    }, [dataFetched]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const rowDataString = params.get("data");
        const rowData = JSON.parse(decodeURIComponent(rowDataString));

        if (rowData) {
            const AccountId = rowData;
            setAccountId(AccountId)
        } else {
            const accountId = location?.state?.accountprofilingid
            setLoading(true);
            if (accountId) {
                const params = {
                    accountId,
                };

                axios.get(process.env.REACT_APP_BACKEND_HOST + '/accounts/profile', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + accessToken
                    },
                    params
                })
                    .then((response) => {
                        setCustomerData(response.data);
                        const responseData = response.data[0];
                        setAccountId(responseData.accountId);
                        setLoading(false);

                    })
                    .catch((error) => {
                        setLoading(false);

                        if (error?.response && error?.response?.status === 403) {
                            window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                            sessionStorage.clear();
                            setLoading(false);
                        }
                        else if (error?.response && error?.response?.data && error?.response?.data.message) {
                            const errorMessage = error?.response?.data?.message;
                            displayErrorToast(errorMessage);
                            setLoading(false);
                        }
                    });
            } else {
                setLoading(false);

            }
        }

    }, [location.search, setAccountId, accessToken, location?.state?.accountprofilingid ]);

    const items = [
        {
            label: 'Risk Check', command: () =>
                history.push({
                    pathname: '/admin/account/search',
                    state: {
                        accessToken: accessToken,
                        emailId: location?.state?.emailId,
                        getUserData: location?.state?.getUserData,
                    },
                })
        },
        {
            label: 'Account Search', command: () =>
                history.push({
                    pathname: '/admin/account/search',
                    state: {
                        accessToken: accessToken,
                        emailId: location?.state?.emailId,
                        getUserData: location?.state?.getUserData,
                    },
                })
        },
        { label: 'Individual Profiling' },
    ];
    const home = {
        icon: 'pi pi-home', command: () =>
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                },
            })
    }
    const home1 = { icon: 'pi pi-angle-double-left', command: () => window.history.back() }
    // const handleNavigate = (url) => {
    //     history.push(url);
    // }

    // apiData1

    if (apiData1?.length >= 1 || apiData2?.length >= 1) {
        const chartDataBeneficiary = {
            labels: Object?.keys(apiData1),
            datasets: [
                {
                    label: 'Transaction Count',
                    data: Object?.values(apiData1).map(item => item.transactionCount),
                    backgroundColor: '#FFE0E6',
                },
                {
                    label: 'Total Amount',
                    data: Object?.values(apiData1).map(item => item.totalAmount),
                    backgroundColor: '#DCFCE7',
                },
            ],
        };

        const chartOptionsBeneficiary = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: 'gray',
                        usePointStyle: true
                    },
                    position: 'bottom',
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: 'gray',
                        font: {
                            size: 10
                        },
                    },
                    grid: {
                        color: '#E5E4E2',
                        display: false,
                    },
                },
                y: {
                    ticks: {
                        color: 'gray'
                    },
                    grid: {
                        color: '#E5E4E2',
                    }
                }
            }
        };

        const Beneficiary = [{
            chartDataBeneficiary: chartDataBeneficiary,
            chartOptionsBeneficiary: chartOptionsBeneficiary
        }]

        setChartDataBeneficiary(Beneficiary);

        // apiData2
        const chartDataTransaction = {
            labels: Object?.keys(apiData2),
            datasets: [
                {
                    label: 'Pending',
                    data: Object?.values(apiData2).map(item => item.pendingCount),
                    fill: true,
                    backgroundColor: '#FFF3D6',
                    borderColor: 'rgb(255, 99, 132)',
                    pointBackgroundColor: 'rgb(255, 99, 132)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgb(255, 99, 132)',
                },
                {
                    label: 'Cleared',
                    data: Object?.values(apiData2).map(item => item.clearedCount),
                    fill: true,
                    backgroundColor: '#D7ECFB',
                    borderColor: 'rgb(54, 162, 235)',
                    pointBackgroundColor: '#D7ECFB',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgb(54, 162, 235)',
                },
                {
                    label: 'Blocked',
                    data: Object?.values(apiData2).map(item => item.blockedCount),
                    fill: true,
                    backgroundColor: '#DBF2F2',
                    borderColor: 'rgb(54, 162, 235)',
                    pointBackgroundColor: 'rgb(54, 162, 235)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgb(54, 162, 235)',
                },
            ],
        };

        const chartOptionsTransaction = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                legend: {
                    labels: {
                        color: "gray",
                        usePointStyle: true
                    },
                    position: 'bottom',
                }
            },
            scales: {
                type: 'category',
                x: {
                    stacked: true,
                    ticks: {
                        color: "gray"
                    },
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: "--text-color-secondary"
                    },
                }
            }
        };

        const Transaction = [{
            chartDataTransaction: chartDataTransaction,
            chartOptionsTransaction: chartOptionsTransaction
        }]

        setChartDataTransaction(Transaction);
    }

    return (
        <>
            <div className="mainSec" >
                <BreadCrumb model={items} className='breadCrumb' home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />
                <Toast ref={toast} position="top-right" style={{ top: '10%' }} />

                {loading ? (
                    <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                        <Triangle
                            height="80"
                            width="80"
                            color="#124A99"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <>
                        <Card title={('Account Id : ') + accountId} className='carD' style={{ height: (windowWidth < 500 ? 'auto' : '') }}>
                            {customerData?.length >= 1 && (
                                <div className='h_ing'>
                                    Corporate Information
                                </div>
                            )}

                            {customerData?.length >= 1 && (
                                <>
                                    {customerData?.map(nameObj => (
                                        <>
                                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Client Id <span>:</span></div>
                                                <div className='value'>{nameObj?.clientId || 'N/A'}</div>
                                            </div>

                                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Profile Type <span>:</span></div>
                                                <div className='value' style={{
                                                    color:
                                                        nameObj.profile === 'Medium Risk'
                                                            ? '#f7af00'
                                                            : nameObj.profile === 'Low Risk'
                                                                ? 'rgb(69 160 75)'
                                                                : nameObj.profile === 'High Risk'
                                                                    ? '#f42616'
                                                                    : 'rgb(143, 143, 143)',
                                                }}>{nameObj?.profile || 'N/A'}</div>
                                            </div>

                                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                                <div className='leable' style={{ width: '230px', display: 'flex', justifyContent: 'space-between' }}>Matched Against Sip <span>:</span></div>
                                                <div className='value'>{nameObj?.matchedAgainstSip || 'N/A'}</div>
                                            </div>

                                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Full Name <span>:</span></div>
                                                <div className='value' style={{ backgroundColor: nameObj?.fullName ? '#fef2c2' : 'transparent' }}>{nameObj?.fullName || 'N/A'}</div>
                                            </div>

                                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Arabic Name <span>:</span></div>
                                                <div className='value'>{mapingData?.arabicName || 'N/A'}</div>
                                            </div>

                                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Date of Birth <span>:</span></div>
                                                <div className='value'>{mapingData?.dob || 'N/A'}</div>
                                            </div>

                                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Gender <span>:</span></div>
                                                <div className='value'>{mapingData?.gender || 'N/A'}</div>
                                            </div>

                                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Postal Code <span>:</span></div>
                                                <div className='value'>{mapingData?.postalCode || 'N/A'}</div>
                                            </div>

                                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Country <span>:</span></div>
                                                <div className='value'>{mapingData?.country || 'N/A'}</div>
                                            </div>

                                            <div className='info_D' style={{ height: 'auto', minHeight: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Address <span>:</span></div>
                                                <div className='value'>{mapingData?.address || 'N/A'}</div>
                                            </div>

                                            <div className='info_D' style={{ height: 'auto', minHeight: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>City <span>:</span></div>
                                                <div className='value'>{mapingData?.city || 'N/A'}</div>
                                            </div><br />

                                            <div className="div" /><br />
                                            <div className="heading" style={{ padding: '0 20px' }}>Last Transaction</div>

                                            <div className='info_D' style={{ minHeight: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                <div className='leable' style={{ width: '230px', display: 'flex', justifyContent: 'space-between' }}>Last Transaction Date <span>:</span></div>
                                                <div className='value'>{mapingData?.lastTransactionDate || 'N/A'}</div>
                                            </div>

                                            <div className='info_D' style={{ minHeight: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                <div className='leable' style={{ width: '230px', display: 'flex', justifyContent: 'space-between' }}>Previous Transaction Date <span>:</span></div>
                                                <div className='value'>{mapingData?.lastBeforeTransactionDate || 'N/A'}</div>
                                            </div>

                                            <div className='info_D' style={{ minHeight: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                <div className='leable' style={{ width: '230px', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>Transaction Frequency <span>:</span></div>
                                                <div className='value'>{mapingData?.transactionFrequency || 'N/A'}</div>
                                            </div>

                                            <div className="div" /><br />
                                            <div className="heading" style={{ padding: '0 20px' }}>Risk Reason</div><br />

                                            <div className='info_D' style={{ height: 'auto', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Risk Reason <span>:</span></div>
                                                <div className='value'>{mapingData?.riskReason || 'N/A'}</div>
                                            </div>

                                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>more <span>:</span></div>
                                                <div className='value text-blue-700 font-bold' onClick={handleRiskreason} style={{ cursor: 'pointer' }}>click here.</div>
                                            </div><br />


                                        </>
                                    ))}

                                </>
                            )}

                            <div className='h_ing'>
                                View Beneficiary
                            </div>

                            <Account data={location?.state} />

                            <div className='h_ing'>
                                View Transaction
                            </div>


                            <Transactionv data={location?.state} />

                            <div className='h_ing'>
                                View History
                            </div>

                            <Historyv data={location?.state} />

                            {(apiData1?.length >= 1 || apiData2?.length >= 1) && (
                                <>
                                    <div className='h_ing'>
                                        Graphical Representation
                                    </div>

                                    <div className='flex' style={{ justifyContent: 'space-around', margin: 'auto', flexWrap: 'wrap' }}>
                                        <>
                                            {apiData1 ?
                                                <div className='width-height-chart' style={{ width: (windowWidth < 500 ? '-webkit-fill-available' : '') }}>
                                                    <h4 style={{ marginLeft: '5px' }} className='text-center'>Beneficiary</h4>
                                                    <div className="flex justify-content-center">
                                                        <Chart type="bar" data={chartDataBeneficiarys?.chartDataBeneficiary} options={chartDataBeneficiarys?.chartOptionsBeneficiarys} className="w-full md:w-28rem h-20rem" />
                                                    </div>
                                                </div>
                                                : ''}

                                            {apiData2 ?
                                                <div className='width-height-chart m-2' style={{ width: (windowWidth < 500 ? '-webkit-fill-available' : '') }}>
                                                    <h4 className='trans-h4-style text-center' >Transaction</h4>
                                                    <div className="flex justify-content-center">
                                                        <Chart type="bar" data={chartDataTransactions?.chartDataTransaction} options={chartDataTransactions?.chartOptionsTransactions} className="w-full md:w-26rem h-20rem" />
                                                    </div>
                                                </div>
                                                : ''}
                                        </>
                                    </div>
                                </>
                            )}
                        </Card>
                    </>
                )}
            </div >
        </>
    )
}
export default AccountProfilings;