import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';
import { Triangle } from 'react-loader-spinner';
import { Button } from 'primereact/button';
import { useHistory, useLocation } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { Toast } from 'primereact/toast';


function CorporateAdvanceTable(props) {

    const [individualdata, setIndividualdata] = useState([]);
    // const [loading, setLoading] = useState(true);
    const history = useHistory();
    const [loadings, setLoadings] = useState(false);
    const toast = React.useRef(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const location = useLocation();
    // const storageData = location.state;
    const corporateSearchData = location.state;
    // const [corporateSearchData, setCorporateSearchData] = useState(storageData);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        setTimeout(() => {
        }, 500);
    }, []);

    useEffect(() => {
        setLoadings(true);
        const fetchData = async () => {
            const data = {
                fullName: corporateSearchData?.fullname,
                arabicName: corporateSearchData?.arabicname,
                idNumber: corporateSearchData?.idnumber,
                address: corporateSearchData?.address,
                city: corporateSearchData?.city,
                country: corporateSearchData?.country,
                postalCode: corporateSearchData?.postalCode,
                exact: corporateSearchData?.exact || 'true',
                partial: corporateSearchData?.partial || 'true',
                wrinklerDistance: corporateSearchData?.wrinklerDistance || 'false',
            }
            try {
                const response = await axios.post(
                    process.env.REACT_APP_BACKEND_HOST + '/corporate/sanctions', data,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization:
                                'Bearer ' + location?.state?.accessToken,
                        },
                    }
                );
                if (response.data.individuals.length === 0) {
                    toast.current.show({
                        severity: 'warn',
                        summary: 'Warning',
                        detail: 'No data found.',
                    });
                }
                setIndividualdata(response.data.individuals);
                setLoadings(false);
            } catch (error) {
                if (error?.response && error?.response?.status === 403) {
                    (window?.location?.assign(process.env.REACT_APP_AUTH_URL));
                    sessionStorage.clear();
                }
                setLoadings(false);
            }
        };
        fetchData();
    }, [corporateSearchData?.fullname,location?.state?.accessToken,corporateSearchData?.arabicname,corporateSearchData?.idnumber,corporateSearchData?.address,corporateSearchData?.city,corporateSearchData?.country, corporateSearchData?.postalCode,corporateSearchData?.exact,corporateSearchData?.partial,corporateSearchData?.wrinklerDistance]);

    const actionbody = (rowData) => {
        return (
            <Button
                style={{ color: '#124A99', fontWeight: 'bold', width: 'fit-content' }}
                className="p-button p-button-text p-button-info pi pi-info-circle"
                onClick={() => handleMoreInfo(rowData.id)}
            />
        );
    };

    const handleMoreInfo = (id) => {
        history.push({
            pathname: '/admin/adhoc/corporateadvancemoreinfo',
            state: {
                pageCount: id,
                corporateSearchData: corporateSearchData,
                accessToken: location?.state?.accessToken,
                emailId: location?.state?.emailId,
                getUserData: location?.state?.getUserData,
            },
        });
    };

    const items = [
        {
            label: 'Risk Check', command: () =>
                history.push({
                    pathname: '/admin/adhoc/search',
                    state: {
                        accessToken: location?.state?.accessToken,
                        emailId: location?.state?.emailId,
                        getUserData: location?.state?.getUserData,
                    },
                })
        },
        {
            label: 'Adhoc Search', command: () =>
                history.push({
                    pathname: '/admin/adhoc/search',
                    state: {
                        accessToken: location?.state?.accessToken,
                        emailId: location?.state?.emailId,
                        getUserData: location?.state?.getUserData,
                    },
                })
        },
        { label: 'Corporate Search Data' },
    ];
    const home = location?.state?.getUserData?.premiumPlan === "FreeTrial" ? null : {
        icon: 'pi pi-home',
        command: () => history.push({
            pathname: '/admin/dashboard',
            state: {
                accessToken: location?.state?.accessToken,
                emailId: location?.state?.emailId,
                getUserData: location?.state?.getUserData,
            },
        })
    };
    const home1 = { icon: 'pi pi-angle-double-left', command: () => window.history.back() }

    const highlightMatchType = (exactName) => {
        return exactName.map((name, index) => (
            <span key={index} style={{ backgroundColor: name.matchType === 'bg-orange-500' ? '#fef2c2' : 'transparent' }}>
                {name.name}{' '}
            </span>
        ));
    };

    return (
        <>
            <div className="mainSec">
                <Toast ref={toast} />

                {loadings ? (
                    <div class="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                        <Triangle
                            height="80"
                            width="80"
                            color="#124A99"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <div className="card" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <BreadCrumb model={items} className='breadCrumb' home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />
                        {/* <ToastContainer /> */}
                        <DataTable
                            value={individualdata} stripedRows
                            paginator
                            rows={10}
                            scrollable
                            scrollHeight="63vh"
                            rowsPerPageOptions={[15, 30, 45, 60]}
                            style={{ whiteSpace: 'nowrap' }}
                            className='dataTable adh'
                        >
                            <Column field="id" header="Id" />
                            <Column field="exactName.name" bodyClassName="" header="Full Name" body={(rowData) => highlightMatchType(rowData.exactName)}></Column>
                            <Column field="matchPoints" header="Match Point" />
                            <Column field="number" header="Number" />
                            <Column field="action" header="Action" />
                            <Column header="More Info" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} body={actionbody} />
                        </DataTable>
                    </div>
                )}
            </div>
        </>
    )
}

export default CorporateAdvanceTable
