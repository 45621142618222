import React, { useEffect, useState, useRef } from "react";
import { Dropdown } from 'primereact/dropdown';
import IndividualSearch from "../IndividualSearch/IndividualSearch";
import CorporateSearch from "../CorporateSearch/CorporateSearch";
import './Accountsearch.css';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Triangle } from 'react-loader-spinner';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import axios from "axios";
import { Toast } from "primereact/toast";
import { useLocation } from 'react-router-dom';

function Accountsearch({roleName}) {

    const [activeSection, setActiveSection] = useState('Individual');
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const toast = useRef(null);
    const location = useLocation();

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        setTimeout(() => {
            setLoading(false);
        }, 500);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSectionClick = (section) => {
        setActiveSection(section);
    };

    const items = [
        { label: 'Risk Check' },
        { label: 'Account Search' }
    ];

    const home = {
        icon: 'pi pi-home', command: () =>
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                },
            })
    }
    const home1 = { icon: 'pi pi-angle-double-left', command: () => window.history.back() }

    const option = [
        { label: 'Individual Search', value: 'Individual' },
        { label: 'Corporate Search', value: 'corporate' },
    ];

    return (
        <>
            <div className="mainSec main-scroll-height" >
                <BreadCrumb model={items} className='breadCrumb' home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />
                <Toast ref={toast} position="top-right" style={{ top: '10%' }} />
                {loading ? (
                    <div class="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                        <Triangle
                            height="80"
                            width="80"
                            color="#124A99"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <>
                        <div className="feild-Sec">
                            <Dropdown className='dropdown' style={{ width: '100%' }} options={option} onChange={(selectedOption) => handleSectionClick(selectedOption.value)} value={activeSection} />
                        </div>

                        <div className='condent_sec' style={{ height: (windowWidth < 500 ? '65vh' : '70vh') }} >
                            {activeSection === 'Individual' && <IndividualSearch Data={location?.state} roleName={roleName} />}
                            {activeSection === 'corporate' && <CorporateSearch Data={location?.state} roleName={roleName} />}
                        </div>
                    </>

                )}
            </div>
        </>
    )
}
export default Accountsearch;