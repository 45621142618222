import React, { useState, useRef, useEffect } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import axios from "axios";
import moment from 'moment';
import { Toast } from "primereact/toast";
import { Triangle } from 'react-loader-spinner';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import '../Accountsearch/Accountsearch.css';
// import { useLocation } from 'react-router-dom';

function IndividualSearch(props) {
    // const location = useLocation();
    const [date, setDate] = useState(null);
    const [arabicName, setArabicName] = useState('');
    let anameRef = useRef(null);
    const [idNumber, setIdNumber] = useState('');
    let idNumberRef = useRef(null);
    const [fullName, setFullname] = useState('');
    let fnameRef = useRef(null);
    const [gender, setGender] = useState('');
    // const [nationalitydata, setNationalitydata] = useState(props?.Data?.nationalitydata || []);
    const nationalitydata = props?.Data?.nationalitydata || [];
    const [nationality, setNationality] = useState('');
    const [status, setStatus] = useState('');
    const [profiletype, setProfileType] = useState('');
    const [clientId, setClientId] = useState('');
    let clientIdRef = useRef(null);
    const toast = useRef(null);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [clientIdError, setClientIdError] = useState('');
    // const [fullNameError, setFullNameError] = useState('');
    const history = useHistory();
    const currentDate = new Date();
    const eighteenYearsAgo = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const accessToken = props?.Data?.accessToken;

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleClientIdBlur = () => {
        const numericRegex = /^[0-9]+$/;
        if (!clientId.match(numericRegex)) {
            // setClientIdError('Client Id must contain only numbers.');
        } else {
            // setClientIdError('');
        }
    };

    const validateFullName = () => {
        const regex = /^[a-zA-Z\s]*$/;
        if (!regex.test(fullName)) {
            // setFullNameError('Full name should contain letters and spaces only.');
        } else {
            // setFullNameError('');
        }
    };

    const handleGenderChange = (event) => {
        setGender(event.value);
    }

    const handleStatusChange = (event) => {
        setStatus(event.value);
    }
    const handleProfileTypeChange = (event) => {
        setProfileType(event.value);
    }

    const handleDateChange = (selectedDate) => {
        const formattedSelectedDate = moment(selectedDate).format('MM/DD/YYYY');
        setDate(formattedSelectedDate);
    };

    const displayErrorToast = (message) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
    };
    const displayWarningToast = (message) => {
        toast.current.show({ severity: 'info', summary: 'Info', detail: message, life: 5000 });
    };

    // const email = props?.Data?.emailId;

    const handleReset = () => {
        setClientId('');
        setFullname('');
        setArabicName('');
        setDate('');
        setGender('');
        setNationality(null);
        setIdNumber('');
        setStatus('');
        setProfileType('');
        if (fnameRef.current) {
            fnameRef.current.value = fnameRef.current.defaultValue;
        }
        if (anameRef.current) {
            anameRef.current.value = anameRef.current.defaultValue;
        }
        if (idNumberRef.current) {
            idNumberRef.current.value = idNumberRef.current.defaultValue;
        }
        if (clientIdRef.current) {
            clientIdRef.current.value = clientIdRef.current.defaultValue;
        }
        // setTableData([]);
    }

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        // if (!status && !profiletype && !nationality && !clientId && !fullName && !arabicName && !gender && !idNumber) {
        //     displayWarningToast('Please choose at least one option before searching.');
        //     setLoading(false);
        //     return; // Stop further execution
        // }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_HOST}/account/pending?status=${status}&profileType=${profiletype}&nationality=${nationality ? nationality.laCountryName : ''}&clientId=${clientId}&fullName=${fullName}&arabicName=${arabicName}&gender=${gender}&idNumber=${idNumber}`,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + accessToken
                    }
                }
            );

            if (response.data.accounts.length === 0) {
                displayWarningToast('No accounts found.');
                // setTableData(false);
            } else {
                setTableData(response.data.accounts);
            }
            setLoading(false);
            handleReset()
        } catch (error) {
            setLoading(false);
            if (error?.response && error?.response?.data && error?.response?.data?.message) {
                const errorMessage = error?.response?.data?.message;
                displayErrorToast(errorMessage);
            } else if (error?.response?.status === 401) {
                toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
            } else if (error?.response?.status === 403) {
                window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                sessionStorage.clear();
            } else {
                displayErrorToast('An error occurred. Please try again later.');
            }
            handleReset()
        }
    };

    const handleButtonClick = (rowData) => {
        const rowDataString = JSON.stringify(rowData);
        const newURL = `/admin/account/profilings?data=${encodeURIComponent(rowDataString)}`;
        history.push(newURL);
        history.push({
            state: {
                individualid: rowDataString,
                accountprofilingid: rowData,
                accessToken: accessToken,
                emailId: props?.Data?.emailId,
                getUserData: props?.Data?.getUserData,
            },
        });
    };

    const InfoTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    className="pi pi-info-circle"
                    onClick={() => handleButtonClick(rowData.id)}
                    id="MI"
                    style={{ backgroundImage: 'none', backgroundColor: 'transparent', color: 'navy', border: 'none' }}
                />
            </React.Fragment>
        );
    };

    const genders = [
        { name: "Male", code: "Male" },
        { name: "Female", code: "Female" },
        { name: "Other", code: "Other" }
    ];

    const profileOptions = [
        "High Risk",
        "Medium Risk",
        "Low Risk",
        "Watch",
    ]

    const statusOptions = [
        "Pending",
        "Cleared",
        "Blocked",
    ]

    const handleNationalityChange = (event) => {
        const selectedNationality = nationalitydata.find((item) => item?.country?.laCountryName === event?.target?.value);
        setNationality(selectedNationality);
    };

    const statusTemplate = (rowData) => {
        const status = rowData.status;
        let color = '';
        if (status === 'Pending') {
            color = '#f7af00';
        } else if (status === 'Blocked') {
            color = '#f42616';
        } else if (status === 'Cleared') {
            color = 'rgb(69 160 75)';
        } else if (status === 'On-going') {
            color = '#3746c6';
        }
        return (
            <div
                style={{
                    // backgroundColor: backgroundColor,
                    color: color,
                    fontWeight: '500',

                }}
            >
                {status}
            </div>
        );
    };

    const profileTemplate = (rowData) => {
        const status = rowData.profileType;
        let color = '';
        if (status === 'Medium Risk') {
            color = '#f7af00';
        } else if (status === 'High Risk') {
            color = '#f42616';
        } else if (status === 'Low Risk') {
            color = 'rgb(69 160 75)';
        }
        return (
            <div
                className="profile-type-size"
                style={{
                    color: color,
                    fontWeight: '500',
                }}
            >
                {status}
            </div>
        );
    };
    const idNumberBody = (rowData) => {
        return rowData.idNumber ? rowData.idNumber : '-';
      };

    return (
        <>
            <div style={{ margin: 'unset !important' }}>
                <Toast ref={toast} position="top-right" style={{ top: '10%' }} />
                {loading ? (
                    <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                        <Triangle
                            height="80"
                            width="80"
                            color="#124A99"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <>
                        <div className="feild c">
                            <div className="flex" >
                                <div className="input" style={{ margin: 'auto' }}>
                                    <div className="label">Client Id</div>
                                    <InputText keyfilter="int" onChange={(event) => setClientId(event.target.value)} onBlur={handleClientIdBlur} ref={clientIdRef} defaultValue={clientIdRef.current ? clientIdRef.current.defaultValue : ''} />
                                </div>
                                <div className="input" style={{ margin: 'auto' }}>
                                    <div className="label">Full Name</div>
                                    <InputText onChange={(event) => setFullname(event.target.value)} ref={fnameRef} defaultValue={fnameRef.current ? fnameRef.current.defaultValue : ''} onBlur={validateFullName} />
                                    {fullName && fullName.length < 3 && <div className="error-message">minimum 3 characters</div>}
                                </div>
                                <div className="input" style={{ margin: 'auto' }}>
                                    <div className="label">Name In Arabic</div>
                                    <InputText dir="rtl" ref={anameRef} defaultValue={anameRef.current ? anameRef.current.defaultValue : ''} onChange={(event) => setArabicName(event.target.value)} />
                                </div>
                            </div>

                            <div className="flex">
                                <div className="input" style={{ margin: 'auto' }}>
                                    <div className="label">Date Of Birth</div>
                                    <Calendar value={date} style={{ padding: 'unset' }} showIcon id="p-calender" className="w-full calander" onChange={handleDateChange} maxDate={eighteenYearsAgo} />
                                </div>
                                <div className="input" style={{ margin: 'auto' }}>
                                    <div className="label">Gender</div>
                                    <Dropdown optionLabel="name" optionValue="code" options={genders} value={gender} className="w-full p-0 unique-drops dropdown" onChange={handleGenderChange} />
                                </div>
                                <div className="input" style={{ margin: 'auto' }}>
                                    <div className="label">Nationality</div>
                                    <Dropdown value={nationality ? nationality?.country?.laCountryName : null} options={nationalitydata.map((item) => ({ label: item?.country?.laCountryName, value: item?.country?.laCountryName }))} onChange={handleNationalityChange} filter required className="w-full p-0 unique-drops dropdown" optionLabel="label" />
                                </div>
                            </div>

                            <div className="flex">
                                <div className="input" style={{ margin: 'auto' }}>
                                    <div className="label">Id Number</div>
                                    <InputText ref={idNumberRef} defaultValue={idNumberRef.current ? idNumberRef.current.defaultValue : ''} onChange={(event) => setIdNumber(event.target.value)} />
                                </div>
                                <div className="input" style={{ margin: 'auto' }}>
                                    <div className="label">Status</div>
                                    <Dropdown className="w-full p-0 unique-drops dropdown" value={status} options={statusOptions} onChange={handleStatusChange} />
                                </div>
                                <div className="input" style={{ margin: 'auto' }}>
                                    <div className="label">Profile Type</div>
                                    <Dropdown value={profiletype} options={profileOptions} onChange={handleProfileTypeChange} className="w-full p-0 unique-drops dropdown" />
                                </div>
                            </div>

                            <div className="footer_sec_btn" style={{ padding: 'unset' }}>
                                <Button style={{ background: '#124A99' }} className="resetBtn" icon="pi pi-refresh" onClick={handleReset} />
                                <Button style={{ background: '#124A99' }} onClick={handleSubmit} disabled={!status && !profiletype && !nationality && !clientId && !fullName && !arabicName && !gender && !idNumber}>Search</Button>
                            </div>

                            {tableData.length > 0 && (
                                <div className="individual-account-search" style={{ width: windowWidth <= 500 ? '95%' : '100%', margin: 'auto', border: 'solid rgba(209, 209, 209, 1) 1px' }}>
                                    <DataTable value={tableData} id='edit-btn1' paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}>
                                        {props.roleName !== "FreeTrial" && <Column field="id" header="Id"></Column>}
                                        {props.roleName === "FreeTrial" && <Column field="clientId" header="Client Id"></Column>}
                                        <Column field="fullName" header="Full Name"></Column>
                                        {props.roleName === "FreeTrial" && <Column field="gender" header="Gender"></Column>}
                                        <Column field="nationality" header="Nationality"></Column>
                                        {props.roleName === "FreeTrial" && <Column field="idNumber" header="Id Number" body={idNumberBody}></Column>}
                                        <Column field="dob" header="Date Of Birth"></Column>
                                        <Column field="profileType" header="Profile Type" body={profileTemplate}></Column>
                                        <Column field="status" header="Status" body={statusTemplate}></Column>
                                        {props.roleName !== "FreeTrial" && <Column body={InfoTemplate} header="More Info"></Column>}
                                    </DataTable>
                                </div>
                            )}

                        </div>
                    </>
                )}

            </div>
        </>
    )
}


export default IndividualSearch;