import React, { useEffect, useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';
import { Triangle } from 'react-loader-spinner';
import { Button } from 'primereact/button';
import { useHistory, useLocation } from 'react-router-dom';
import '../../../../../views/Loading.css';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Toast } from 'primereact/toast';
// import './Individualsearchdata.css';

function Individualsearchdata(props) {


    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [dailyLimitExceeded, setDailyLimitExceeded] = useState(false);
    // const [error, setError] = useState(false);
    const history = useHistory();
    const toast = useRef(null);
    const location = useLocation();
    const storageData = location.state;

    useEffect(() => {
        const fetchData = async (url) => {
            try {
                const data = {
                    fullName: location?.state?.individualname,
                    exact: location?.state?.exactvalue,
                    partial: location?.state?.partialvalue,
                    wrinklerDistance: location?.state?.eightyvalue,
                };
                const response = await axios.post(
                    url,
                    data,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + location?.state?.accessToken,
                        },
                    }
                );

                return response.data;
            } catch (error) {
                if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                } else if (error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    sessionStorage.clear();
                } else if (error.response && error.response.data.message === "Daily access limit exceeded for FreeTrial users") {
                    toast.current.show({ severity: 'warn', summary: 'Limit Exceeded', detail: 'Daily access limit exceeded for FreeTrial user.', life: 3000 });
                }
                return null;
            }
        };

        async function fetchDataForTable() {
            setLoading(true);

            try {
                const individualsResponse = await fetchData(process.env.REACT_APP_BACKEND_HOST + '/sanctions');
                const corporateResponse = await fetchData(process.env.REACT_APP_BACKEND_HOST + '/corporate/sanctions');

                const combinedData = [
                    ...(individualsResponse?.individuals || []),
                    ...(corporateResponse?.individuals || [])
                ];
                setData(combinedData);

                const accessCount = individualsResponse?.accessCount;
                const remainingCount = 5 - (parseInt(accessCount) || 0);
                if (props.roleName === "FreeTrial") {
                    accessCount < 5 && (
                        toast.current.show({
                            severity: 'warn',
                            summary: 'Remaining Search',
                            detail: `Remaining Search count is: ${remainingCount}`,
                            life: 3000,
                        })
                    );
                }

                if (combinedData.length === 0) {
                    toast.current.show({
                        severity: 'warn',
                        summary: 'No Data',
                        detail: 'The response is empty.',
                        life: 3000,
                    });
                }
            } catch (error) {
                if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                } else if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    sessionStorage.clear();
                }
            } finally {
                setLoading(false);
            }
        }

        fetchDataForTable();
    }, [props.roleName, location]);


    const actionbody = (rowData) => {
        return (
            <Button
                className="p-button p-button-text p-button-info pi pi-info-circle"
                onClick={() => handleMoreInfo(rowData.number)}
            />
        );
    };

    const handleMoreInfo = (number) => {
        history.push({
            pathname: '/admin/adhoc/corporateadhocmoreinfo',
            state: {
                storageData: storageData,
                pageCount: number,
                accessToken: location?.state?.accessToken,
                emailId: location?.state?.emailId,
                getUserData: location?.state?.getUserData,
                fullName: location?.state?.individualname,
                exact: location?.state?.exactvalue,
                partial: location?.state?.partialvalue,
                wrinklerDistance: location?.state?.eightyvalue,
            },
        });
    };
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const items = [
        {
            label: 'Risk Check', command: () =>
                history.push({
                    pathname: '/admin/adhoc/search',
                    state: {
                        accessToken: location?.state?.accessToken,
                        emailId: location?.state?.emailId,
                        getUserData: location?.state?.getUserData,
                    },
                })
        },
        {
            label: 'Adhoc Search', command: () =>
                history.push({
                    pathname: '/admin/adhoc/search',
                    state: {
                        accessToken: location?.state?.accessToken,
                        emailId: location?.state?.emailId,
                        getUserData: location?.state?.getUserData,
                    },
                })
        },
        { label: 'Adhoc Search Data' },
    ];

    const home = location?.state?.getUserData?.premiumPlan === "FreeTrial" ? null : {
        icon: 'pi pi-home',
        command: () => history.push({
            pathname: '/admin/dashboard',
            state: {
                accessToken: location?.state?.accessToken,
                emailId: location?.state?.emailId,
                getUserData: location?.state?.getUserData,
            },
        })
    };

    const home1 = { icon: 'pi pi-angle-double-left', command: () => window.history.back() }

    const highlightMatchType = (exactName) => {
        return exactName.map((name, index) => (
            <span key={index} style={{ backgroundColor: name.matchType === 'bg-orange-500' ? '#fef2c2' : 'transparent' }}>
                {name.name}{' '}
            </span>
        ));
    };


    return (
        <>
            <div className="mainSec" >
                <BreadCrumb model={items} home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />

                {loading ? (
                    <div class="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                        <Triangle height="80" width="80" color="#124A99" ariaLabel="triangle-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
                    </div>
                ) : (

                    <div className="card card-Adhoc h-full">
                        <DataTable
                            value={data}
                            stripedRows
                            showGridlines
                            paginator
                            rows={10}
                            scrollable
                            scrollHeight="63vh"
                            rowsPerPageOptions={[15, 30, 45, 60]}
                            style={{ whiteSpace: 'nowrap' }}
                            className='dataTable adh'
                        >
                            <Column field="id" header="Id"></Column>
                            <Column field="exactName.name" style={{ width: '100px' }} bodyClassName="" header="Full Name" body={(rowData) => highlightMatchType(rowData.exactName)}></Column>
                            <Column field="description" header="Description"></Column>
                            <Column field="number" header="Number"></Column>
                            <Column field="action" header="Action"></Column>
                            <Column header="More Info" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} body={actionbody} ></Column>
                        </DataTable>
                    </div>
                )}

            </div>

            <Toast ref={(el) => (toast.current = el)} />

        </>

    );
}

export default Individualsearchdata;
