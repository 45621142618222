
import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';
import { Triangle } from 'react-loader-spinner';

import { Button } from 'primereact/button';
import { useHistory, useLocation } from 'react-router-dom';
import '../../../../../views/Loading.css';
import { BreadCrumb } from 'primereact/breadcrumb';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AdvanceIndividualSearch(props) {

  const [individualdata, setIndividualdata] = useState([]);
  // const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [loadings, setLoadings] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  // const storageData = location.state;
  const individualSearchData = location.state;
  // const [individualSearchData, setIndividualSearchData] = useState(storageData);


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  useEffect(() => {
    const fetchData = async () => {
      setLoadings(true);

      const data = {
        fullName: individualSearchData?.fullname,
        arabicName: individualSearchData?.arabicname,
        gender: individualSearchData?.gender,
        nationality: individualSearchData?.nationality,
        idNumber: individualSearchData?.idnumber,
        address: individualSearchData?.address,
        city: individualSearchData?.city,
        country: individualSearchData?.country,
        postalCode: individualSearchData?.postalCode,
        pep: individualSearchData?.pep,
        exact: 'true',
        partial: 'true',
        wrinklerDistance: 'false',
      }

      // const data = {
      //   fullName: sessionStorage.getItem("individulaadvancefullname"),
      //   arabicName: sessionStorage.getItem("individulaadvancearabicname"),
      //   dob: sessionStorage.getItem("individulaadvancedate"),
      //   gender: sessionStorage.getItem("individulaadvancegender"),
      //   nationality: sessionStorage.getItem("individulaadvancenationality"),
      //   idNumber: sessionStorage.getItem("individulaadvanceidnumber"),
      //   address: sessionStorage.getItem("individulaadvanceaddress"),
      //   city: sessionStorage.getItem("individulaadvancecity"),
      //   country: sessionStorage.getItem("individulaadvancecountry"),
      //   postalCode: sessionStorage.getItem("individulaadvancepostalCode"),
      //   pep: sessionStorage.getItem("individulaadvancepep"),
      //   exact: "true",
      //   partial: "true",
      //   wrinklerDistance: 'false'
      // }

      try {
        const response = await axios.post(
          process.env.REACT_APP_BACKEND_HOST + '/sanctions', data,

          {
            headers: {
              'Content-Type': 'application/json',
              Authorization:
                'Bearer ' + location?.state?.accessToken,
            },
          }
        );
        if (response.data.individuals.length === 0) {
          toast.error('No data found.');
        }
        setIndividualdata(response.data.individuals);
        setLoadings(false);

      } catch (error) {
        if (error?.response?.status === 401) {
          toast.error({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', autoClose: 3000 });
        } else if (error?.response && error?.response?.status === 403) {
          (window?.location?.assign(process.env.REACT_APP_AUTH_URL));
          sessionStorage.clear();
        }
        setLoadings(false);
      }
      setLoadings(false);
    };

    fetchData();
  }, [individualSearchData?.fullname,individualSearchData?.arabicname,individualSearchData?.gender,individualSearchData?.nationality,individualSearchData?.idnumber,individualSearchData?.address,individualSearchData?.city,individualSearchData?.country,individualSearchData?.postalCode,individualSearchData?.pep,location?.state?.accessToken]);

  const actionbody = (rowData) => {
    return (
      <Button
        style={{ color: '#124A99', fontWeight: 'bold', width: 'fit-content' }}
        className="p-button p-button-text p-button-info pi pi-info-circle"
        onClick={() => handleMoreInfo(rowData.id)}
      />
    );
  };

  const handleMoreInfo = (id) => {
    history.push({
      pathname: '/admin/adhoc/IndividualadvancedaMoreinfo',
      state: {
        pageCount: id,
        individualSearchData: individualSearchData,
        accessToken: location?.state?.accessToken,
        emailId: location?.state?.emailId,
        getUserData: location?.state?.getUserData,
      },
    });
  };

  const items = [
    {
      label: 'Risk Check', command: () =>
        history.push({
          pathname: '/admin/adhoc/search',
          state: {
            accessToken: location?.state?.accessToken,
            emailId: location?.state?.emailId,
            getUserData: location?.state?.getUserData,
          },
        })
    },
    {
      label: 'Adhoc Search', command: () =>
        history.push({
          pathname: '/admin/adhoc/search',
          state: {
            accessToken: location?.state?.accessToken,
            emailId: location?.state?.emailId,
            getUserData: location?.state?.getUserData,
          },
        })
    },
    { label: 'Individual Search Data' },
  ];
  const home = location?.state?.getUserData?.premiumPlan === "FreeTrial" ? null : {
    icon: 'pi pi-home', 
    command: () => history.push({
        pathname: '/admin/dashboard',
        state: {
            accessToken: location?.state?.accessToken,
            emailId: location?.state?.emailId,
            getUserData: location?.state?.getUserData,
        },
    })
  };
  const home1 = { icon: 'pi pi-angle-double-left', command: () => window.history.back() }
  // const handleNavigate = (url) => {
  //   history.push(url);
  // };

  const highlightMatchType = (exactName) => {
    return exactName.map((name, index) => (
      <span key={index} style={{ backgroundColor: name.matchType === 'bg-orange-500' ? '#fef2c2' : 'transparent' }}>
        {name.name}{' '}
      </span>
    ));
  };

  return (
    <>
      <div className="mainSec">
        {loadings ? (
          <div class="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
            <Triangle
              height="80"
              width="80"
              color="#124A99"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        ) : (
          <div className="card" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <BreadCrumb model={items} className='breadCrumb' home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />
            <ToastContainer />
            <DataTable value={individualdata} stripedRows
              paginator
              rows={10}
              scrollable
              scrollHeight="63vh"
              rowsPerPageOptions={[15, 30, 45, 60]}
              style={{ whiteSpace: 'nowrap' }}
              className='dataTable adh'
            >
              <Column field="id" header="Id" />
              <Column field="exactName.name" bodyClassName="" header="Full Name" body={(rowData) => highlightMatchType(rowData.exactName)}></Column>
              <Column field="description" header="Description" />
              <Column field="gender" header="Gender" />
              <Column field="action" header="Action" />
              <Column header="More Info" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} body={actionbody} />
            </DataTable>

          </div>
        )}
      </div>
    </>
  )
}

export default AdvanceIndividualSearch
