import React, { useState, useRef, useEffect } from "react";
// import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from "primereact/toast";
// import countryList from "react-select-country-list";
import axios from "axios";
// import Logo from '../../../assets/ACAIA-1 2.png';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import './Companyprofile.css';
import CompanyChart from "./CompanyChart";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BreadCrumb } from 'primereact/breadcrumb';
import { Triangle } from 'react-loader-spinner';
import { Dialog } from 'primereact/dialog';
import { Card } from 'primereact/card';
import { InputNumber } from "primereact/inputnumber";
import { useLocation } from 'react-router-dom';


function CompanyProfile() {
    const toast = useRef(null);
    const [companyData, setCompanyData] = useState("");
    const [phono, setPhono] = useState("");
    const [chartData, setChartData] = useState('');
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [, setPhoneNumber1] = useState("");
    const [dialogError, setDialogError] = useState('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState("");
    const location = useLocation();
    const accessToken = location?.state?.accessToken;
    const companyDatas = location?.state?.getUserData;
    const [add, setAdd] = useState("");
    const [cPhono, setCPhono] = useState("");
    const [imageData, setUploadedImageUrl] = useState(location?.state?.profileDP);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const token = accessToken
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                };
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_HOST}/company`, requestOptions);
                setCompanyData(response.data);

                setPhono(location?.state?.getUserData?.phoneNumber)
                setPhoneNumber1(location?.state?.getUserData?.phoneNumber);
                setAdd(response?.data?.address)
                setCPhono(response?.data?.phoneNumber)
                const requestOptions1 = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                };
                const response1 = await axios.get(`${process.env.REACT_APP_BACKEND_HOST}/companies/summary`, requestOptions1)
                setChartData(response1.data);
                setLoading(false);

            } catch (error) {
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                } else if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                } else if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                }
                setLoading(false);
            }
        };
        fetchData();
    }, [accessToken, location?.state?.getUserData?.phoneNumber])

    const [activeProducts, setActiveProducts] = useState([]);
    const [inactiveProducts, setInactiveProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectInactiveproduct, setSelectInactiveProducts] = useState('');
    // const [selectedUser, setSelectedUser] = useState('');

    useEffect(() => {

        const fetchData = async () => {
            try {
                const token = accessToken
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                };

                const activeResponse = await axios.get(
                    `${process.env.REACT_APP_BACKEND_HOST}/branches/status?status=active`,
                    requestOptions
                );
                setActiveProducts(activeResponse.data);
                const inactiveResponse = await axios.get(
                    `${process.env.REACT_APP_BACKEND_HOST}/branches/status?status=inactive`,
                    requestOptions
                );
                setInactiveProducts(inactiveResponse.data);
            } catch (error) {
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                } else if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    sessionStorage.clear();
                } else if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                }
            }
        };

        fetchData();
    }, [accessToken]);


    useEffect(() => {
        if (location?.state?.getUserData?.data) {
            axios.get(process.env.REACT_APP_BACKEND_HOST + `/get/image`, {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                responseType: 'arraybuffer'
            })
                .then(response => {
                    const base64Image = btoa(String.fromCharCode(...new Uint8Array(response.data)));
                    const dataUrl = `data:image/png;base64,${base64Image}`;
                    setUploadedImageUrl(dataUrl);
                })
                .catch(error => {
                    if (error?.response && error?.response?.status === 403) {
                        window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    } else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                        const errorMessage = error?.response?.data?.message;
                        toast.current.show({ severity: 'error', summary: errorMessage, life: 3000 });
                    }
                });
        }
    }, [accessToken]);

    // const showErrorToast = (message) => {
    //     toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
    // };
    const showSuccessToast = (message) => {
        toast.current.show({ severity: 'success', summary: 'Successfully Changed', detail: message, life: 5000 });
    };
    // const uservalue = (event) => {
    //     setSelectedUser(event.value);
    //     history.push('/admin/users');
    // }

    const droupvalue = (event) => {
        setSelectedProduct(event.value);
        history.push({
            pathname: '/admin/branches',
            state: { getUserData: location?.state?.getUserData, accessToken: location?.state?.accessToken, emailId: location?.state?.getUserData?.emailId, companyprofiledropdown: event.value.name },
        });
    }

    const dropvalue1 = (event) => {
        setSelectInactiveProducts(event.value);
        history.push({
            pathname: '/admin/branches',
            state: { getUserData: location?.state?.getUserData, accessToken: location?.state?.accessToken, emailId: location?.state?.getUserData?.emailId, companyprofiledropdown: event.value.name },
        });
    }

    const displayErrorToast = (message) => {
        if (toast.current) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
        }
    };
    const [, setPhoneNumberUpdated] = useState(false);

    const handleUpdateCompanyData = (event) => {
        event.preventDefault();
        setLoading(true);
        setVisible(false);
        const data = {
            phoneNumber: phono
        }
        // const email = location?.state?.emailId;

        axios.put(`${process.env.REACT_APP_BACKEND_HOST}/companies`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then(response => {
                showSuccessToast(response.data.message);
                setUpdatedPhoneNumber(phono);
                setPhoneNumberUpdated(true);
                setVisible(false);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                } else if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                } else if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                }
            });
    }

    const items = [
        // { label: 'ADMIN' }, 
        { label: 'Company Profile' }];
    const home = {
        icon: 'pi pi-home', command: () =>
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                },
            })
    }
    const home1 = {
        icon: 'pi pi-angle-double-left', command: () => history.push({
            pathname: '/admin/dashboard',
            state: {
                accessToken: location?.state?.accessToken,
                emailId: location?.state?.emailId,
                getUserData: location?.state?.getUserData,
            },
        })
    }
    // const handleNavigate = (url) => {
    //     history.push(url);
    // };

    const logoTextStyle = {
        fontFamily: 'Arial, sans-serif',
        fontSize: '2rem',
        fontWeight: 'bold',
        color: '#124A99',
        textTransform: 'uppercase',
        letterSpacing: '2px',
        margin: 0,
        textAlign: 'center'
    };
    return (
        <>
            <div className="ban mainSec" >
                <Toast ref={toast} style={{ marginTop: '70px' }} />
                {loading ? (
                    <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                        <Triangle
                            height="80"
                            width="80"
                            color="#124A99"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <div className="com-container">
                        <BreadCrumb model={items} className='breadCrumb' home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />
                        <div className="card company-profile-container h-full pb-4"  >
                            <div className="com-profile-img flex gap-5">
                                <div className="imgandcard grid gap-5 pt-2 m-0">

                                    <Card className="profile-img flex w-full align-items-center justify-content-center " style={{ borderBottom: '0px' }}>
                                        {imageData ? (
                                            <>
                                                <img src={imageData} alt="Profile" width={200} />

                                                <div className="logo-com">
                                                    <p style={logoTextStyle}>{companyData.entityName && `${companyData.entityName}`}</p>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="logo-com">
                                                <p style={logoTextStyle}>{companyData.entityName && `${companyData.entityName}`}</p>
                                            </div>
                                        )}
                                    </Card>

                                    <Card className="profile-contents flex w-full align-items-center justify-content-center">
                                        <div className="profile-content">
                                            <div className="com-name">
                                                <h4 style={{ color: '#124A99' }}>{companyData?.entityName && `${companyData?.entityName}:`}</h4>

                                                <p>{add || location?.state?.getUserData?.address || 'N/A'}</p>

                                                {/* {companyDatas.city && companyDatas.pinCode && ( */}
                                                <p>{companyDatas?.city} - <span>{companyDatas?.pinCode}</span></p>
                                                {/* )} */}

                                                {/* {companyDatas.state && companyDatas.country && ( */}
                                                <p>{companyDatas?.state},<span>{companyDatas?.country}</span></p>
                                                {/* )} */}

                                                {(!companyDatas?.address && !companyDatas?.city && !companyDatas?.pinCode && !companyDatas?.state && !companyDatas?.country) && (
                                                    <p>Empty field</p>
                                                )}
                                            </div>

                                            <div className="com-phone">
                                                <h4 style={{ color: '#124A99' }}>Phone Number:</h4>
                                                <p>{updatedPhoneNumber || cPhono || companyDatas?.phoneNumber || 'N/A'}</p>
                                                <br />
                                                <Button label="Edit Number" className="edit-button flex align-items-center justify-content-center w-full" style={{ border: '2px solid #124A99', backgroundColor: 'white', color: '#000000' }} id="b-btn" onClick={() => setVisible(true)}></Button>
                                            </div>
                                        </div>
                                    </Card>

                                </div>

                                <div className='pie-pie-pie  w-full pt-2 m-0'>
                                    <Card className="pie-card-card pb-3">

                                        <div className="">
                                            <div className="chart-com flex justify-content-around align-items-center p-4">
                                                {chartData ? <CompanyChart data={chartData} /> : <><CompanyChart data={chartData} /></>}
                                            </div>
                                            <div className="summary flex justify-content-around align-item-center">
                                                <div className="com-branch">
                                                    <h4 style={{ color: '#124A99' }}>Branch Summary</h4>
                                                    <div className="field unique-users-com grid">
                                                        <label for="lastname3" className="col-fixed" style={{ width: '150px' }}>Active Branches</label>
                                                        <label style={{ fontWeight: '500' }}>:</label>
                                                        <div className="unique-com-profile col">
                                                            <InputNumber className="input-box" type="number" value={companyData.activeBranches} readOnly />
                                                            <Dropdown
                                                                value={selectedProduct}
                                                                options={activeProducts}
                                                                onChange={droupvalue}
                                                                placeholder='Location'
                                                                className="dropdown-box"
                                                                optionLabel="name"
                                                                id="state"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="field unique-users-com grid">
                                                        <label for="lastname3" className="col-fixed" style={{ width: '150px' }}>Inactive Branches</label>
                                                        <label style={{ fontWeight: '500' }}>:</label>
                                                        <div className="col unique-com-profile">
                                                            <InputNumber className="input-box" type="number" value={companyData.inactiveBranches} readOnly />
                                                            <Dropdown
                                                                className="dropdown-box"
                                                                value={selectInactiveproduct}
                                                                options={inactiveProducts}
                                                                onChange={dropvalue1}
                                                                placeholder="Location"
                                                                optionLabel="name"
                                                                id="state">
                                                            </Dropdown>                                                                                                                                    </div>
                                                    </div>
                                                    <div className="field unique-users-com grid ">
                                                        <label for="lastname3" className="col-fixed" style={{ width: '150px' }}>Total Branches</label>
                                                        <label style={{ fontWeight: '500' }}>:</label>
                                                        <div className="col">
                                                            <input id="state" type="text" className="unique-text-base text-base text-color surface-overlay p-2" style={{ border: 'none' }}
                                                                value={companyData.totalBranches}
                                                                readOnly disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="com-user">
                                                    <h4 style={{ color: '#124A99' }}>User Summary</h4>
                                                    <div className="field unique-users-com grid">
                                                        <label for="lastname3" className="col-fixed" style={{ width: '150px' }}>Active Users</label>
                                                        <label style={{ fontWeight: '500' }}>:</label>
                                                        <div className="col">
                                                            <input id="state" type="text" className="unique-text-base text-base text-color surface-overlay p-2" style={{ border: 'none' }}
                                                                value={companyData.activeUsers}
                                                                readOnly disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="field unique-users-com grid">
                                                        <label for="lastname3" className="col-fixed" style={{ width: '150px' }}>Disable Users</label>
                                                        <label style={{ fontWeight: '500' }}>:</label>
                                                        <div className="col">
                                                            <input id="state" type="text" className="unique-text-base text-base text-color surface-overlay p-2" style={{ border: 'none' }}
                                                                value={companyData.disableUsers}
                                                                readOnly disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="field unique-users-com grid ">
                                                        <label for="lastname3" className="col-fixed" style={{ width: '150px' }}>Inactive Users</label>
                                                        <label style={{ fontWeight: '500' }}>:</label>
                                                        <div className="col">
                                                            <input id="state" type="text" className="unique-text-base text-base text-color surface-overlay p-2" style={{ border: 'none' }}
                                                                value={companyData.inactiveUsers}
                                                                readOnly disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="field unique-users-com grid ">
                                                        <label for="lastname3" className="col-fixed" style={{ width: '150px' }}>Locked Users</label>
                                                        <label style={{ fontWeight: '500' }}>:</label>
                                                        <div className="col">
                                                            <input id="state" type="text" className="unique-text-base text-base text-color surface-overlay p-2" style={{ border: 'none' }}
                                                                value={companyData.lockedUsers}
                                                                readOnly disabled
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Dialog header="Edit Phone Number" visible={visible} onHide={() => setVisible(false)} className="sing_up_main_Dialog">
                <form className="w-full py-4 px-4">
                    <div className="flex align-items-center justify-content-center pt-3">
                        <div className="field unique-users-com grid w-full">
                            <PhoneInput
                                country={'us'}
                                countryCodeEditable={false}
                                value={phono}
                                onChange={(phoneNumber) => {
                                    setPhono(phoneNumber);
                                    if (!phoneNumber) {
                                        setDialogError('Phone Number is required.')
                                    } else {
                                        setDialogError('')
                                    }
                                }}
                                className={`unique-phone ${dialogError ? 'error-border' : ''}`}
                            />
                            {dialogError && <small className="p-error">{dialogError}</small>}
                        </div>

                    </div>
                    <div className="flex align-items-center justify-content-end w-full pt-3 pb-2">
                        <Button className="flex align-items-center justify-content-center" style={{ border: 'none', backgroundColor: '#124A99' }} id="b-btn" onClick={handleUpdateCompanyData} disabled={Boolean(dialogError)} >Update</Button>
                    </div>
                </form>
            </Dialog>
        </>
    )
}
export default CompanyProfile;







