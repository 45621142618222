import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dialog } from "primereact/dialog";
// import classNames from 'classnames';
import CountryService from "./ProductService";
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { Triangle } from 'react-loader-spinner';
import "../../../views/Loading.css"
import './Country.css';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as XLSX from 'xlsx';
import { FaFilePdf, FaFileCsv, FaFileExcel } from 'react-icons/fa';
import { Menu } from 'primereact/menu';
import { BreadCrumb } from 'primereact/breadcrumb';
// import { MultiSelect } from 'primereact/multiselect';
// import { InputNumber } from 'primereact/inputnumber';
import { useLocation } from 'react-router-dom';

export default function Country(props) {

    let emptyProduct = {
        id: 0,
        country: {
            laCountryName: '',
            arCountryName: '',
            countryCode: '',
        },
        riskType: '',
        sanctionScore: '',
        isSanctioned: ''
    };



    const [products, setProducts] = useState(null);
    // const [dropdownValue, setDropdownValue] = useState(null);
    // const [optionValue, setOptionValue] = useState([]);
    const [, setSelectedCountry] = useState('');
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [, setProductDialog1] = useState(false);
    const [productDialog2, setProductDialog2] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState(emptyProduct);
    const toast = useRef(null);
    // const productService = new CountryService();
    const [submitted, setSubmitted] = useState(false);
    const [visible, setVisible] = useState(false);
    const [productid, setProductid] = useState('');
    const history = useHistory();
    const [, setSelectedCountries] = useState([]);
    const [errorMessage, setErrorMessage] = useState('')
    // const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [, setWindowWidth] = useState(window.innerWidth);
    const location = useLocation();
    const plan = location?.state?.getUserData?.premiumPlan;

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // const showSuccessToast = (message) => {
    //     toast.current.show({ severity: 'success', summary: 'Successfully Changed', detail: message, life: 5000 });
    // };
    const displayErrorToast = (message) => {
        if (toast.current) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
        }
    };

    const accessToken = location?.state?.accessToken;
    const productService = useMemo(() => new CountryService(), []);

    useEffect(() => {
        setLoading(true);
        const accessToken = location?.state?.accessToken;


        productService.getProducts(accessToken)
            .then((data) => {
                setProducts(data);
                setLoading(false);
            })
            .catch(error => {
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                } else if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    sessionStorage.clear();
                } else if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                }
                setLoading(false);
            });
    }, [location?.state?.accessToken, productService]);

    const getproduct = (accessToken) => {
        productService.getProducts(accessToken)
            .then((data) => {
                // const filteredData = data.filter(item => selectedCountries.includes(item.laCountryName));
                setProducts(data);
                setLoading(false);
            })
            .catch(error => {
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                } else if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    sessionStorage.clear();
                } else if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                }
                setLoading(false);
            });
    }

    const sanctionOptions = [
        { name: 'Active', value: 'Active' },
        { name: 'Inactive', value: 'Inactive' }
    ];

    // const openNew = () => {
    //     setProduct(emptyProduct);
    //     setSubmitted(false);
    //     setProductDialog1(true);
    //     setSelectedCountries('');
    //     setSelectedCountry('');
    // }
    // const openNews = () => {
    //     toast.current.show({
    //         severity: 'warn',
    //         summary: 'Warning',
    //         detail: 'All Countries in the list are selected',
    //     });
    // }
    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog1(false);
        setProductDialog2(false);
        setErrorMessage('');
        setSelectedCountries('');
        setSelectedCountry('')
    }

    const handleisSanctionedChange = (e) => {
        setProduct({ ...product, isSanctioned: e.value });
    };

    const editProduct = (product) => {
        setProductid(product.id)
        setProduct({ ...product });
        setProductDialog2(true);
    }

    // const onInputChange = (e) => {
    //     const { value } = e.target;
    //     setProduct(prevProduct => ({
    //         ...prevProduct,
    //         country: {
    //             ...prevProduct.country,
    //             laCountryName: value
    //         }
    //     }));
    // };




    const infoTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button className="pi pi-pencil" onClick={() => editProduct(rowData)} />
            </React.Fragment>
        );
    };



    // const saveProduct = (e) => {
    //     e.preventDefault();
    //     setSubmitted(true);

    //     if (!selectedCountries || selectedCountries.length === 0) {
    //         displayErrorToast("Please select at least one country.");
    //         return;
    //     }

    //     const selectedData = dropdownValue.filter(item => selectedCountries.includes(item.laCountryName));
    //     if (selectedData.length === 0) {
    //         displayErrorToast("Please select valid countries.");
    //         return;
    //     }

    //     const data = selectedData.map(item => ({
    //         id: item.id,
    //         laCountryName: item.laCountryName,
    //         arCountryName: item.arCountryName,
    //         riskType: item.riskType,
    //         countryCode: item.countryCode,
    //         sanctionScore: item.sanctionScore,
    //         isSanctioned: item.isSanctioned,
    //         deleted: item.deleted,
    //         alphabitSymbole1: item.alphabitSymbole1,
    //         createdUser: item.createdUser,
    //         createdDate: item.createdDate,
    //         lastUpdatedDate: item.lastUpdatedDate,
    //         lastUpdatedUser: item.lastUpdatedUser,
    //     }));

    //     const apiUrl = `${process.env.REACT_APP_BACKEND_HOST}/countries/select`;

    // axios.post(apiUrl, data, {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer ' + location?.state?.accessToken,
    //     },
    // })
    //     .then(response => {
    //         if (response.status === 200) {
    //             hideDialog();
    //         }
    //     })
    //     .catch(error => {
    //         if (error?.response && error?.response?.data && error?.response?.data?.message) {
    //             const errorMessage = error?.response?.data?.message;
    //             displayErrorToast(errorMessage);
    //         } else if (error?.response && error?.response?.status === 403) {
    //             window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
    //             sessionStorage.clear();
    //         } else if (error?.response?.status === 401) {
    //             toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
    //         }
    //     });

    // }

    const handleUpdate = (e) => {
        e.preventDefault();
        hideDialog();
        setSubmitted(true);
        const { isSanctioned, sanctionScore } = product;
        if (!sanctionScore || !isSanctioned) {
            return;
        }

        const data = {
            sanctionScore: sanctionScore,
            isSanctioned: isSanctioned,
        };


        // const email = location?.state?.emailId;

        axios.put(process.env.REACT_APP_BACKEND_HOST + `/countries/${productid}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + location?.state?.accessToken
            },
        })
            .then(response => {
                hideDialog();
                setLoading(true)
                getproduct(accessToken);
            })
            .catch(error => {
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                } else if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    sessionStorage.clear();
                } else if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                }
            });
    }

    // const productDialogFooter = (
    //     <React.Fragment>
    //         <div className='footer_sec_btn'>
    //             <Button style={{ margin: 'unset', width: '80px' }} onClick={saveProduct}>Submit</Button>
    //         </div>
    //     </React.Fragment>
    // );


    const productDialogFooter2 = (
        <React.Fragment>
            <div className='footer_sec_btn'>
                <Button style={{ margin: 'unset', width: '80px' }} onClick={handleUpdate} disabled={!(product.isSanctioned && product.sanctionScore && !errorMessage)}>Update</Button>
            </div>
        </React.Fragment>
    );

    const cols = [
        { field: 'id', header: 'Country Id' },
        { field: 'laCountryName', header: 'Country' },
        { field: 'arCountryName', header: 'Country Name' },
        { field: 'countryCode', header: 'Country Code' },
        { field: 'sanctionScore', header: 'Sanction Score' },
    ];

    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    const handleExportToPDF = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, products.map(product => product.country));
                doc.save('Country Datas.pdf');
            });
        });
    };

    const handleExportToCSV = () => {
        const csvContent = convertToCSV(products.map(product => product.country), cols);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.setAttribute('href', URL.createObjectURL(blob));
        link.setAttribute('download', 'Country Datas.csv');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const convertToCSV = (data, columns) => {
        const csvRows = [];
        if (Array.isArray(data) && data.length > 0) {
            const headers = columns.map(col => col.field);
            csvRows.push(headers.join(','));
            for (const row of data) {
                const values = columns.map(col => row[col.field]).join(',');
                csvRows.push(values);
            }
        }
        return csvRows.join('\n');
    };

    const exportToExcel = () => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(products.map(product => product.country), { header: cols.map(col => col.header) });
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'Country Datas.xlsx');
    };

    const exportToXLS = () => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(products.map(product => product.country), { header: cols.map(col => col.header) });
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'Country Datas.xls');
    };

    let menu = null;

    const items = [
        {
            label: 'XLS',
            icon: () => <FaFileExcel />,
            command: () => {
                setVisible(false);
                exportToXLS();
            }
        },
        {
            label: 'XLSX',
            icon: () => <FaFileExcel />,
            command: () => {
                setVisible(false);
                exportToExcel();
            }
        },
        {
            label: 'CSV',
            icon: () => <FaFileCsv />,
            command: () => {
                setVisible(false);
                handleExportToCSV();
            }
        },
        {
            label: 'PDF',
            icon: () => <FaFilePdf />,
            command: () => {
                setVisible(false);
                handleExportToPDF();
            }
        }
    ];

    const itemsFB = [
        {
            label: 'PDF',
            icon: () => <FaFilePdf />,
            command: () => {
                setVisible(false);
                handleExportToPDF();
            }
        }
    ];

    const toggleMenu = (event) => {
        setVisible(!visible);
        menu.toggle(event);
    };
    const handlePrint = () => {
        const printWindow = window.open('', '_blank');
        printWindow.document.write('<html><head><title>Country Datas List</title></head><body>');
        printWindow.document.write('<style>table { border-collapse: collapse; width: 100%; } th, td { border: 1px solid black; padding: 8px; text-align: left; }</style>');
        printWindow.document.write('<h1>Country Datas List</h1>');

        printWindow.document.write('<table>');
        printWindow.document.write('<thead>');
        printWindow.document.write('<tr>');

        const columns = [
            { field: "id", header: "Country Id" },
            { field: "country.laCountryName", header: "Country Name" },
            { field: "country.arCountryName", header: "Arabic Name" },
            { field: "country.countryCode", header: "Country Code" },
            { field: "sanctionScore", header: "Sanction Score" },
            { field: "isSanctioned", header: "Status" },
        ];

        columns.forEach(col => {
            printWindow.document.write(`<th>${col.header}</th>`);
        });

        printWindow.document.write('</tr>');
        printWindow.document.write('</thead>');
        printWindow.document.write('<tbody>');

        products.forEach(row => {
            printWindow.document.write('<tr>');

            columns.forEach(col => {
                const fieldValue = col.field.split('.').reduce((obj, key) => obj[key], row);
                printWindow.document.write(`<td>${fieldValue}</td>`);
            });


            printWindow.document.write('</tr>');
        });

        printWindow.document.write('</tbody></table></body></html>');
        printWindow.document.close();
        printWindow.print();
    };


    const item = [
        // { label: 'ADMIN' }, 
        { label: 'Country' }];
    const home = {
        icon: 'pi pi-home', command: () =>
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                },
            })
    }
    // const home1 = {
    //     icon: 'pi pi-angle-double-left', command: () =>
    //         history.push({
    //             pathname: '/admin/dashboard',
    //             state: {
    //                 accessToken: location?.state?.accessToken,
    //                 emailId: location?.state?.emailId,
    //                 getUserData: location?.state?.getUserData,
    //             },
    //         })
    // }
    // const handleNavigate = (url) => {
    //     history.push(url);
    // };

    const TypeTemplate = (rowData) => {
        const status = rowData.isSanctioned;

        let color = '';

        if (status.toLowerCase() === 'inactive') {
            color = '#f7af00';
        } else if (status.toLowerCase() === 'active') {
            color = 'rgb(69 160 75)';
        }

        return (
            <div
                style={{
                    color: color,
                    fontWeight: '500',
                }}
            >
                {status}
            </div>
        );
    };

    const RiskTemplate = (rowData) => {
        const status = rowData.riskType;

        const statusLowerCase = status.toLowerCase().replace(/\s/g, ''); // Convert to lowercase and remove spaces

        let color = '';

        if (statusLowerCase === 'mediumrisk') {
            color = 'rgb(69 160 75)';
        } else if (statusLowerCase === 'highrisk') {
            color = '#f42616';
        } else if (statusLowerCase === 'lowrisk') {
            color = '#f7af00';
        } else if (statusLowerCase === 'low') {
            color = '#f7af00';
        } else if (statusLowerCase === 'high') {
            color = '#f42616';
        } else if (statusLowerCase === 'medium') {
            color = 'rgb(69 160 75)';
        }


        return (
            <div
                style={{
                    color: color,
                    fontWeight: '500',
                }}
            >
                {status}
            </div>
        );
    };


    const onSanctionScoreChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue === '') {
            setProduct({ ...product, sanctionScore: '' });
            setErrorMessage('Please enter a value between 1 and 10.');
        } else if (/^\d+$/.test(inputValue) && inputValue >= 1 && inputValue <= 10) {
            setProduct({ ...product, sanctionScore: inputValue });
            setErrorMessage('');
        } else {
            setErrorMessage('Please enter a valid number between 1 and 10.');
        }
    };


    return (
        <>
            {/* <div className='mainSec cou'>
                <Toast ref={toast} style={{ margin: 'unset' }} />
                <BreadCrumb model={item} home={home} style={{ border: 'none', width: '325px', top: '1em', overflowX:'visible',position:'relative' }} />

                <div className="card-Adhoc w-full bg-white unique-data-responsess" > */}
            <div className='mainSec cou'>
                <Toast ref={toast} style={{ marginTop: '70px' }} />
                <div className='container-user h-full w-full'>
                    <BreadCrumb model={item} home={home} style={{ border: 'none' }} />
                    {loading ? (
                        <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                            <Triangle
                                height="80"
                                width="80"
                                color="#124A99"
                                ariaLabel="triangle-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>
                    ) : (
                        <>
                            <div className="individual-account-search" style={{ border: 'solid rgba(209, 209, 209, 1) 1px' }}>
                                <div className="flex flex-wrap align-items-center justify-content-end" style={{ borderBottom: 'rgba(209, 209, 209, 1) 1px solid' }}>
                                    <div className='country-conatent flex justify-content-end pt-2 pb-2 px-3 bg-gray-50'>
                                        <div className="tab-head-btn flex justify-content-end flex-wrap w-full" >
                                            <div className="">
                                                <span className="p-input-icon-left ">
                                                    <i className="pi pi-search" />
                                                    <InputText placeholder="Search..." style={{ border: '2px solid #ccc' }} className='py-2 w-full' onInput={(e) => setGlobalFilter(e.target.value)} />
                                                </span>
                                            </div>
                                            {/* </div> */}
                                            {/* <div className="flex align-items-center justify-content-center" style={{ zIndex: '1' }}>
                                                <Button className=' mr-2 flex align-items-center justify-content-center' id="b-btn" style={{ zIndex: '1', border: 'none', backgroundColor: 'transparent' }} onClick={isButtonDisabled ? openNews : openNew} tooltip="Create Country" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} ><i className='pi pi-plus '></i></Button>
                                            </div> */}
                                            <div className="flex align-items-center justify-content-center">
                                                <Button className=' mr-2  flex align-items-center justify-content-center' id="b-btn" style={{ border: 'none', backgroundColor: 'transparent' }} disabled={products.length === 0} tooltip="Export" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} onClick={toggleMenu} ><i className='pi pi-upload '></i></Button>
                                                <Menu
                                                    className='unique-menu-exp'
                                                    model={plan === 'FreeTrial' || plan === 'BasicAdmin' ? itemsFB : items}
                                                    popup
                                                    ref={(el) => (menu = el)}
                                                    onHide={() => setVisible(false)}
                                                    appendTo={document.body}
                                                />
                                            </div>
                                            <div className="flex align-items-center justify-content-center">
                                                <Button className=' mr-2 flex align-items-center justify-content-center' id="b-btn" style={{ zIndex: '1', border: 'none', backgroundColor: 'transparent' }} onClick={handlePrint} disabled={products.length === 0} tooltip="Print" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} ><i className='pi pi-print '></i></Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <DataTable value={products} selection={selectedProducts} stripedRows onSelectionChange={(e) => setSelectedProducts(e.value)} showGridlines dataKey="id" id='edit-btn' paginator rows={10} rowsPerPageOptions={[10, 20, 30, 40]} globalFilter={globalFilter}>
                                    <Column field="id" header="Id" sortable></Column>
                                    <Column field="country.laCountryName" header="Name" sortable></Column>
                                    <Column field="country.countryCode" header="Code" sortable></Column>
                                    <Column field="sanctionScore" header="Sanction Score" sortable></Column>
                                    <Column field="riskType" header="Risk Type" body={RiskTemplate} sortable></Column>
                                    <Column field="isSanctioned" header="Status" body={TypeTemplate} sortable></Column>
                                    {props.roleName === 'ProAdmin' && <Column header="Edit" body={infoTemplate} ></Column>}
                                </DataTable>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/* <Dialog visible={productDialog1} modal header="Add Country Details" draggable={false} className="sing_up_main_Dialog" footer={productDialogFooter} onHide={hideDialog}>
                <div className="feild c pt-3 ">
                    <div className="flex">
                        <div className="input">
                            <div className="label">Country</div>
                            <MultiSelect
                                className='w-full'
                                filter
                                maxSelectedLabels={3}
                                minSelectedLabels={1}
                                options={optionValue}
                                onChange={(e) => {
                                    setSelectedCountry(e.value);
                                    setSelectedCountries(e.value);
                                }}
                                value={selectedCountry}
                            />
                        </div>
                    </div>
                </div >
            </Dialog > */}

            <Dialog visible={productDialog2} header="Country Update Details" draggable={false} modal className="sing_up_main_Dialog" footer={productDialogFooter2} onHide={hideDialog}>
                <div className="feild c pt-3 ">
                    <div className="flex flex-column">
                        {/* <div className="input">
                            <div className="label">Country</div>
                            <InputText
                                name="laCountryName"
                                value={product.country && product.country.laCountryName}
                                onChange={onInputChange}
                                className='w-full'  
                                readOnly                 
                            />
                            {submitted && !product.country && !product.country.laCountryName && <small className="p-error">Country Name is required.</small>}
                        </div> */}
                        <div className="input">
                            <div className="label">Status</div>
                            <Dropdown value={product.isSanctioned} onChange={handleisSanctionedChange} options={sanctionOptions} optionLabel="name" className={`w-full unique-drops ${submitted && !product.isSanctioned ? 'p-invalid' : ''}`} />
                            {submitted && !product.isSanctioned && <small className="p-error">Status is required.</small>}
                        </div>
                        <div className="input">
                            <div className="label">Sanction Score</div>
                            <InputText
                                className={`w-full ${errorMessage ? 'p-invalid' : ''}`}
                                value={product.sanctionScore}
                                onChange={onSanctionScoreChange}
                                pt={{
                                    input: {
                                        root: { autoComplete: 'off' },
                                    },
                                }}
                            />
                            {errorMessage && <small className="p-error">{errorMessage}</small>}
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    )
}
