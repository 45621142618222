import React, { useState, useRef, useEffect } from "react";
// import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import axios from "axios";
import { BiSolidCloudUpload } from 'react-icons/bi';
import { Toast } from "primereact/toast";
function CorporateForm(props) {

    const [selectedFile, setSelectedFile] = useState(null);
    const toast = useRef(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // const email = props?.data?.emailId

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const displayErrorToast = (message) => {
        if (toast.current) { // Check if the ref is not null
            toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
        }
    };

    const handleUpdate = async (event) => {
        event.preventDefault();
        if (selectedFile) {
            try {
                const formData = new FormData();
                formData.append('file', selectedFile);

                const response = await axios.post(
                    process.env.REACT_APP_BACKEND_HOST + '/sanctionCorporate/fileupload',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: 'Bearer ' + props?.data?.accessToken
                        },
                    }
                );

                if (response?.status === 200) {
                    setSelectedFile('')
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Sanction Updated', life: 3000 });
                }

            } catch (error) {
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                } else if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                } else if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                }
                return;
            }
        } else {
            toast.current.show({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Please select a file to upload.',
                life: 5000,
            });
        }
    };

    return (
        <>
            <Toast ref={toast} position="top-right" />
            <div className="pb-4">
                <div class="individual-class h-full mt-4 overflow-auto mb-4">
                    <div className="flex justify-content-end flex-wrap card-container w-full">
                        <div className="w-full text-center line-height-1 flex align-items-center justify-content-center">
                            <div className="w-6 p-4 border-round-xl" id="upload-func" style={{ border: 'dashed 2px #124A99' }}>
                                <div className="flex align-items-center justify-content-center" >
                                    <label htmlFor="fileInput">
                                        <div className="text-center text-7xl" id="file-upload-icon" style={{ cursor: 'pointer' }}><BiSolidCloudUpload /></div>
                                        <em className="text-xs">{selectedFile ? selectedFile.name : ''}</em>
                                    </label>
                                    <input type="file" id="fileInput" onChange={handleFileUpload} />
                                </div>
                                <p className="drop-file-sanc" style={{ fontSize: (windowWidth < 500 ? '12px' : '16px'), textAlign: 'center' }}>Drop a File to Upload Sanction</p>
                            </div>
                        </div>
                        <div className="footer_sec_btn" style={{ height: '15vh' }}>
                            <Button disabled={!selectedFile} className="form-btns w-7rem flex align-items-center justify-content-center" style={{ border: 'none', background: '#091f41' }} id="b-btn" onClick={handleUpdate}>Submit</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default CorporateForm;
