import React, { useState, useRef, useEffect } from "react";
import { Button } from 'primereact/button';
import { useHistory, useLocation } from "react-router-dom";
import Logo_web from '../../assets/acaia_logo/acaia_dark_logo.svg';
import Logo_mob from '../../assets/acaia_logo/acaia_white_logo.svg';
import { InputText } from 'primereact/inputtext';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Toast } from "primereact/toast";
import signupimage from "../../Landing Page/media/banner-image.png";
import { Dialog } from 'primereact/dialog';
import OTPInput from "react-otp-input";
import { Triangle } from 'react-loader-spinner';
import axios from 'axios';
import { Country, State, City } from 'country-state-city';
import '../SignInForm/Signup.css';
import { Dropdown } from 'primereact/dropdown';
import './AdditionalInfo.css'
import CryptoJS from 'crypto-js';
import idmaxios from '../../axios/idmaxios';

const encrypt = (data, type) => {
    const key = CryptoJS.enc.Utf8.parse('acaiaalthi0001uu');
    const iv = CryptoJS.enc.Utf8.parse('qwertyuioplkjhgf');
    const encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv });

    if (type === 'hex') {
        return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
    } else {
        return encrypted.toString();
    }
};
const AdditionalInfo = () => {

    const history = useHistory();
    const toast = useRef(null);
    const [phoneValue, setPhoneValue] = useState('');
    const [openPop, setOpenPop] = useState(false);
    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);
    // const [userEmail, setUserEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyNameEr, setCompanyNameEr] = useState('');
    const [countdown, setCountdown] = useState(600);
    const intervalRef = useRef();
    const [phoneVerify, setPhoneVerify] = useState(false);
    // const [phOTP, setPhOTP] = useState();
    const [isOtpButtonDisabled, setIsOtpButtonDisabled] = useState(true);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [countryOptios, setCountryOptios] = useState();
    const location = useLocation();
    const emailId = location.state?.captureMail;
    const access_Token = location.state?.captureAccessToken;
    const refresh_Token = location.state?.captureRefreshToken;
    const signupPassword = location.state?.password;
    let fnameRef = useRef('');
    let lnameRef = useRef('');
    let cnameRef = useRef('');
    let phonenumRef = useRef('');
    let cemailRef = useRef('');

    // const clearFields = () => {
    //     setSelectedBranchCountry('');
    //     setEmailIds('');
    //     setCompanyName('');
    //     setSelectedBranchCity('');
    //     setSelectedBranchState('');
    //     setBranchPostalCode('');
    //     setFirstName('');
    //     setLastName('');
    //     setPhoneValue('');
    // }

    useEffect(() => {
        if (location.state?.captureAccessToken || location.state?.accessToken) {
            axios.get(process.env.REACT_APP_BACKEND_HOST + `/countries`, {
                headers: {
                    Authorization: 'Bearer ' + location.state?.captureAccessToken,
                }
            })
                .then(response => {
                    setLoading(false);
                    setCountryOptios(response?.data)
                })
                .catch(error => {
                    setLoading(false);
                    toast.current.show({ severity: 'error', summary: error?.response?.data?.message || 'error' });
                });
        } else {
            history.push({
                pathname: '/signup',
            });
        }
    }, [history, location.state?.captureAccessToken]);

    // useEffect(() => {
    //     document.addEventListener('contextmenu', (event) => {
    //         event.preventDefault();
    //     });

    //     document.addEventListener('keydown', (event) => {
    //         if (event.key === 'F12' || (event.ctrlKey && event.shiftKey && event.key === 'I')|| (event.ctrlKey && event.shiftKey && event.key === 'C')) {
    //             event.preventDefault();
    //         }
    //     });
    // }, []);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        // getCountry();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSignOut = () => {
        fetch(process?.env?.REACT_APP_IDM_BACKEND_HOST + "/reload/reload/" + emailId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        sessionStorage.clear();
    }
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeoutId);
    }, []);

    const handleLogin = async () => {
        setLoading(true);
        const encryptedEmail = encrypt(emailId, 'default');
        const encryptedPassword = encrypt(signupPassword, 'default');
        try {
            const response = await idmaxios.post(`/sign_in`, {
                emailId: encryptedEmail,
                password: encryptedPassword,
            });

            const accessToken = response.data.access_token;
            const refreshToken = response.data.refresh_token;

            const [profileResponse, validateResponse] = await Promise.all([
                axios.get(`${process.env.REACT_APP_BACKEND_HOST}/get`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }),
                idmaxios.get('token_info/' + accessToken),
            ]);

            if (profileResponse.status === 200 && validateResponse.status === 200) {
                const userProfile = profileResponse.data;

                if (userProfile.premiumPlan === "FreeTrial") {
                    setTimeout(() => {
                        history.push({
                            // pathname: '/admin/adhoc/search',
                            pathname: '/admin/dashboard',
                            state: {
                                emailId: emailId,
                                accessToken: accessToken,
                                refreshToken: refreshToken,
                                roleName: userProfile.premiumPlan
                            },
                        });
                    }, 1000);
                } else {
                    setTimeout(() => {
                        history.push({
                            pathname: '/admin/dashboard',
                            state: {
                                emailId: emailId,
                                accessToken: accessToken,
                                refreshToken: refreshToken
                            },
                        });
                    }, 1000);
                }
            } else {
                setLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while processing your request.', life: 3000 });
                setTimeout(() => {
                    history.push('/login');
                }, 1000);
                handleSignOut();
            }
        } catch (error) {
            setLoading(false);
            if (error?.response && error?.response?.data && error?.response?.data?.message) {
                const errorMessage = error?.response?.data?.message;
                toast.current.show({ severity: 'error', summary: 'Error', detail: errorMessage, life: 3000 });
            }
            setTimeout(() => {
                history.push('/login');
            }, 2000);
        }
    };


    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        intervalRef.current = setInterval(() => {
            setCountdown((prevCountdown) => (prevCountdown > 0 ? prevCountdown - 1 : 0));
        }, 1000);

        return () => {
            clearInterval(intervalRef.current);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleClosePop = () => {
        setOtp('');
        setOpenPop(false);
    }

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const mobileVerify = () => {
        setCountdown(600);
        setLoading(true);

        if (phoneValue.length >= 8) {
            setLoading(true);
            const payload = {
                phoneNumber: phoneValue,
            };

            axios.post(process.env.REACT_APP_BACKEND_HOST + `/send-phone-otp`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + access_Token
                }
            })
                .then(response => {
                    setLoading(false);
                    toast.current.show({ severity: 'success', summary: 'Success', detail: response?.data?.message });
                    setTimeout(() => {
                        setOpenPop(true);
                    }, 1000);
                })
                .catch(error => {
                    setLoading(false);
                    toast.current.show({ severity: 'error', summary: 'error', detail: error?.response?.data?.message });
                });
            setLoading(false);
        }
    }

    const resendOTP = async () => {
        setOtp('')
        setLoading(true);
        setCountdown(600);
        mobileVerify();
    };

    const otpVerify = (data) => {
        setLoading(true);

        const phoneNumberPayload = {
            phoneNumber: phoneValue,
            phoneOTP: otp
        };

        // setPhOTP(otp);
        const phoneNumberUrl = `/verify-phone-otp`;

        axios.post(process.env.REACT_APP_BACKEND_HOST + phoneNumberUrl, phoneNumberPayload, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Success', detail: response?.data?.message });
                setOtp('');
                setOpenPop(false);
                setLoading(false);
                setPhoneVerify(true);
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'error', detail: error?.message });
                setOtp('');
                setLoading(false);
                setPhoneVerify(false);
            });
    }

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [firstNameEr, setFirstNameEr] = useState('');
    const [lastNameEr, setLastNameEr] = useState('');
    const [phoneEr, setPhoneEr] = useState('');

    const handleFirstnameChange = (event) => {
        const val = event.target.value;
        if (/[^a-zA-Z\s]/.test(val)) {
            return;
        }
        if (val.length === 0) {
            setFirstNameEr('First Name is required.');
        }
        // else if (val.length < 3) {
        //     setFirstNameEr('Must have at least 3 characters.');
        // } 
        else {
            setFirstNameEr('');
        }
        setFirstName(val);
    };

    const handleLastNameChange = (event) => {
        const val = event.target.value;
        if (/[^a-zA-Z\s]/.test(val)) {
            return;
        }
        if (val.length === 0) {
            setLastNameEr('Last Name is required.');
        } else {
            setLastNameEr('');
        }
        setLastName(val);
    };

    // const handlePhoneChange = (e) => {
    //     const val = e;
    //     if (val.length === 0) {
    //         setPhoneEr('Phone Number is required.');
    //     } else {
    //         setPhoneEr('');
    //     }
    //     setPhoneValue(val);
    // };
    const handlePhoneChange = (value, e) => {
        const val = e;
        if (val.length === 0) {
            setPhoneEr('Phone Number is required.');
        }
        else {
            setPhoneEr('');
        }
        setPhoneValue(val);
        setPhoneValue(value);
        if (value.length >= 6) {
            setIsOtpButtonDisabled(false);
        } else {
            setIsOtpButtonDisabled(true);
        }
    };

    const handleCompanyNameChange = (event) => {
        const val = event.target.value;
        if (/[^a-zA-Z\s]/.test(val)) {
            return;
        }
        if (val.length === 0) {
            setCompanyNameEr('Company name is required.');
        }
        // else if (val.length < 3) {
        //     setCompanyNameEr('Must have at least 3 characters.');
        // } 
        else {
            setCompanyNameEr('');
        }
        setCompanyName(val);
    };

    const [emailIds, setEmailIds] = useState('');
    const [emailMessage, setEmailMessage] = useState('');
    const [branchPostalCode, setBranchPostalCode] = useState('');
    const [postalErMessage, setPostalErMessage] = useState('');

    const handleMailChange = (e) => {
        const val = e.target.value;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(val)) {
            setEmailMessage('Invalid email format');
        } else {
            setEmailMessage('');
        }
        setEmailIds(val);
    };

    const handleBranchPostalChange = (e) => {
        const val = e.target.value;
        if (!/^\d*$/.test(val)) {
            return;
        }
        if (val.length === 0) {
            setPostalErMessage('Postal Code is required.');
        } else {
            setPostalErMessage('');
        }
        setBranchPostalCode(val);
    };

    // Dialog Panel country,state,city changed
    const [selectedBranchCountry, setSelectedBranchCountry] = useState('');
    const [selectedBranchState, setSelectedBranchState] = useState('');
    const [selectedBranchCity, setSelectedBranchCity] = useState('');
    const [countriess, setCountriess] = useState([]);
    const [statess, setStatess] = useState([]);
    const [citiess, setCitiess] = useState([]);
    const [selectedBranchCountries, setSelectedBranchCountries] = useState('');

    useEffect(() => {
        const fetchCountries = async () => {
            const allCountries = await Country.getAllCountries();
            setCountriess(allCountries);
        };
        fetchCountries();
    }, []);
    const [selectedCountryFlag, setSelectedCountryFlag] = useState(null);
    const handleBranchCountryChange = (e) => {
        const selectedCountryName = e.value;
        const selectedCountryData = countriess.find(country => country.name === selectedCountryName);
        setSelectedBranchCountry(selectedCountryName)
        setSelectedCountryFlag(selectedCountryData)
        if (selectedCountryData) {
            setSelectedBranchCountries(selectedCountryData);
            setSelectedBranchState('');
            setSelectedBranchCity('');
            const countryStates = State.getStatesOfCountry(selectedCountryData.isoCode);
            setStatess(countryStates);
        } else {
            console.error("Selected country not found");
        }
    };

    const handleStateBranchChange = (e) => {
        const stateValue = e.value;
        setSelectedBranchState(stateValue);
        setSelectedBranchCity('');
        const stateCities = City.getCitiesOfState(selectedBranchCountries.isoCode, stateValue.isoCode);
        setCitiess(stateCities);
    };

    const handleChangePlan = () => {
        history.push({
            pathname: '/payment',
            state: {
                emailId: emailId,
                password: signupPassword,
                accessToken: access_Token,
                refreshToken: refresh_Token,
            },
        });
    }

    const handleSubmit = () => {
        setLoading(true);
        const payload = {
            country: selectedBranchCountry,
            email: emailId,
            companyName: companyName,
            city: selectedBranchCity.name,
            state: selectedBranchState.name,
            postalCode: branchPostalCode,
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneValue
        };
        axios.post(process.env.REACT_APP_BACKEND_HOST + `/signup/selected`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_Token,
            }
        })
            .then(response => {
                setLoading(false);

                if (response.status === 200) {

                    if (location?.state?.type === 'sign_Up') {

                        if (location?.state?.plan === 'FreeTrial') {
                            setTimeout(() => {
                                history.push({
                                    // pathname: '/admin/adhoc/search',
                                    pathname: '/admin/dashboard',
                                    state: {
                                        accessToken: access_Token,
                                        refreshToken: refresh_Token,
                                        emailId: emailId,
                                        roleName: location?.state?.plan,
                                    },
                                });
                            }, 1000);
                        } else {
                            handleLogin();
                        }
                    } else {

                        if (location?.state?.plan === undefined) {
                            history.push({
                                pathname: '/admin/dashboard',
                                state: {
                                    emailId: emailId,
                                    accessToken: access_Token,
                                    refreshToken: refresh_Token
                                },
                            });
                        } else if (location?.state?.plan === 'BasicAdmin' || location?.state?.plan === 'ProAdmin') {
                            history.push({
                                pathname: '/admin/dashboard',
                                state: {
                                    emailId: emailId,
                                    accessToken: access_Token,
                                    refreshToken: refresh_Token
                                },
                            });
                        } else {
                            history.push({
                                // pathname: '/admin/adhoc/search',
                                pathname: '/admin/dashboard',
                                state: {
                                    accessToken: access_Token,
                                    refreshToken: refresh_Token,
                                    emailId: emailId,
                                    roleName: location?.state?.plan,
                                },
                            });
                        }

                    }

                }
            })
            .catch(error => {
                setLoading(false);
                toast.current.show({ severity: 'error', summary: error?.response?.data?.message || 'Error' });
            });
    }

    return (
        <>
            {loading &&
                <div className="spin-wrapper">
                    <Triangle
                        height="80"
                        width="80"
                        color="#124A99"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            }

            <div className="sing_up_main">
                <div style={{ width: '-webkit-fill-available', margin: 'auto', display: 'flex', justifyContent: 'center' }}>
                    <div className="sing_up_main_c">
                        <div className="l_side">
                            <div className='logo_f' style={{ padding: ' 0 10px', position: 'relative', top: '-125px' }}>
                                <img src={Logo_web} onClick={() => history.push('/')} style={{ width: '130px', height: 'max-content', cursor: 'pointer' }} alt="Acaia-content" />
                            </div>
                            <div className="cen">
                                <img src={signupimage} className="l_side_D" style={{ width: '20rem' }} alt="signup-content" />
                            </div>
                        </div>
                        <div className="r_side unique-multiselect-font" style={{ height: (windowWidth >= 500 && windowWidth < 575 ? 'auto' : 'auto') }}>
                            <div className="feild" style={{ width: (windowWidth < 1001 ? '100%' : '90%'), margin: 'auto' }}>
                                <div className='logo_f web_hide' style={{ padding: ' 0 10px' }}>
                                    <img src={Logo_mob} onClick={() => history.push('/')} style={{ width: '130px', height: 'max-content', cursor: 'pointer' }} alt="Acaia-content" />
                                </div>
                                <div className="flex" style={{ flexDirection: 'column', gap: '20px' }}>
                                    <div className="main_heading">Additional Details</div>
                                    <div className="sub_heading"></div>
                                </div>
                                <div className="unique-adjust-gap" style={{ overflow: windowWidth < 500 ? 'auto' : (windowWidth >= 1000 && windowWidth < 1230 ? 'auto' : 'inherit'), height: windowWidth < 500 ? '550px' : (windowWidth >= 1000 && windowWidth < 1230 ? '400px' : 'inherit') }}>
                                    <div className="flex" style={{ flexDirection: (windowWidth < 1001 && windowWidth >= 500 ? 'row' : (windowWidth < 1230 ? 'column' : 'row')), gap: (windowWidth < 1000 && windowWidth >= 500 ? '20px' : (windowWidth < 1230 ? '10px' : '20px')) }}>
                                        <div className="input">
                                            <div className="label"> First Name<span style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>*</span></div>
                                            <InputText defaultValue={fnameRef.current} onChange={handleFirstnameChange} className={firstNameEr ? "p-invalid" : ""} />
                                            {firstNameEr && <small className="p-error">{firstNameEr}</small>}
                                        </div>
                                        <div className="input">
                                            <div className="label"> Last Name<span style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>*</span></div>
                                            <InputText defaultValue={lnameRef.current} onChange={handleLastNameChange} className={lastNameEr ? "p-invalid" : ""} />
                                            {lastNameEr && <small className="p-error">{lastNameEr}</small>}
                                        </div>
                                    </div>

                                    <div className="flex" style={{ flexDirection: (windowWidth < 1001 && windowWidth >= 500 ? 'row' : (windowWidth < 1230 ? 'column' : 'row')), gap: (windowWidth < 1000 && windowWidth >= 500 ? '20px' : (windowWidth < 1230 ? '10px' : '20px')) }}>
                                        <div className="input">
                                            <div className="label">Company Name<span style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>*</span></div>
                                            <InputText defaultValue={cnameRef.current} onChange={handleCompanyNameChange} className={companyNameEr ? "p-invalid" : ""} />
                                            {companyNameEr && <small className="p-error">{companyNameEr}</small>}
                                        </div>
                                        <div className="input unique-text-change">
                                            <div className="label">Company Locations<span style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>*</span></div>
                                            <Dropdown
                                                value={selectedBranchCountry}
                                                options={countryOptios?.map((item) => ({ label: item?.laCountryName, value: item?.laCountryName }))}
                                                onChange={handleBranchCountryChange}
                                                optionLabel="label"
                                                filter
                                                className="w-full p-0 unique-drops dropdown"
                                            />
                                        </div>
                                    </div>

                                    <div className="flex" style={{ flexDirection: (windowWidth < 1001 && windowWidth >= 500 ? 'row' : (windowWidth < 1230 ? 'column' : 'row')), gap: (windowWidth < 1000 && windowWidth >= 500 ? '20px' : (windowWidth < 1230 ? '10px' : '20px')) }}>
                                        <div className="input">
                                            <div className="label">Phone Number<span style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>*</span></div>
                                            <div className="ph_aling">
                                                <PhoneInput country={selectedCountryFlag ? selectedCountryFlag.isoCode.toLowerCase() : 'us'} countryCodeEditable={false} className='phoneFeild sign' value={phoneValue} onChange={handlePhoneChange} style={{ border: 'unset' }} />
                                                <Button className="unique-email-button" onClick={mobileVerify} disabled={isOtpButtonDisabled} label="OTP" style={{ height: windowWidth < 575 ? '35px' : '40px' }} />
                                            </div>
                                            {phoneEr && <small className="p-error">{phoneEr}</small>}
                                            {phoneValue && !phoneVerify && <small className="p-error" style={{ position: 'relative', float: 'right', right: '0' }}>verify {phoneVerify}</small>}
                                        </div>
                                    </div>

                                    <div className="flex" style={{ flexDirection: (windowWidth < 1001 && windowWidth >= 500 ? 'row' : (windowWidth < 1230 ? 'column' : 'row')), gap: (windowWidth < 1000 && windowWidth >= 500 ? '20px' : (windowWidth < 1230 ? '10px' : '20px')) }}>
                                        <div className="input">
                                            <div className="label">Company EmailID<span style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>*</span></div>
                                            <InputText
                                                disabled={emailId}
                                                defaultValue={emailId}
                                                onChange={handleMailChange}
                                                className={emailMessage ? "p-invalid" : ""}
                                                style={{ height: '40px' }}
                                            />
                                            {emailMessage && (<small className="p-error">{emailMessage}</small>)}
                                        </div>
                                        <div className="input unique-text-change">
                                            <div className="label">State<span style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>*</span></div>
                                            <Dropdown
                                                value={selectedBranchState}
                                                options={statess}
                                                onChange={handleStateBranchChange}
                                                optionLabel="name"
                                                filter
                                                disabled={!selectedBranchCountry || !emailId}
                                                className="w-full p-0 unique-drops dropdown"
                                            />
                                        </div>
                                    </div>

                                    <div className="flex" style={{ flexDirection: (windowWidth < 1001 && windowWidth >= 500 ? 'row' : (windowWidth < 1230 ? 'column' : 'row')), gap: (windowWidth < 1000 && windowWidth >= 500 ? '20px' : (windowWidth < 1230 ? '10px' : '20px')) }}>
                                        <div className="input unique-text-change">
                                            <div className="label">City<span style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>*</span></div>
                                            <Dropdown
                                                value={selectedBranchCity}
                                                options={citiess}
                                                onChange={(e) => setSelectedBranchCity(e.value)}
                                                optionLabel="name"
                                                filter
                                                disabled={!selectedBranchState}
                                                className="w-full p-0 unique-drops dropdown"
                                            />
                                        </div>
                                        <div className="input">
                                            <div className="label">Postal Code<span style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>*</span></div>
                                            <InputText
                                                disabled={!selectedBranchCity}
                                                defaultValue={phonenumRef.current}
                                                onChange={handleBranchPostalChange}
                                                className={postalErMessage ? "p-invalid" : ""}
                                                style={{ height: '40px' }}
                                                keyfilter="int" 
                                                maxlength={6}
                                            />
                                            {postalErMessage && (<small className="p-error">{postalErMessage}</small>)}
                                        </div>
                                    </div>

                                    <div className="flex mt-4" style={{ justifyContent: 'end', alignItems: 'end' }}>
                                        <Button className="footer_Sub_Btn" style={{ background: 'none', color: '#124A99' }} onClick={handleChangePlan}>Change Plan</Button>
                                        <Button className="footer_Sub_Btn" onClick={handleSubmit} disabled={!firstName || !lastName || !companyName || !selectedBranchCountry || !phoneValue || !emailId || !selectedBranchState || !selectedBranchCity || !branchPostalCode || !phoneVerify || firstNameEr || lastNameEr || companyNameEr || emailMessage || postalErMessage || phoneEr}>Submit</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Dialog header="OTP Verification" visible={openPop} className="sing_up_main_Dialog" onHide={handleClosePop}>
                    <div style={{ height: 'auto', overflow: 'auto', padding: '25px', display: 'flex', flexDirection: 'column', gap: '25px' }}>
                        <div className="feild" style={{ padding: 'unset' }}>
                            <div className="verificationOTP" style={{ fontSize: (windowWidth < 575 ? '16px' : '18px'), }}>Enter the OTP sent to your phone number.</div>
                            <div style={{ fontSize: (windowWidth < 575 ? '12px' : '14px'), textAlign: 'center', height: 'auto', color: '#bdbdbd' }}>OTP expires in <span style={{ color: '#124A99' }}>{sessionStorage?.verifyPath ? ('+' + phoneValue) : ''}</span><span style={{ color: '#124A99' }}>{formatTime(countdown)}</span> minutes</div>

                            <OTPInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                isInputNum={true}
                                separator={<span className="p-2"></span>}
                                containerStyle="otp-container"
                                inputStyle="otp-input"
                                inputType='number'
                                renderInput={(props, index) => (
                                    <input {...props} className="otp-input-field text-center" key={index} />
                                )}
                            />

                            <div className="flex_footer_btn otp_footer">
                                <Button className="footer_Sub_Btn" style={{ width: (windowWidth < 500 ? '-webkit-fill-available' : 'fit-content'), background: 'none', border: 'none', color: '#124A99', fontWeight: 'bold' }} onClick={resendOTP}><u>Resend OTP</u></Button>
                                <Button disabled={!otp || otp.length !== 6} className="footer_Sub_Btn" onClick={otpVerify}>Verify</Button>
                            </div>
                            {loading &&
                                <div class="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                                    <Triangle
                                        height="80"
                                        width="80"
                                        color="#124A99"
                                        ariaLabel="triangle-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </Dialog>

                <Toast ref={(el) => (toast.current = el)} />

            </div >
        </>
    );
}

export default AdditionalInfo;