import React, { useState, useEffect, useRef } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import { useHistory } from "react-router-dom/cjs/react-router-dom";
import axios from "axios";
import { Toast } from "primereact/toast";

function ViewTransaction(props) {
    const [transactionData, setTransactionData] = useState([]);
    // const [selectedItem, setSelectedItem] = useState(null);
    // const [dialogVisible, setDialogVisible] = useState(false);
    // const history = useHistory()
    const toast = useRef(null);
    const [expandedRows, setExpandedRows] = useState([]);


    const displayErrorToast = (message) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
    };
    // const displayWarningToast = (message) => {
    //     toast.current.show({ severity: 'warn', summary: 'Warning', detail: message, life: 5000 });
    // };


    useEffect(() => {
        const corporateId = props?.getUserData?.corporateid;

        if (corporateId) {
            axios.get(process.env.REACT_APP_BACKEND_HOST + `/accounts/${corporateId}/corporate-transactions`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props?.getUserData?.accessToken
                },
            })

                .then(response => {
                    if (response.data.length === 0) {
                        // displayWarningToast('No Transaction found.');
                    } else {
                        setTransactionData(response.data);
                    }
                })
                .catch(error => {
                    if (error?.response && error?.response?.status === 403) {
                        (window?.location?.assign(process.env.REACT_APP_AUTH_URL));
                        sessionStorage.clear();
                    }
                    if (error?.response && error?.response?.data && error?.response?.data?.message) {
                        const errorMessage = error?.response?.data?.message;
                        displayErrorToast(errorMessage);
                    } else {
                        displayErrorToast('An error occurred. Please try again later.');
                    }
                });
        }
    }, [props?.getUserData?.corporateid, props?.getUserData?.accessToken]);

    // const openDialog = (item) => {
    //     setSelectedItem(item);
    //     setDialogVisible(true);
    // };

    // const hideDialog = () => {
    //     setDialogVisible(false);
    // };

    const TypeTemplate = (rowData) => {
        const status = rowData.category;

        // Define styles based on the status
        // let backgroundColor = '';
        let color = '';

        if (status === 'Medium Risk') {
            // backgroundColor = '#f9d9bb';
            color = '#f7af00';
        } else if (status === 'High Risk') {
            // backgroundColor = '#ffcdd2';
            color = '#f42616';
        } else if (status === 'Low risk' || 'LowRisk') {
            // backgroundColor = '#c8e6c9';
            color = 'rgb(69 160 75)';
        }


        // Render the cell content with the defined styles
        return (
            <div
                style={{
                    color: color,
                    fontWeight: '500',

                }}
            >
                {status}
            </div>
        );
    };

    const rowExpansionTemplate = (rowData) => {
        return (
            <div className="w-full flex text-justify Acount-profile-expander-txt" style={{ fontSize: '14px', padding: '20px 40px' }}>
                <div className='f-Dd'>
                    <div className='w-full flex m-2'><div className='w-12rem font-semibold'>Transaction ID</div> <div className='w-4rem'>:</div> <div className='w-11rem'>{rowData.transactionId}</div></div>
                    <div className='w-full flex m-2'><div className='w-12rem font-semibold'>Correspondent ID</div> <div className='w-4rem'>:</div> <div className='w-11rem'>{rowData.correspondentId}</div></div>
                    <div className='w-full flex m-2'><div className='w-12rem font-semibold'>Transaction Amount</div> <div className='w-4rem'>:</div> <div className='w-11rem'>{rowData.amount}</div></div>
                    <div className='w-full flex m-2'><div className='w-12rem font-semibold'>Status</div> <div className='w-4rem'>:</div> <div className='w-11rem'>{rowData.status}</div></div>

                    <div className='w-full flex m-2'><div className='w-12rem font-semibold'>Currency</div> <div className='w-4rem'>:</div> <div className='w-11rem'>{rowData.currency}</div></div>
                    <div className='w-full flex m-2'><div className='w-12rem font-semibold'>Beneficiary ID</div> <div className='w-4rem'>:</div> <div className='w-11rem'>{rowData.beneficiaryId}</div></div>
                    <div className='w-full flex m-2'><div className='w-12rem font-semibold'>Beneficiary</div> <div className='w-4rem'>:</div> <div className='w-11rem'>{rowData.beneficiaryName}</div></div>
                </div>

                <div className='f-Dd'>
                    <div className='w-full flex m-2'><div className='w-9rem font-semibold'>Transfer Status</div> <div className='w-2rem'>:</div> <div className='w-10rem'>{rowData.transferStatus}</div></div>
                    <div className='w-full flex m-2'><div className='w-9rem font-semibold'>Purpose</div> <div className='w-2rem'>:</div> <div className='w-10rem'>{rowData.purpose}</div></div>
                    <div className='w-full flex m-2'><div className='w-9rem font-semibold'>Mode Of Payment</div> <div className='w-2rem'>:</div> <div className='w-10rem'>{rowData.modeOfPayment}</div></div>

                    <div className='w-full flex m-2'><div className='w-9rem font-semibold'>Created User</div> <div className='w-2rem'>:</div> <div className='w-10rem'>{rowData.createdUser}</div></div>
                    <div className='w-full flex m-2'><div className='w-9rem font-semibold'>Created Date</div> <div className='w-2rem'>:</div> <div className='w-10rem'>{rowData.createdDate}</div></div>
                    <div className='w-full flex m-2'><div className='w-9rem font-semibold'>Beneficiary Country</div> <div className='w-2rem'>:</div> <div className='w-10rem'>{rowData.beneficiaryCountry}</div></div>
                    <div className='w-full flex m-2'><div className='w-9rem font-semibold'>Purpose</div> <div className='w-2rem'>:</div> <div className='w-10rem'>{rowData.purpose}</div></div>

                </div>
            </div>
        );
    };
    const onRowToggle = (event) => {
        setExpandedRows(event.data);
    };

    return (
        <div style={{ padding: '20px 0' }} >
            <Toast ref={toast} position="top-right" style={{ top: '10%' }} />
            <DataTable value={transactionData} style={{ whiteSpace: 'nowrap' }} stripedRows paginator expandedRows={expandedRows} rowExpansionTemplate={rowExpansionTemplate} onRowToggle={onRowToggle} rows={5} rowsPerPageOptions={[5, 10, 25, 50]} scrollable='true'>
                <Column expander style={{ width: '3rem' }} />
                <Column field="transactionId" header="Transaction Id"></Column>
                <Column field="beneficiaryId" header="Beneficiary Id"></Column>
                <Column field="amount" header="Amount"></Column>
                <Column field="category" header="Category" body={TypeTemplate}></Column>
                <Column field="createdUser" header="Created User" ></Column>
            </DataTable>
        </div>
    );
}
export default ViewTransaction;