import React, { useState, useRef, useEffect } from "react";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import axios from "axios";
import { Toast } from "primereact/toast";
import { Triangle } from "react-loader-spinner";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import '../Accountsearch/Accountsearch.css';

function CorporateSearch(props) {
    const toast = useRef(null);
    const [corporateName, setCorporateName] = useState('');
    let cnameRef = useRef(null);
    const [arabicName, setArabicName] = useState('');
    let anameRef = useRef('');
    // const [nationalitydata, setNationalitydata] = useState([]);
    const nationalitydata = props?.Data?.nationalitydata;
    const [nationality, setNationality] = useState(props?.Data?.nationalitydata || []);
    const [idNumber, setIdNumber] = useState('');
    let idNumberRef = useRef('');
    const [status, setStatus] = useState('');
    const [profile, setProfile] = useState('');
    const [clientId, setClientId] = useState('');
    let clientIdRef = useRef('');
    const [tableData, setTableData] = useState([]);
    const [corporateid, setCorporateId] = useState("");
    let corporateidRef = useRef('');
    const [loading, setLoading] = useState(false);
    // const [clientIdError, setClientIdError] = useState('');
    const history = useHistory();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const accessToken = props?.Data?.accessToken;

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleClientIdBlur = () => {
        const numericRegex = /^[0-9]+$/;
        if (!clientId.match(numericRegex)) {
            // setClientIdError('Client Id must contain only numbers.');
        } else {
            // setClientIdError('');
        }
    };

    const handleNationalityChange = (event) => {
        const selectedNationality = nationalitydata.find((item) => item?.country?.laCountryName === event.target.value);
        setNationality(selectedNationality);
    };

    const handleStatusChange = (event) => {
        setStatus(event.value);
    }
    const handleProfileTypeChange = (event) => {
        setProfile(event.value);
    }
    const displayErrorToast = (message) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
    };
    const displayWarningToast = (message) => {
        toast.current.show({ severity: 'info', summary: 'Info', detail: message, life: 5000 });
    };

    // const email = props?.Data?.emailId;

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        // const formData = {
        //     corporateid,
        //     profile,
        //     status,
        //     idNumber,
        //     nationality: nationality ? nationality.laCountryName : '',
        //     arabicName,
        //     corporateName,
        //     clientId
        // };
        // const isAnyFieldFilled = Object.values(formData).some(value => !!value);

        // if (!isAnyFieldFilled) {
        //     setLoading(false);
        //     displayWarningToast('Please fill at least one field before searching.');
        //     return;
        // }
        try {
            const url = `${process.env.REACT_APP_BACKEND_HOST}/accounts?ClientId=${clientId}&corporateName=${corporateName}&arabicName=${arabicName}&nationality=${nationality.length >= 1 ? nationality.laCountryName : ''}&idNo=${idNumber}&status=${status}&profile=${profile}&id=${corporateid}`;
            const response = await axios.post(
                url,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + accessToken
                    }
                }
            );
            if (response.data.accounts.length === 0) {
                displayWarningToast('No accounts found.');
            } else {
                setTableData(response.data.accounts);
            }
            setLoading(false);
            resetHandler()
        } catch (error) {
            setLoading(false);
            resetHandler();
            setTableData([]);
            if (error?.response && error?.response?.data && error?.response?.data?.message) {
                const errorMessage = error?.response?.data.message;
                displayErrorToast(errorMessage);
            } else if (error?.response?.status === 401) {
                toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
            } else if (error?.response?.status === 403) {
                window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                sessionStorage.clear();
            } else {
                displayErrorToast('An error occurred. Please try again later.');
            }
        }
    };

    const infoTemplate = (rowData) => {
        const handleButtonClick = () => {
            const compid = rowData.companyId;
            const compiid = JSON.stringify(compid);
            // sessionStorage.setItem("corporateid", compid);
            // sessionStorage.setItem("corporateurlid", compiid);
            const newURL = `/admin/account/corporateprofiling?data=${encodeURIComponent(compiid)}`;
            history.push(newURL);
            history.push({
                state: {
                    userAllData: props?.Data,
                    corporateid: compid,
                    corporateurlid: compiid,
                    accessToken: accessToken,
                    emailId: props?.Data?.emailId,
                    getUserData: props?.Data?.getUserData,
                },
            });
        };

        return (
            <React.Fragment>
                <Button
                    className="pi pi-exclamation-circle"
                    onClick={handleButtonClick}
                    id="MI"
                    style={{ backgroundImage: 'none', border: 'none', backgroundColor: 'transparent', color: 'navy' }}
                />
            </React.Fragment>
        );
    };

    const statusOptions = [
        "Pending",
        "Cleared",
        "Blocked",
    ]

    const profileOptions = [
        "High Risk",
        "Medium Risk",
        "Low Risk",
    ]

    const resetHandler = () => {
        setCorporateName('');
        setArabicName('');
        setNationality('');
        setIdNumber('');
        setStatus('');
        setProfile('');
        setClientId('');
        if (cnameRef.current) {
            cnameRef.current.value = cnameRef.current.defaultValue;
        }
        if (anameRef.current) {
            anameRef.current.value = anameRef.current.defaultValue;
        }
        if (idNumberRef.current) {
            idNumberRef.current.value = idNumberRef.current.defaultValue;
        }
        if (clientIdRef.current) {
            clientIdRef.current.value = clientIdRef.current.defaultValue;
        }
        if (corporateidRef.current) {
            corporateidRef.current.value = corporateidRef.current.defaultValue;
        }
        // setTableData([]);
        setCorporateId('');
        setLoading(false);
    };


    const statusTemplate = (rowData) => {
        const status = rowData.status;
        let color = '';

        if (status === 'Pending') {
            color = '#f7af00';
        } else if (status === 'Blocked') {
            color = '#f42616';
        } else if (status === 'Cleared') {
            color = 'rgb(69 160 75)';
        } else if (status === 'On-going') {
            color = '#3746c6';
        }
        return (
            <div
                style={{
                    // backgroundColor: backgroundColor,
                    color: color,
                    fontWeight: '500',

                }}
            >
                {status}
            </div>
        );
    };

    const rendenationalityCell = (nationality) => {
        return nationality ? nationality : '-';
    };

    return (
        <>
            <div className="w-full h-full">
                <Toast ref={toast} position="top-right" style={{ top: '10%' }} />
                {loading ? (
                    <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                        <Triangle
                            height="80"
                            width="80"
                            color="#124A99"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <>

                        <div className="feild c">
                            <div className="flex">
                                <div className="input" style={{ margin: 'auto' }}>
                                    <div className="label">Client Id</div>
                                    <InputText ref={clientIdRef} defaultValue={clientIdRef.current ? clientIdRef.current.defaultValue : ''} keyfilter="int" onChange={(event) => setClientId(event.target.value)} onBlur={handleClientIdBlur} />
                                    {/* {clientIdError && <div className="text-red-500 ">{clientIdError}</div>} */}
                                </div>
                                <div className="input" style={{ margin: 'auto' }}>
                                    <div className="label">Corporate Id</div>
                                    <InputText ref={corporateidRef} defaultValue={corporateid.current ? corporateidRef.current.defaultValue : ''} onChange={(event) => setCorporateId(event.target.value)} />
                                </div>
                                <div className="input" style={{ margin: 'auto' }}>
                                    <div className="label">Company Name</div>
                                    <InputText ref={cnameRef} defaultValue={cnameRef.current ? cnameRef.current.defaultValue : ''} onChange={(event) => setCorporateName(event.target.value)} />
                                </div>
                            </div>

                            <div className="flex">
                                <div className="input" style={{ margin: 'auto' }}>
                                    <div className="label">Name In Arabic</div>
                                    <InputText dir="rtl" ref={anameRef} defaultValue={anameRef.current ? anameRef.current.defaultValue : ''} onChange={(event) => setArabicName(event.target.value)} />
                                </div>
                                <div className="input" style={{ margin: 'auto' }}>
                                    <div className="label">Nationality</div>
                                    <Dropdown value={nationality ? nationality?.country?.laCountryName : null} options={nationalitydata?.map((item) => ({ label: item?.country?.laCountryName, value: item?.country?.laCountryName }))} onChange={handleNationalityChange} filter required className="w-full p-0 unique-drops dropdown" optionLabel="label" />
                                </div>
                                <div className="input" style={{ margin: 'auto' }}>
                                    <div className="label">Id Number</div>
                                    <InputText ref={idNumberRef} defaultValue={idNumberRef.current ? idNumberRef.current.defaultValue : ''} onChange={(event) => setIdNumber(event.target.value)} />
                                </div>
                            </div>

                            <div className="flex">
                                <div className="input" style={{ margin: 'auto' }}>
                                    <div className="label">Status</div>
                                    <Dropdown value={status} options={statusOptions} onChange={handleStatusChange} className="w-full unique-drops dropdown" />
                                </div>
                                <div className="input" style={{ margin: 'auto' }}>
                                    <div className="label">Profile Type</div>
                                    <Dropdown value={profile} options={profileOptions} onChange={handleProfileTypeChange} className="w-full unique-drops dropdown" />
                                </div>
                            </div>

                            <div className="footer_sec_btn" style={{ padding: 'unset' }}>
                                <Button style={{ background: '#124A99' }} className="resetBtn" onClick={resetHandler} icon="pi pi-refresh" />
                                <Button style={{ background: '#124A99' }} onClick={handleSubmit} disabled={!status && !profile && !corporateid && !corporateName && !nationality && !clientId && !arabicName && !idNumber}>Search</Button>
                            </div>

                            {tableData?.length > 0 && (
                                <div className="individual-account-search" style={{ width: windowWidth <= 500 ? '95%' : '100%', margin: 'auto', border: 'solid rgba(209, 209, 209, 1) 1px' }}>
                                    <DataTable value={tableData} id='edit-btn1' paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}>
                                        {props.roleName !== "FreeTrial" && <Column field="id" header="Id"></Column>}
                                        {props.roleName === "FreeTrial" && <Column field="clientId" header="Client Id"></Column>}
                                        <Column field="corporateName" header="Corporate Name"></Column>
                                        <Column field="nationality" header="Nationality" body={(rowData) => rendenationalityCell(rowData.nationality)}></Column>
                                        <Column field="companyId" header="Company Id"></Column>
                                        <Column field="status" header="Status" body={statusTemplate}></Column>
                                        {props.roleName !== "FreeTrial" && <Column body={infoTemplate} header="More Info"></Column>}
                                    </DataTable>
                                </div>
                            )}

                        </div>
                    </>
                )}
            </div>
        </>
    )
}
export default CorporateSearch;







