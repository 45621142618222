import React, { Component, createRef } from "react";
import { Dropdown } from 'primereact/dropdown';
import "../../../views/Loading.css";
import "./Beneficiary.css";
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';
import { Triangle } from 'react-loader-spinner';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import jsPDF from "jspdf";
import { Menu } from 'primereact/menu';
import 'jspdf-autotable';
import { FaFilePdf, FaFileCsv, FaFileExcel } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BreadCrumb } from 'primereact/breadcrumb';
import PhoneInput from 'react-phone-input-2';
import AccountDropdown from '../MonitoringThresholds/AccountId';

class BeneficiarySearchListTable extends Component {
    constructor(props) {
        super(props);
        this.menu = null;
        this.toast = React.createRef();
        this.plan = props?.location?.state?.getUserData?.premiumPlan;
        this.itemsFB = [
            {
                label: 'PDF',
                icon: () => <FaFilePdf />,
                command: () => {
                    this.setVisible(false);
                    this.handleExportToPDF();
                }
            }
        ];
        this.items = [
            {
                label: 'XLS',
                icon: () => <FaFileExcel />,
                command: () => {
                    this.setVisible(false);
                    this.exportToXLS();
                }
            },
            {
                label: 'XLSX',
                icon: () => <FaFileExcel />,
                command: () => {
                    this.setVisible(false);
                    this.exportToExcel();
                }
            },
            {
                label: 'CSV',
                icon: () => <FaFileCsv />,
                command: () => {
                    this.setVisible(false);
                    this.handleExportToCSV();
                }
            },
            {
                label: 'PDF',
                icon: () => <FaFilePdf />,
                command: () => {
                    this.setVisible(false);
                    this.handleExportToPDF();
                }
            }
        ];
        let today = new Date();
        let month = today.getMonth();
        let year = today.getFullYear();
        let prevMonth = month === 0 ? 11 : month - 1;
        let prevYear = prevMonth === 11 ? year - 1 : year;
        let nextMonth = month === 11 ? 0 : month + 1;
        let nextYear = nextMonth === 0 ? year + 1 : year;
        this.state = {
            dropdownOptions: [],
            accountId: '',
            accountId1: '',
            name: '',
            address: '',
            city: '',
            postalCode: '',
            beneficiaryType: "",
            beneficiaryTypeMessage: '',
            emailId: '',
            phoneNumber: '',
            relationship: '',
            laCountryName: '',
            screeningStatus: '',
            // status: '',
            bankAccountNumber: '',
            bankName: '',
            bankName1: '',
            branchName: '',
            branchCode: '',
            nickName: '',
            currency: '',
            isLoading: false,
            dates2: null,
            currencies: [],
            visible: false,
            isInvalidPhoneNumber: false,
            productDialog1: false,
            currencyId: '',
            country: [],
            selectedCountry: null,
            selectedCountry1: null,
            selectedAccount: null,
            selectedAccountId: '',
            isSubmitDisabled: true,
            isInvalidName: false,
        };
        this.minDate = new Date();
        this.minDate.setMonth(prevMonth);
        this.minDate.setFullYear(prevYear);
        this.maxDate = new Date();
        this.maxDate.setMonth(nextMonth);
        this.maxDate.setFullYear(nextYear);
        this.invalidDates = [today];
        this.dateTemplate = this.dateTemplate.bind(this);
        this.baseState = this.state;
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleCountryChanges = this.handleCountryChanges.bind(this);
        this.handlecurrencyChange = this.handlecurrencyChange.bind(this);
        this.handleScreeningStatusChange = this.handleScreeningStatusChange.bind(this);
        this.handlePostalCodeChange = this.handlePostalCodeChange.bind(this);
        this.handleAccountIdChange = this.handleAccountIdChange.bind(this);
        // this.handleAccountIdChanges = this.handleAccountIdChanges.bind(this);
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
        this.handleBankAccountNumberChange = this.handleBankAccountNumberChange.bind(this);
        this.handleBranchCodeChange = this.handleBranchCodeChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.resetForm = this.resetForm.bind(this);
        // this.history = useHistory();
        this.handleResize = this.handleResize.bind(this);
        this.accountidRef = createRef();
        this.nameRef = createRef();
        this.addressRef = createRef();
        this.cityRef = createRef();
        this.postalCodeRef = createRef();
        this.emailidRef = createRef();
        this.relationshipRef = createRef();
        this.bankAccountNumberRef = createRef();
        this.branchNameRef = createRef();
        this.branchCodeRef = createRef();
        this.nickNameRef = createRef();
    }
    toast = React.createRef();

    async componentDidMount() {
        this.setState({ isLoading: true });

        window.addEventListener('resize', this.handleResize);

        try {
            const products = await this.getProducts();
            this.setState({ data: products });

            const response = await axios.get('https://openexchangerates.org/api/currencies.json');
            const currencyData = response.data;
            const currencyArray = Object.entries(currencyData).map(([code]) => ({ code }));
            this.setState({ currencies: currencyArray });
            const headers = {
                'Authorization': 'Bearer ' + this.props?.location?.state?.accessToken
            };
            const countryResponse = await axios.get(`${process.env.REACT_APP_BACKEND_HOST}/country`, { headers });
            const countryDatas = countryResponse.data;
            const countryData = countryDatas.map(entry => entry.country);
            const dropdownOptions = countryData.map(country => ({
                label: country.laCountryName,
                value: country.laCountryName,
            }));
            this.setState({ country: countryData, dropdownOptions: dropdownOptions });
        } catch (error) {
            if (error?.response) {
                if (error.response.status === 401) {
                    toast.error('Access Denied: You do not have permission to access this resource.', { autoClose: 3000 });
                } else if (error.response.status === 403) {
                    window.location.assign(process.env.REACT_APP_AUTH_URL);
                    sessionStorage.clear();
                } else if (error.response.data?.message) {
                    toast.error(error.response.data.message, { autoClose: 5000 });
                } else {
                    toast.error('An error occurred. Please try again later.', { autoClose: 5000 });
                }
            }
        } finally {
            setTimeout(() => {
                this.setState({ isLoading: false });
            }, 1000);
        }
    }

    dateTemplate(date) {
        if (date.day > 10 && date.day < 15) {
            return (
                <strong style={{ textDecoration: "line-through" }}>{date.day}</strong>
            );
        }
        return date.day;
    }
    exportToXLS() {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(this.state.data);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Beneficiary.xls");
    }
    exportToExcel = (format) => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(this.state.data);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Beneficiary.xlsx");
    };
    handleExportToPDF = () => {
        const doc = new jsPDF();
        doc.autoTable(this.exportColumns, this.state.data);
        doc.save("Beneficiary.pdf");
    };
    handleExportToCSV = () => {
        const csvContent = this.convertToCSV(this.state.data);
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        link.setAttribute("href", URL.createObjectURL(blob));
        link.setAttribute("download", "Beneficiary.csv");
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    convertToCSV = (data) => {
        const csvRows = [];
        if (Array.isArray(data) && data.length > 0) {
            const headers = Object.keys(data[0]);
            csvRows.push(headers.join(","));
            for (const row of data) {
                const values = Object.values(row).join(",");
                csvRows.push(values);
            }
        }
        return csvRows.join("\n");
    };
    toggleMenu = (event) => {
        event.preventDefault();
        this.setVisible(!this.state.visible);
        this.menu.toggle(event);
    };
    setVisible = (visible) => {
        this.setState({ visible });
    };
    handleAccountIdChange(e) {
        const value = e.target.value;
        const isValidNumeric = /^\d*$/.test(value);
        const isEmpty = value.trim() === '';
        this.setState({
            accountId1: value,
            accountIdMessage: isEmpty ? 'AccountId field is required.' : isValidNumeric ? null : 'Invalid AccountId field format.',
        });
    }

    handleAccountChange(selectedAccount) {
        const accountId = selectedAccount ? selectedAccount.accountId : '';
        this.setState({
            selectedAccountId: accountId,
        });
    }

    handleNameChange = (e) => {
        const inputValue = e.target.value;
        const isValidText = /^[A-Za-z\s]*$/;
        const isEmpty = inputValue.trim() === '';
        const containsNumeric = /\d/.test(inputValue);
        if (containsNumeric) {
            return;
        }
        this.setState({
            name: inputValue,
            nameMessage: isEmpty ? 'Name is required.' : isValidText ? null : 'Invalid Name field.',
        });
    };

    handleBeneficiaryTypeChange = (e) => {
        const selectedValue = e.value;
        if (selectedValue) {
            this.setState({
                beneficiaryType: selectedValue,
                beneficiaryTypeMessage: '',
            });
        } else {
            this.setState({
                beneficiaryType: '',
                beneficiaryTypeMessage: 'Please select beneficiary.',
            });
        }
    };

    handleAddressChange = (e) => {
        const inputValue = e.target.value;
        const onlyValidCharacters = /^[A-Za-z,.\s]{0,100}$/;
        const isEmpty = inputValue.trim() === '';
        this.setState({
            address: inputValue,
            addressMessage: isEmpty ? 'Address is required.' : onlyValidCharacters ? null : 'Invalid addres format.',
        });
    };

    handleCityChange = (e) => {
        const inputValue = e.target.value;
        const onlyLettersAndSpaces = /^[A-Za-z.\s]{0,30}$/;
        const isEmpty = inputValue.trim() === '';
        const containsNumeric = /\d/.test(inputValue);
        if (containsNumeric) {
            return;
        }
        this.setState({
            city: inputValue,
            cityMessage: isEmpty ? 'City is required' : onlyLettersAndSpaces ? null : 'Invalid city name.',
        });
    };

    handleCountryChanges = (e) => {
        const selectedValue = e.value;
        const isEmpty = selectedValue.trim() === '';

        this.setState({
            selectedCountry: isEmpty ? null : selectedValue,
            countryMessage: isEmpty ? 'Country is required.' : null,
        }, () => {
            // console.log(this.state.selectedCountry);
        });
    };

    handlePostalCodeChange = (e) => {
        const inputValue = e.target.value;
        // const isValidPostalCode = /^[0-9]{1,6}$/.test(inputValue);
        const isEmpty = inputValue.trim() === '';
        const containsNonDigit = /\D/.test(inputValue);
        if (containsNonDigit) {
            return;
        }
        this.setState({
            postalCode: inputValue,
            postalCodeMessage: isEmpty ? 'Postal Code is required.' : null,
        });
    };

    handleEmailIdChange = (e) => {
        const inputValue = e.target.value;
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue);
        const isEmpty = inputValue.trim() === '';
        this.setState({
            emailId: inputValue,
            emailMessage: isEmpty ? 'Email Id is required.' : isValidEmail ? null : 'Invalid Email Id format.',
        });
    };

    handlePhoneNumberChange = (newPhoneNumber) => {
        // if (!newPhoneNumber) {
        const rawInput = newPhoneNumber;
        const isEmpty = rawInput.trim() === '';
        this.setState({
            phoneNumber: rawInput,
            phoneNumberMessage: isEmpty ? 'Phone Number is required.' : null,
        });
        // }
    }

    // formatPhoneNumber = (phoneNumber) => {
    //     const matches = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    //     if (matches) {
    //         return `${matches[1]}-${matches[2]}-${matches[3]}`;
    //     }
    //     return phoneNumber;
    // };

    handleRelationshipChange = (e) => {
        const inputValue = e.target.value;
        const onlyLetters = /^[A-Za-z.\s]{0,30}$/;
        const isEmpty = inputValue.trim() === '';
        const containsNumeric = /\d/.test(inputValue);
        if (containsNumeric) {
            return;
        }
        this.setState({
            relationship: inputValue,
            relateMessage: isEmpty ? 'Relationship field is required.' : onlyLetters ? null : 'Invalid field.',
        });
    };

    handleBankAccountNumberChange = (e) => {
        const inputValue = e.target.value;
        const accountNumberPattern = /^[A-Za-z0-9]{0,20}$/;
        const isEmpty = inputValue.trim() === '';
        const containsNonDigit = /\D/.test(inputValue);
        if (containsNonDigit) {
            return;
        }
        this.setState({
            bankAccountNumber: inputValue,
            acNumberMessage: isEmpty ? 'Bank Account Number is required.' : accountNumberPattern ? null : 'Invalid Account Number.',
        });
    }

    handleCurrencyChange = (e) => {
        const selectedCurrencyId = e.value;
        const isEmpty = !selectedCurrencyId;
        this.setState({
            currencyId: selectedCurrencyId,
            currencyErrorMessage: isEmpty ? 'Please select currency.' : null,
        });
    }

    handleBankNameChange = (e) => {
        const selectedBankName = e.value;
        const isEmpty = !selectedBankName;
        this.setState({
            bankName: selectedBankName,
            bankNameErrorMessage: isEmpty ? 'Please select bank name.' : null,
        });
    }

    handleBranchNameChange = (e) => {
        const inputValue = e.target.value;
        const onlyLetters = /^[A-Za-z\s]*$/;
        const isEmpty = inputValue.trim() === '';
        const containsNumeric = /\d/.test(inputValue);
        if (containsNumeric) {
            return;
        }
        this.setState({
            branchName: inputValue,
            branchNameMessage: isEmpty ? 'Branch name is required.' : onlyLetters ? null : 'Invalid branch name.',
        });
    };

    handleBranchCodeChange = (e) => {
        const value = e.target.value;
        const isEmpty = value.trim() === '';
        const containsNonDigit = /\D/.test(value);
        if (containsNonDigit) {
            return;
        }
        this.setState({
            branchCode: value,
            branchCodeMessage: isEmpty ? 'Branch code is required.' : /^\d*$/.test(value) ? null : 'Invalid branch code.',
        });
    }

    handleNickNameChange = (e) => {
        const inputValue = e.target.value;
        const onlyLetters = /^[A-Za-z\s]*$/;
        const isEmpty = inputValue.trim() === '';
        const containsNumeric = /\d/.test(inputValue);
        if (containsNumeric) {
            return;
        }
        this.setState({
            nickName: inputValue,
            nickNameMessage: isEmpty ? 'Nick name is required.' : onlyLetters ? null : 'Invalid nick name field.',
        });
    };
    isFormValid = () => {
        const {
            beneficiaryType,
            name,
            address,
            city,
            country,
            postalCode,
            emailId,
            phoneNumber,
            relationship,
            bankAccountNumber,
            // currency,
            bankName,
            branchName,
            branchCode,
            nickName,
        } = this.state;

        return (
            beneficiaryType &&
            name &&
            address &&
            city &&
            country &&
            postalCode &&
            relationship &&
            bankAccountNumber &&
            emailId &&
            // currency &&
            bankName &&
            branchName &&
            nickName &&
            branchCode &&
            phoneNumber
        );
    };
    handleKeyDown(e) {
        const validKeyCodes = [8, 46];
        if (validKeyCodes.indexOf(e.keyCode) === -1 && !/^\d$/.test(e.key)) {
            e.preventDefault();
        }
    }

    resetFormState = () => {
        this.setState({
            accountId1: '',
            name: '',
            screeningStatus: '',
            address: '',
            city: '',
            selectedCountry: null,
            postalCode: '',
            emailId: '',
            phoneNumber: '',
            relationship: '',
            beneficiaryType: "",
            bankAccountNumber: '',
            currencyId: null,
            bankName: null,
            branchName: '',
            branchCode: '',
            nickName: '',
            isInvalidAccountId: false,
            isInvalidName: false,
            isInvalidAddress: false,
            isInvalidCity: false,
            isInvalidPostalCode: false,
            isInvalidEmailId: false,
            isInvalidPhoneNumber: false,
            isInvalidRelationship: false,
            isInvalidBankAccountNumber: false,
            isInvalidBranchname: false,
            isInvalidBranchCode: false,
            isInvalidNickname: false,
            isInvalidBeneficiaryType: false,
            // isInvalidPhoneNumber: false,
        });
    };
    openNew = (event) => {
        event.preventDefault();
        this.setState({
            productDialog1: true
        });
    };
    hideDialog = () => {
        this.resetFormState();
        this.setState({
            productDialog1: false
        });
    };
    handleNavigate = (url) => {
        this.props.history.push(url);
    };
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
    handleResize = () => {
        this.setState({
            windowWidth: window.innerWidth,
        });
    };

    async getProducts() {
        try {
            this.setState({ isLoading: true });
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.props?.location?.state?.accessToken
                }
            };

            const uri = `/beneficiaries`;
            const response = await axios.get(process.env.REACT_APP_BACKEND_HOST + uri, requestOptions);
            const productsData = response.data;
            this.setState({ isLoading: false });
            toast.success({
                severity: 'success',
                summary: 'Success',
                detail: 'Successfully Submitted!',
                autoClose: 3000
            });
            return productsData;
        } catch (error) {
            if (error?.response && error?.response?.status === 403) {
                window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                sessionStorage.clear();
            } else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                const errorMessage = error?.response?.data?.message;
                toast.error(errorMessage, {
                    autoClose: 5000,
                });
            } else {
                toast.error('An error occurred. Please try again later.', {
                    autoClose: 5000,
                });
            }
            this.setState({ isLoading: false });
            return [];
        }
    }
    resetForm = async () => {
        sessionStorage.removeItem("beneficiary_search");
        this.setState(this.baseState);
        const products = await this.getProducts();
        this.setState({ data: products });
        try {
            const products = await this.getProducts();
            this.setState({ data: products });
            const response = await axios.get('https://openexchangerates.org/api/currencies.json');
            const currencyData = response.data;
            const currencyArray = Object.entries(currencyData).map(([code]) => ({ code }));
            this.setState({ currencies: currencyArray });
        } catch (error) {
            if (error?.response) {
                if (error?.response?.status === 401) {
                    toast.error({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', autoClose: 3000 });
                } else if (error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    sessionStorage.clear();
                } else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    toast.error(errorMessage, {
                        autoClose: 5000,
                    });
                } else {
                    toast.error('An error occurred. Please try again later.', {
                        autoClose: 5000,
                    });
                }
            }
        }
        try {
            this.setState({ isLoading: true });
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.props?.location?.state?.accessToken
                }
            };

            const uri = `/beneficiaries`;
            const response = await axios.get(process.env.REACT_APP_BACKEND_HOST + uri, requestOptions);
            const productsData = response.data;
            this.setState({ isLoading: false });
            return productsData;
        } catch (error) {
            if (error?.response && error?.response?.status === 403) {
                window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                sessionStorage.clear();
            } else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                const errorMessage = error?.response?.data?.message;

                toast.error(errorMessage, {
                    autoClose: 5000,
                });
            } else {
                toast.error('An error occurred. Please try again later.', {
                    autoClose: 5000,
                });
            }
            this.setState({ isLoading: false });
            return [];
        }
    }
    handlecurrencyChange(event) {
        this.setState({
            currency: event.target.value
        });
        // }
        // handleStatusChange(event) {
        //     this.setState({
        //         status: event.target.value
        //     });
    }
    handleScreeningStatusChange(event) {
        this.setState({
            screeningStatus: event.target.value
        });
    }
    handleChange = (key, value) => {
        this.setState({ [key]: value }, () => {
            const isSubmitDisabled = !this.isFormValid();
            this.setState({ isSubmitDisabled });
        });
    };
    handleCountryChange(event) {
        this.setState({
            country: event.target.value
        });
    }
    rightToolbarTemplate = () => {
        return (
            <div className="flex justify-content-end flex-wrap card-container w-full pb-3">
                <div className="flex align-items-center justify-content-center smg-1" >
                    <Button icon="pi pi-plus" tooltip="New" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} className='new-btn' style={{ zIndex: '1' }} onClick={this.openNew} />
                </div>
                <div className="flex align-items-center justify-content-center">
                    <Button icon="pi pi-upload" tooltip="Export" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} className='export-btn' id='dt-btn' onClick={this.toggleMenu} />
                    <Menu
                        className='unique-menu-exp'
                        model={this.items}
                        popup
                        ref={(el) => (this.menu = el)}
                        onHide={() => this.setVisible(false)}
                        appendTo={document.body}
                    />
                </div>
            </div>
        );
    }
    handleSave = async () => {
        this.setState({ isLoading: true });
        const {
            accountId1,
            name,
            address,
            city,
            selectedCountry,
            postalCode,
            emailId,
            phoneNumber,
            relationship,
            beneficiaryType,
            bankAccountNumber,
            bankName,
            branchName,
            branchCode,
            nickName,
        } = this.state;
        if (!accountId1) {
            this.setState({ submitted: true });
            return;
        }
        this.setState({ isLoading: true, submitted: false });
        const requestData = {
            accountId: accountId1,
            name,
            address,
            city,
            country: selectedCountry,
            postalCode,
            emailId,
            beneficiaryType,
            phoneNumber,
            relationship,
            bankAccountNumber,
            bankName,
            branchName,
            branchCode,
            nickName,
            currencyId: this.state.currencyId
        };
        try {

            // const email = this.props?.location?.state?.emailId;

            const response = await axios.post(
                process.env.REACT_APP_BACKEND_HOST + '/beneficiaries',
                requestData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.props?.location?.state?.accessToken
                    }
                }
            );
            if (response.status === 200) {
                const products = await this.getProducts();
                this.setState({ data: products });
                this.setState({
                    productDialog1: false,
                    accountId1: '',
                    name: '',
                    address: '',
                    city: '',
                    selectedCountry: '',
                    postalCode: '',
                    emailId: '',
                    phoneNumber: '',
                    beneficiaryType: '',
                    relationship: '',
                    bankAccountNumber: '',
                    bankName: '',
                    branchName: '',
                    branchCode: '',
                    nickName: '',
                    currencyId: '',
                });
                toast.success({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Successfully Submitted!',
                    autoClose: 3000
                });
            }
            this.setState({ isLoading: false });
        } catch (error) {
            this.setState({ productDialog1: false });
            this.setState({ isLoading: false });
            if (error?.response && error?.response?.status === 403) {
                window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                sessionStorage.clear();
            } else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                const errorMessage = error?.response?.data?.message;
                toast.error(errorMessage, {
                    autoClose: 5000,
                });
            }
        }
    };
    render() {
        const { isSubmitDisabled } = this.state;
        const { currencies, name, address, city, Relationship, branchName, nickName, emailId, bankAccountNumber } = this.state;
        const isInvalidAccountId = !/^\d*$/.test(this.state.accountId1);
        const isInvalidBranchCode = !/^\d*$/.test(this.state.branchCode);
        const isInvalidName = name && !/^[A-Za-z\s]*$/.test(name);
        const isInvalidAddress = address && !/^[A-Za-z,\s]{5,100}$/.test(address);
        const isInvalidCity = city && !/^[A-Za-z\s]{3,50}$/.test(city);
        const isInvalidRelationship = Relationship && !/^[A-Za-z\s]{3,35}$/.test(Relationship);
        const isInvalidBranchname = branchName && !/^[A-Za-z\s]{3,35}$/.test(branchName);
        const isInvalidNickname = nickName && !/^[A-Za-z\s]{3,35}$/.test(nickName);
        // const isInvalidPostalCode = postalCode && !/^[0-9]{5,6}$/.test(postalCode);
        const isInvalidEmailId = emailId && !/^[\w.-]+@[\w.-]+\.\w+$/.test(emailId);
        const isInvalidBankAccountNumber = bankAccountNumber && !/^\d{6,40}$/.test(bankAccountNumber);
        // const isInvalidPhoneNumber = !/^\d+$/.test(this.state.phoneNumber);
        const cols = [
            { field: 'beneficiaryId', header: 'Beneficiary Id' },
            { field: 'beneficiaryType', header: 'Beneficiary Type' },
            { field: 'bankName', header: 'BankName' },
            // { field: 'currencyId.code', header: 'Currency' },
            { field: 'country', header: 'Country' },
            { field: 'bankAccountNumber', header: 'Bank Account Number' },
            { field: 'screeningStatus', header: 'Status' },
        ];
        this.exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));
        const status = [
            { value: "Pending" },
            { value: "Cleared" },
            { value: "Blocked" },
            { value: "Ongoing" }
        ]
        const TypeTemplate = (rowData) => {
            const status = rowData.screeningStatus;
            let color = '';
            if (status === 'Pending') {
                color = '#f7af00';
            } else if (status === 'Blocked') {
                color = '#b7b7b7';
            } else if (status === 'Cleared') {
                color = 'rgb(69 160 75)';
            } else if (status === 'On-going') {
                color = '#3746c6';
            }
            return (
                <div
                    style={{
                        color: color,
                        fontWeight: '500',
                    }}
                >
                    {status}
                </div>
            );
        };
        const bankName = [
            'ICICI', 'CANARA', 'HDFC', 'IOB', 'INDIAN'];
        const beneficiaryTypeValue = ["Individual", "Corporate"];

        const productDialogFooter = (
            <React.Fragment>
                <div className="footer_sec_btn">
                    <Button
                        onClick={this.handleSave}
                        style={{ width: '80px' }}
                        // disabled={
                        //     !this.state.beneficiaryType ||
                        //     !this.state.accountId1 ||
                        //     !this.state.name ||
                        //     !this.state.address ||
                        //     !this.state.city ||
                        //     !this.state.selectedCountry ||
                        //     !this.state.postalCode ||
                        //     !this.state.emailId ||
                        //     !this.state.phoneNumber ||
                        //     !this.state.relationship ||
                        //     !this.state.bankAccountNumber ||
                        //     !this.state.currencyId ||
                        //     !this.state.bankName ||
                        //     !this.state.branchName ||
                        //     !this.state.branchCode ||
                        //     !this.state.nickName ||
                        //     this.state.beneficiaryTypeMessage ||
                        //     this.state.accountIdMessage ||
                        //     this.state.nameMessage ||
                        //     this.state.addressMessage ||
                        //     this.state.cityMessage ||
                        //     this.state.countryMessage ||
                        //     this.state.postalCodeMessage ||
                        //     this.state.emailMessage ||
                        //     this.state.phoneNumberMessage ||
                        //     this.state.relateMessage ||
                        //     this.state.acNumberMessage ||
                        //     this.state.currencyErrorMessage ||
                        //     this.state.bankNameErrorMessage ||
                        //     this.state.branchNameMessage ||
                        //     this.state.branchCodeMessage ||
                        //     this.state.nickNameMessage
                        // }
                        disabled={isSubmitDisabled}
                    >Submit</Button>
                </div>
            </React.Fragment>
        );
        if (this.state.isLoading) {
            return (
                <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                    <Triangle
                        height="80"
                        width="80"
                        color="#124A99"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            );
        }
        const items = [
            // { label: 'WATCHFULNESS' }, 
            { label: 'Beneficiary' }];
        const home = {
            icon: 'pi pi-home', command: () =>
                this.props.history.push({
                    pathname: '/admin/dashboard',
                    state: {
                        accessToken: this.props?.location?.state?.accessToken,
                        emailId: this.props?.location?.state?.emailId,
                        getUserData: this.props?.location?.state?.getUserData,
                    },
                })
        }
        // const home1 = {
        //     icon: 'pi pi-angle-double-left', command: () =>
        //         this.props.history.push({
        //             pathname: '/admin/dashboard',
        //             state: {
        //                 accessToken: this.props?.location?.state?.accessToken,
        //                 emailId: this.props?.location?.state?.emailId,
        //                 getUserData: this.props?.location?.state?.getUserData,
        //             },
        //         })
        // }
        return (
            <>
                <div className="mainSec tran" >
                    <BreadCrumb model={items} home={home} style={{ border: 'none', overflowX: 'visible' }} />
                    <div className="feild c">
                        <div className="flex">
                            <div className="input">
                                <div className="label">From - To (Date)</div>
                                <Calendar
                                    value={this.state.dates2}
                                    onChange={(e) => this.setState({ dates2: e.value })}
                                    selectionMode="range"
                                    readOnlyInput
                                    className="calendar"
                                    showIcon
                                    id="p-calender"
                                />
                            </div>
                            <div className="input">
                                <div className="label">Currency</div>
                                <Dropdown
                                    value={this.state.currency}
                                    onChange={this.handlecurrencyChange}
                                    options={currencies}
                                    optionLabel="code"
                                    filter filterBy="code"
                                    className="calendar"
                                    style={{ border: '1px solid #ced4da', borderRadius: '6px', padding: 'unset' }}
                                />
                            </div>
                            <div className="input">
                                <div className="label">Status</div>
                                <Dropdown
                                    value={this.state.screeningStatus}
                                    onChange={this.handleScreeningStatusChange}
                                    options={status}
                                    optionLabel="value"
                                    className="calendar"
                                    style={{ border: '1px solid #ced4da', borderRadius: '6px', padding: 'unset' }}
                                />
                            </div>
                        </div>
                        <div className="flex">
                            <div className="input">
                                <div className="label">Account Id</div>
                                <AccountDropdown onChange={this.handleAccountChange}
                                    data={this.props?.location?.state}
                                    selectedAccountId={this.state.selectedAccountId} className="calendar" />
                            </div>
                            <div className="input">
                                <div className="label">Bank Name</div>
                                <Dropdown
                                    value={this.state.bankName1}
                                    onChange={(e) => this.setState({ bankName1: e.value })}
                                    options={bankName}
                                    className="calendar"
                                    style={{ border: '1px solid #ced4da', borderRadius: '6px', padding: 'unset' }}
                                />
                            </div>
                        </div>
                        <div className='mob-view-hides'>
                            <div className='flex align-items-center'>
                                <small className="flex" style={{ color: 'rgba(200, 200, 200, 1)', fontWeight: '600', }}>Note: Fill in one or more fields for preferred results.</small>
                                <div className='flex align-items-center justify-content-end'>
                                    <Button
                                        icon='pi pi-refresh'
                                        className='reset-btn'
                                        style={{ background: '#124A99', border: '1px solid #124A99' }}
                                        onClick={this.resetForm}
                                    />
                                    <Button
                                        label='Search'
                                        style={{ background: '#124A99', border: '1px solid #124A99' }}
                                        onClick={this.handleSubmit}
                                        disabled={!this.state.bankName1 && !this.state.screeningStatus && !this.state.dates2 && !this.state.currency && !this.state.selectedAccountId}
                                    />
                                </div>
                            </div>
                        </div>
                        {window.innerWidth <= 575 && (
                            <>
                                <div>
                                    <div className='unique-pi-iconss'>
                                        <small className="flex py-3" style={{ color: 'rgba(200, 200, 200, 1)', fontWeight: '600' }}>Note: Fill in one or more fields for preferred results.</small>
                                        <div className=' grid justify-content-end gap-3 m-0 pr-2'>
                                            <Button
                                                label='Search'
                                                style={{ background: '#124A99', border: '1px solid #124A99' }}
                                                onClick={this.handleSubmit}
                                                disabled={!this.state.bankName1 && !this.state.screeningStatus && !this.state.dates2 && !this.state.currency && !this.state.selectedAccountId}
                                            />
                                            <Button
                                                className='reset-btn'
                                                icon='pi pi-refresh'
                                                iconPos='center'
                                                style={{ background: '#124A99', border: '1px solid #124A99', fontWeight: 'normal' }}
                                                onClick={this.resetForm}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        <ToastContainer position="top-right" />
                        <div className="unique-cons">
                            <div className="unique-pi-icons bg-gray-50" style={{ border: 'rgba(209, 209, 209, 1) 1px solid', margin: 'unset', width: '100%' }}>
                                <div className="unique-lio gap-3 m-2">
                                    <Button icon="pi pi-upload" disabled={this.state.data} style={{ background: '#124A99', gap: '7px', border: '1px solid #124A99' }} onClick={this.toggleMenu}>Export</Button>
                                    <Menu className='unique-menu-exp'
                                        model={this.plan === 'FreeTrial' || this.plan === 'BasicAdmin' ? this.itemsFB : this.items}
                                        popup ref={(el) => (this.menu = el)} onHide={() => this.setVisible(false)} appendTo={document.body} />
                                    <Button icon="pi pi-plus" style={{ background: '#124A99', border: '1px solid #124A99' }} onClick={this.openNew} className="reset-btn" />
                                </div>
                            </div>
                            <div>
                                <DataTable value={this.state.data} paginator rows={5} rowsPerPageOptions={[5, 10, 20, 30]} stripedRows
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                    resizableColumns
                                    scrollable className="unique-datatable-mobs" >
                                    <Column field="beneficiaryId" header="Id" sortable></Column>
                                    <Column field="name" header="Name" sortable></Column>
                                    <Column field="accountId" header="Account Id" sortable></Column>
                                    <Column field="bankName" header="Bank Name" sortable></Column>
                                    <Column field="beneficiaryType" header="Type" sortable></Column>
                                    <Column field="currencyId.code" header="Currency" sortable></Column>
                                    <Column field="createdTime" header="Date" sortable></Column>
                                    <Column field="screeningStatus" header="Status" body={TypeTemplate} sortable></Column>
                                </DataTable>
                            </div>
                        </div>
                        <Dialog header=" Add Beneficiary Details" visible={this.state.productDialog1} draggable={false} modal className="sing_up_main_Dialog" footer={productDialogFooter} onHide={this.hideDialog}>
                            <div className="feild c" style={{ padding: '0 40px' }}>
                                <br />
                                <div className="flex">
                                    <div className="input">
                                        <div className="label">Beneficiary Type </div>
                                        <Dropdown
                                            options={beneficiaryTypeValue}
                                            style={{ border: '1px solid #ced4da', borderRadius: '6px', padding: 'unset' }}
                                            value={this.state.beneficiaryType}
                                            className={`calendar ${this.state.beneficiaryTypeMessage ? 'invalid-input' : ''}`}
                                            // onChange={this.handleBeneficiaryTypeChange}
                                            onChange={(e) => this.handleChange('beneficiaryType', e.value)}
                                        />
                                        {this.state.beneficiaryTypeMessage && <small className="p-error">{this.state.beneficiaryTypeMessage}</small>}
                                    </div>
                                    <div className="input">
                                        <div className="label">Account Id <span>*</span></div>
                                        <InputText
                                            // defaultValue={this.accountidRef.current}
                                            ref={this.accountidRef}
                                            onChange={this.handleAccountIdChange}
                                            onKeyDown={this.handleKeyDown}
                                            className={`${isInvalidAccountId || this.state.accountIdMessage ? 'invalid-input' : ''}`}
                                        />
                                        {this.state.accountIdMessage && <small className="p-error">{this.state.accountIdMessage}</small>}
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="input">
                                        <div className="label">Name <span>*</span></div>
                                        <InputText
                                            // defaultValue={this.nameRef.current}
                                            ref={this.nameRef}
                                            // onChange={this.handleNameChange}
                                            onChange={(e) => this.handleChange('name', e.target.value)}
                                            className={`${isInvalidName || this.state.nameMessage ? 'invalid-input' : ''}`}
                                            maxLength={30}
                                        />
                                        {this.state.nameMessage && <small className="p-error">{this.state.nameMessage}</small>}
                                    </div>
                                    <div className="input">
                                        <div className="label">Address<span>*</span></div>
                                        <InputText
                                            // defaultValue={this.addressRef.current}
                                            ref={this.addressRef}
                                            onChange={(e) => this.handleChange('address', e.target.value)}
                                            className={`${isInvalidAddress || this.state.addressMessage ? 'invalid-input' : ''}`}
                                        />
                                        {this.state.addressMessage && <small className="p-error">{this.state.addressMessage}</small>}
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="input">
                                        <div className="label">City<span>*</span></div>
                                        <InputText
                                            // defaultValue={this.cityRef.current}
                                            ref={this.cityRef}
                                            onChange={(e) => this.handleChange('city', e.target.value)}
                                            className={`${isInvalidCity || this.state.cityMessage ? 'invalid-input' : ''}`}
                                        />
                                        {this.state.cityMessage && <small className="p-error">{this.state.cityMessage}</small>}
                                    </div>
                                    <div className="input">
                                        <div className="label">Country<span>*</span></div>
                                        <Dropdown
                                            filter
                                            options={this.state.dropdownOptions}
                                            optionLabel="label"
                                            value={this.state.selectedCountry}
                                            onChange={(e) => this.handleChange('selectedCountry', e.target.value)}
                                            className={`calendar ${this.state.countryMessage ? 'invalid-input' : ''}`}
                                            style={{ border: '1px solid #ced4da', borderRadius: '6px', padding: 'unset' }}
                                        />
                                        {this.state.countryMessage && <small className="p-error">{this.state.countryMessage}</small>}
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="input">
                                        <div className="label">Postal Code<span>*</span></div>
                                        <InputText
                                            // defaultValue={this.postalCodeRef.current}
                                            ref={this.postalCodeRef}
                                            keyfilter="int"
                                            onChange={(e) => this.handleChange('postalCode', e.target.value)}
                                            className={`${this.state.postalCodeMessage ? 'invalid-input' : ''}`}
                                        />
                                        {this.state.postalCodeMessage && <small className="p-error">{this.state.postalCodeMessage}</small>}
                                    </div>
                                    <div className="input">
                                        <div className="label">Email Id<span>*</span></div>
                                        <InputText
                                            // defaultValue={this.emailidRef.current}
                                            ref={this.emailidRef}
                                            onChange={(e) => this.handleChange('emailId', e.target.value)}
                                            className={`${isInvalidEmailId || this.state.emailMessage ? 'invalid-input' : ''}`}
                                        />
                                        {this.state.emailMessage && <small className="p-error">{this.state.emailMessage}</small>}
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="input">
                                        <div className="label">Phone Number <span>*</span></div>
                                        <PhoneInput
                                            country={'us'}
                                            countryCodeEditable={false}
                                            value={this.state.phoneNumber}
                                            onChange={(newPhoneNumber) => this.handlePhoneNumberChange(newPhoneNumber)}
                                            className={`unique-phone ${this.state.phoneNumberMessage ? 'error-border' : ''}`}
                                        />
                                        {this.state.phoneNumberMessage && <small className="p-error">{this.state.phoneNumberMessage}</small>}
                                    </div>
                                    <div className="input">
                                        <div className="label">Relationship<span>*</span></div>
                                        <InputText
                                            // defaultValue={this.relationshipRef.current}
                                            ref={this.relationshipRef}
                                            keyType="text"
                                            onChange={(e) => this.handleChange('relationship', e.target.value)}
                                            className={`${isInvalidRelationship || this.state.relateMessage ? 'invalid-input' : ''}`}
                                        />
                                        {this.state.relateMessage && <small className="p-error">{this.state.relateMessage}</small>}
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="input">
                                        <div className="label">Bank Account Number <span>*</span></div>
                                        <InputText
                                            // defaultValue={this.bankAccountNumberRef.current}
                                            ref={this.bankAccountNumberRef}
                                            onChange={(e) => this.handleChange('bankAccountNumber', e.target.value)}
                                            maxLength={20}
                                            className={`${isInvalidBankAccountNumber || this.state.acNumberMessage ? 'invalid-input' : ''}`}
                                        />
                                        {this.state.acNumberMessage && <small className="p-error">{this.state.acNumberMessage}</small>}
                                    </div>
                                    <div className="input">
                                        <div className="label">Currency<span>*</span></div>
                                        <Dropdown
                                            options={currencies}
                                            optionLabel="code"
                                            style={{ width: '100%', border: '1px solid #ced4da', borderRadius: '6px', padding: 'unset' }}
                                            filter filterBy="code"
                                            className={`${this.state.currencyErrorMessage ? 'invalid-input' : ''} p-0 unique-drops`}
                                            value={this.state.currencyId}
                                            onChange={this.handleCurrencyChange}
                                        />
                                        {this.state.currencyErrorMessage && <small className="p-error">{this.state.currencyErrorMessage}</small>}
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="input">
                                        <div className="label">Bank Name<span>*</span></div>
                                        <Dropdown
                                            value={this.state.bankName}
                                            options={bankName}
                                            style={{ border: '1px solid #ced4da', borderRadius: '6px', padding: 'unset' }}
                                            onChange={(e) => this.handleChange('bankName', e.target.value)}
                                            className={`${this.state.bankNameErrorMessage ? 'invalid-input' : ''} calendar`}
                                        />
                                        {this.state.bankNameErrorMessage && <small className="p-error">{this.state.bankNameErrorMessage}</small>}
                                    </div>
                                    <div className="input">
                                        <div className="label">Branch Name <span>*</span></div>
                                        <InputText
                                            // defaultValue={this.branchNameRef.current}
                                            ref={this.branchNameRef}
                                            onChange={(e) => this.handleChange('branchName', e.target.value)}
                                            className={`${isInvalidBranchname || this.state.branchNameMessage ? 'invalid-input' : ''}`}
                                        />
                                        {this.state.branchNameMessage && <small className="p-error">{this.state.branchNameMessage}</small>}
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="input">
                                        <div className="label">Branch Code<span>*</span></div>
                                        <InputText
                                            keyfilter='num'
                                            // defaultValue={this.branchCodeRef.current}
                                            ref={this.branchCodeRef}
                                            onChange={(e) => this.handleChange('branchCode', e.target.value)}
                                            onKeyDown={this.handleKeyDown}
                                            className={`${isInvalidBranchCode || this.state.branchCodeMessage ? 'invalid-input' : ''}`}
                                        />
                                        {this.state.branchCodeMessage && <small className="p-error">{this.state.branchCodeMessage}</small>}
                                    </div>
                                    <div className="input">
                                        <div className="label">Nick Name<span>*</span></div>
                                        <InputText
                                            // defaultValue={this.nickNameRef.current}
                                            ref={this.nickNameRef}
                                            onChange={(e) => this.handleChange('nickName', e.target.value)}
                                            style={{ width: '100%', }}
                                            className={`${isInvalidNickname || this.state.nickNameMessage ? 'invalid-input' : ''}`}
                                        />
                                        {this.state.nickNameMessage && <small className="p-error">{this.state.nickNameMessage}</small>}
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </div>
                </div >
            </>
        );
    }
    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({ isLoading: true });

        const { currency, screeningStatus, selectedAccountId, dates2, bankName1, selectedCountry1 } = this.state;

        // Validate at least one field is filled before searching
        if (!currency && !screeningStatus && !selectedAccountId && !dates2 && !bankName1 && !selectedCountry1) {
            toast.warn('Please choose at least one option before searching.', {
                autoClose: 5000,
            });
            this.setState({ isLoading: false });
            return;
        }

        const startDate = dates2 && dates2.length === 2 ? dates2[0] : null;
        const endDate = dates2 && dates2.length === 2 ? dates2[1] : null;
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.props?.location?.state?.accessToken
            }
        };

        const queryParams = {
            screeningStatus: screeningStatus ? screeningStatus : '',
            accountId: selectedAccountId,
            bankName: bankName1,
        };

        if (currency) {
            queryParams.currency = currency.code;
        }

        const queryString = new URLSearchParams(queryParams).toString();
        let url = `${process.env.REACT_APP_BACKEND_HOST}/search/beneficiaries`;

        if (startDate && endDate) {
            const formattedStartDate = startDate.toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
            });
            const formattedEndDate = endDate.toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
            });
            url += `?startDate=${formattedStartDate}&endDate=${formattedEndDate}&${queryString}`;
        } else if (queryString) {
            url += `?${queryString}`;
        }

        // API request
        fetch(url, requestOptions)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Error fetching transaction data: ' + response.status);
                }
            })
            .then(data => {
                // Process response data
                const formattedData = data.map(item => ({
                    ...item,
                    startDate: new Date(item.startDate).toLocaleDateString('en-US', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric'
                    }),
                    endDate: new Date(item.endDate).toLocaleDateString('en-US', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric'
                    })
                }));
                sessionStorage.setItem('beneficiary_search', JSON.stringify(data));
                this.setState({ data: formattedData, isLoading: false });
            })
            .catch(error => {
                this.setState({ isLoading: false });
                if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    sessionStorage.clear();
                } else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    toast.error(errorMessage, {
                        autoClose: 5000,
                    });
                } else {
                    toast.error('An error occurred. Please try again later.', {
                        autoClose: 5000,
                    });
                }
            });
    };
}
export default BeneficiarySearchListTable;