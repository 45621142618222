import React, { useState, useEffect, useRef } from "react";
// import { InputText } from 'primereact/inputtext';
// import { Button } from 'primereact/button';
// import { Divider } from 'primereact/divider';
import axios from "axios";
import CorporateChart1 from "./CorporateChart1";
import CorporateChart2 from "./CorporateChart2";
import { Toast } from "primereact/toast";
import { Triangle } from 'react-loader-spinner';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import "../../../views/Loading.css";
import { BreadCrumb } from 'primereact/breadcrumb';
import ViewHistory from "./viewHistory";
import ViewBeneficiary from "./ViewBeneficiary";
import ViewTransaction from "./viewTransaction";
import { Card } from 'primereact/card';
import { useLocation } from 'react-router-dom';

function CorporateProfiling() {
    const location = useLocation();
    const [corporateDetails, setCorporateDetails] = useState(null);
    const [corporateTransactionSummary, setCorporateTransactionSummary] = useState(null);
    const [apiData, setApiData] = useState(null);
    const [apiData1, setApiData1] = useState(null);
    const toast = useRef(null);
    const history = useHistory();
    // const [showDetails, setShowDetails] = useState(true);
    // const [showChart, setShowChart] = useState(false);
    // const [showBeneficiary, setShowBeneficiary] = useState(false);
    // const [showHistory, setShowHistory] = useState(false);
    // const [showTransaction, setShowTransaction] = useState(false);
    const [loading, setLoading] = useState(true);
    // const [id, setId] = useState("");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [corpoRiskReasonClientId, setCorpoRiskReasonClientId] = useState(true);
    const accessToken = location?.state?.accessToken;
    const storedId = location?.state?.corporateid;
    const profileApiUrl = `${process.env.REACT_APP_BACKEND_HOST}/corporate/profile?id=${storedId}`;
    const monthApiUrl = `${process.env.REACT_APP_BACKEND_HOST}/corporate/month/${storedId}`;
    const beneApiUrl = `${process.env.REACT_APP_BACKEND_HOST}/corporate/bene/${storedId}`;

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // const toggleDetails = () => {
    //     setShowDetails(!showDetails);
    //     setShowChart(false);
    //     setShowBeneficiary(false);
    //     setShowHistory(false);
    //     setShowTransaction(false);

    // };
    // const toggleChart = () => {
    //     setShowChart(!showChart);
    //     setShowDetails(false);
    //     setShowBeneficiary(false);
    //     setShowHistory(false);
    //     setShowTransaction(false);

    // };
    // const toggleBeneficiary = () => {
    //     setShowBeneficiary(!showBeneficiary);
    //     setShowChart(false);
    //     setShowDetails(false);
    //     setShowHistory(false);
    //     setShowTransaction(false);
    // };
    // const toggleHistory = () => {
    //     setShowHistory(!showHistory);
    //     setShowBeneficiary(false);
    //     setShowChart(false);
    //     setShowDetails(false);
    //     setShowTransaction(false);
    // };
    // const toggleTransaction = () => {
    //     setShowHistory(false);
    //     setShowBeneficiary(false);
    //     setShowChart(false);
    //     setShowDetails(false);
    //     setShowTransaction(!showTransaction);
    // };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };


    const handleRiskreason = (event) => {
        event.preventDefault();
        history.push({
            pathname: '/admin/account/corpoprofiling/riskreason',
            state: {
                accessToken: accessToken,
                emailId: location?.state?.emailId,
                getUserData: location?.state?.getUserData,
                corpoRiskReasonClientId: corpoRiskReasonClientId,
                corporateid: location?.state?.corporateid,
                corporateurlid: location?.state?.corporateurlid,
                storedId: storedId,
                individualid: location?.state?.individualid,
                accountprofilingid: location?.state?.accountprofilingid,
                userAllData: location?.state?.userAllData,
            },
        })
    }

    useEffect(() => {

        if (storedId) {
            const fetchData = async () => {
                try {
                    setLoading(true);
                    if (!accessToken) {
                        console.error('Access token not found. Please ensure the user is authenticated.');
                        return;
                    }

                    const requestOptions = {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${accessToken}`
                        }
                    };

                    const [profileResponse, monthResponse, beneResponse] = await Promise.all([
                        axios.get(profileApiUrl, requestOptions),
                        axios.get(monthApiUrl, requestOptions),
                        axios.get(beneApiUrl, requestOptions)
                    ]);

                    const corporateData = profileResponse.data;
                    setCorporateTransactionSummary(corporateData.corporateTransactionSummary);
                    setCorporateDetails(corporateData);

                    setCorpoRiskReasonClientId(corporateData?.clientId)
                    setLoading(false);

                    const monthDataResponse = monthResponse.data;
                    setApiData(monthDataResponse);
                    setLoading(false);

                    const beneDataResponce = beneResponse.data;
                    setApiData1(beneDataResponce);
                    setLoading(false);

                } catch (error) {
                    if (error?.response && error?.response?.status === 403) {
                        (window?.location?.assign(process.env.REACT_APP_AUTH_URL));
                        setLoading(false);
                    }
                    if (error?.response && error?.response?.data && error?.response?.data?.message) {
                        const errorMessage = error?.response?.data?.message;
                        displayErrorToast(errorMessage);
                        setLoading(false);
                    } else {
                        displayErrorToast('An error occurred. Please try again later.');
                        setLoading(false);
                    }
                }
            };

            fetchData(); // Call the fetchData function
        }
    }, [profileApiUrl, monthApiUrl, beneApiUrl, storedId, accessToken]);

    const displayErrorToast = (message) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
    };

    const items = [
        // { label: 'Account Profiling' },
        {
            label: 'Risk Check', command: () =>
                history.push({
                    pathname: '/admin/account/search',
                    state: {
                        accessToken: accessToken,
                        emailId: location?.state?.emailId,
                        getUserData: location?.state?.getUserData,
                    },
                })
        },
        {
            label: 'Account Search', command: () =>
                history.push({
                    pathname: '/admin/account/search',
                    state: {
                        accessToken: accessToken,
                        emailId: location?.state?.emailId,
                        getUserData: location?.state?.getUserData,
                    },
                })
        },
        { label: 'Corporate Profiling' }
    ];
    const home = {
        icon: 'pi pi-home', command: () =>
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                },
            })
    }
    const home1 = { icon: 'pi pi-angle-double-left', command: () => window?.history?.back() }
    // const handleNavigate = (url) => {
    //     history?.push(url);
    // };

    return (
        <>
            <div className="mainSec" >
                <BreadCrumb model={items} className='breadCrumb' home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />
                <Toast ref={toast} position="top-right" style={{ top: '10%' }} />
                {loading ? (
                    <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                        <Triangle
                            height="80"
                            width="80"
                            color="#124A99"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <>
                        <Card title={('Corporate Id : ') + storedId} className='carD' style={{ height: (windowWidth < 500 ? 'auto' : '') }}>
                            {corporateDetails && (
                                <div className='h_ing'>
                                    Corporate Information
                                </div>
                            )}

                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Corporate Name <span>:</span></div>
                                <div className='value'>{corporateDetails?.corporateName || 'N/A'}</div>
                            </div>

                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Client Id <span>:</span></div>
                                <div className='value'>{corporateDetails?.clientId || 'N/A'}</div>
                            </div>

                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Profile <span>:</span></div>
                                <div className='value' style={{
                                    color:
                                        corporateDetails.profile === 'Medium Risk'
                                            ? '#f7af00'
                                            : corporateDetails.profile === 'Low Risk'
                                                ? 'rgb(69 160 75)'
                                                : corporateDetails.profile === 'High Risk'
                                                    ? '#f42616'
                                                    : 'black',
                                }}>{corporateDetails?.profile || 'N/A'}</div>
                            </div>

                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Aml Status <span>:</span></div>
                                <div className='value' style={{
                                    color:
                                        corporateDetails.amlStatus === 'Pending'
                                            ? '#f7af00'
                                            : corporateDetails.amlStatus === 'Cleared'
                                                ? 'rgb(69 160 75)'
                                                : corporateDetails.amlStatus === 'Blocked'
                                                    ? '#f42616'
                                                    : corporateDetails.amlStatus === 'Ongoing'
                                                        ? '#3746c6'
                                                        : 'black',
                                }}>{corporateDetails?.amlStatus || 'N/A'}</div>
                            </div>


                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Created Date <span>:</span></div>
                                <div className='value'>{formatDate(corporateDetails?.createdAt) || 'N/A'}</div>
                            </div>

                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Address <span>:</span></div>
                                <div className='value'>{corporateDetails?.address || 'N/A'}</div>
                            </div>

                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>City <span>:</span></div>
                                <div className='value'>{corporateDetails?.city || 'N/A'}</div>
                            </div>

                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Created User <span>:</span></div>
                                <div className='value' style={{ overflow: (windowWidth < 500 ? 'scroll' : '') }}>{corporateDetails?.createdUser || 'N/A'}</div>
                            </div>

                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Daily Transaction Count <span>:</span></div>
                                <div className='value'>{corporateDetails?.partner[0]?.customerObj?.riskReason || 'N/A'}</div>
                            </div>

                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Country <span>:</span></div>
                                <div className='value'>{corporateDetails?.country || 'N/A'}</div>
                            </div>

                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Pin Code <span>:</span></div>
                                <div className='value'>{corporateDetails?.pincodeNo || 'N/A'}</div>
                            </div>

                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Year-to-Date Total <span>:</span></div>
                                <div className='value'>{corporateTransactionSummary?.amountCurrentYear || 'N/A'}</div>
                            </div>

                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Monthly Total <span>:</span></div>
                                <div className='value'>{corporateTransactionSummary?.amountCurrentMonth || 'N/A'}</div>
                            </div>

                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Weekly Total <span>:</span></div>
                                <div className='value'>{corporateTransactionSummary?.amountCurrentWeek || 'N/A'}</div>
                            </div>

                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Today's Total <span>:</span></div>
                                <div className='value'>{corporateTransactionSummary?.amountToday || 'N/A'}</div>
                            </div>

                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Year-to-Date Transaction<br /> Count <span>:</span></div>
                                <div className='value'>{corporateTransactionSummary?.numTransactionsCurrentYear || 'N/A'}</div>
                            </div>


                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Monthly Transaction Count <span>:</span></div>
                                <div className='value'>{corporateTransactionSummary?.numTransactionsCurrentMonth || 'N/A'}</div>
                            </div>

                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Weekly Transaction Count <span>:</span></div>
                                <div className='value'>{corporateTransactionSummary?.numTransactionsCurrentWeek || 'N/A'}</div>
                            </div>

                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>Daily Transaction Count <span>:</span></div>
                                <div className='value'>{corporateTransactionSummary?.numTransactionsToday || 'N/A'}</div>
                            </div>
                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                <div className='leable' style={{ minWidth: '230px', display: 'flex', justifyContent: 'space-between' }}>more <span>:</span></div>
                                <div className='value text-blue-700 font-bold' onClick={handleRiskreason} style={{ cursor: 'pointer' }}>click here.</div>
                            </div>

                            <div className='h_ing'>
                                View Beneficiary
                            </div>

                            <ViewBeneficiary getUserData={location?.state?.userAllData} />

                            <div className='h_ing'>
                                View Transaction
                            </div>

                            <ViewTransaction getUserData={location?.state?.userAllData} />

                            <div className='h_ing'>
                                View History
                            </div>

                            <ViewHistory getUserData={location?.state?.userAllData} />

                            <div className='h_ing'>
                                Graphical Representation
                            </div>

                            <div className='flex' style={{ justifyContent: 'space-around', margin: 'auto', flexWrap: 'wrap' }}>
                                <>
                                    {apiData ? <CorporateChart1 data={apiData} /> : ''}
                                    {apiData1 ? <CorporateChart2 data={apiData1} /> : ''}
                                </>
                            </div>

                        </Card>
                    </>
                )}
            </div>
        </>
    )
}
export default CorporateProfiling;